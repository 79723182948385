import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { FormCollapsible, SubmitButton, TextInput, Select, DateInput } from "components/forms";
import { activeClinicalResearchSelector, activeParameterSelector } from "reducers";
import * as notifications from "notifications";
import {
  createClinicalResearch,
  deleteClinicalResearchFile,
  editClinicalResearch,
  uploadClinicalResearchFile,
} from "actions";
import { Form } from "react-bootstrap";
import { isAuthorized, partial } from "utils";
import { PUBLICATION_CHOICES } from "../../constants";
import SingleFileDropZone from "../../../../components/SingleFileDropZone";
import { PERMISSIONS } from "../../../../constants";

const ClinicalResearchForm = ({ activeClinicalResearch, ...props }) => {
  let initialValues = { ...activeClinicalResearch };
  let isSaved = activeClinicalResearch?.id;
  let title = isSaved ? "Edit Clinical Research" : "New Clinical Research";

  function renderFileDropZone() {
    if (isSaved && props.hasAdminPermission) {
      let DropZoneComponent = SingleFileDropZone;
      return (
        <div>
          <DropZoneComponent
            activeCaseFile={activeClinicalResearch}
            currentFile={activeClinicalResearch.file}
            uploadFile={props.uploadClinicalResearchFile}
            deleteFile={() => props.deleteClinicalResearchFile(activeClinicalResearch.id)}
            fileDownloadUrl={`/api/clinical_research/${activeClinicalResearch.id}/download_file/`}
          />
        </div>
      );
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isSaved
          ? partial(props.editClinicalResearch, activeClinicalResearch.id)
          : props.createClinicalResearch;
        values.parameter = props.parameterInfo.id;
        console.log("onsubmit", method, values);

        try {
          await method(values);
          notifications.success("Clinical Research saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Clinical Research");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <div style={{ minHeight: 380 }}>
            <FormCollapsible onSubmit={handleSubmit} title={title}>
              <Form.Row>
                <TextInput label="Title" name="title" />
                <Select
                  label="Publication"
                  name="publication"
                  placeholder="Select Publication"
                  options={props.publicationChoices}
                />
                <DateInput label="Publication Date" name="publication_date" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Summary" name="summary" md={8} />
                <TextInput label="Authors" name="authors" />
              </Form.Row>
              <SubmitButton />
            </FormCollapsible>
            {renderFileDropZone()}
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const roles = state.auth.activeAgencyRoles;

  return {
    parameterInfo: activeParameterSelector(state),
    activeClinicalResearch: activeClinicalResearchSelector(state),
    publicationChoices: PUBLICATION_CHOICES,
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  createClinicalResearch,
  editClinicalResearch,
  uploadClinicalResearchFile,
  deleteClinicalResearchFile,
})(ClinicalResearchForm);
