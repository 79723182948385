import {
  DELETE_LIFE_EXPECTANCY,
  EDIT_LIFE_EXPECTANCY,
  FETCH_LIFE_EXPECTANCIES,
  FETCH_ALL_LIFE_EXPECTANCIES,
  FETCH_LIFE_EXPECTANCY_CHOICES,
  SELECT_LIFE_EXPECTANCY,
  FETCH_21ST_MEDICAL_CONDITIONS_SCHEMA,
  FETCH_PREDICTIVE_MEDICAL_CONDITIONS_SCHEMA,
  STORE_RELATED_LE,
  SET_LE_TABLE_INDEX,
} from "actions/types";
import {
  SET_CASES_PAGINATION_DATA,
  SET_LES_PAGINATION_DATA,
  LOADING,
  SORT_BY,
  SET_ACTIVE_TAB,
  STORE_LE_FILTERS_DATA,
} from "../types.js";
import { sortByParam } from "../../utils.js";
import advisorhq from "apis/advisorhq";
import * as notifications from "notifications.js";
import {
  activeCaseIdSelector,
  twentyFirstMedicalConditionsSchemaSelector,
  predictiveMedicalConditionsSchemaSelector,
  sortBySelector,
  activeAgencySelector,
  pageSizeSelector,
  storedLeFiltersSelector,
  currentPageLeSelector,
} from "reducers";

export const selectLifeExpectancy = id => {
  return { type: SELECT_LIFE_EXPECTANCY, payload: id };
};

export const fetchLifeExpectancies = () => async (dispatch, getState) => {
  const activeCaseId = activeCaseIdSelector(getState());
  const response = await advisorhq.get("/underwriting/api/life_expectancies/", {
    params: { case: activeCaseId },
  });
  dispatch({ type: FETCH_LIFE_EXPECTANCIES, payload: response.data });
};

export const fetchNonPaginatedLifeExpectancies = () => async (dispatch, getState) => {
  const activeCaseId = activeCaseIdSelector(getState());
  const response = await advisorhq.get("/underwriting/api/life_expectancies/", {
    params: {
      case: activeCaseId,
      page_size: 1000,
    },
  });
  dispatch({ type: FETCH_LIFE_EXPECTANCIES, payload: response.data });
};

// TODO: REPLACE FOR FetchPage
export const fetchAllLifeExpectancies = filters => async (dispatch, getState) => {
  const pageSize = pageSizeSelector(getState());
  const sort_by = sortBySelector(getState());
  const activeAgency = activeAgencySelector(getState());
  const page = 1;
  dispatch({ type: FETCH_ALL_LIFE_EXPECTANCIES, payload: [] });
  dispatch({ type: LOADING, payload: { le: true } });
  try {
    const response = await advisorhq.get("/underwriting/api/life_expectancies/", {
      params: {
        agency: activeAgency.id,
        page: 1,
        page_size: pageSize,
        ...filters,
        sort_by: sortByParam(sort_by),
      },
    });
    let { results, ...paginateData } = response.data;
    paginateData = { ...paginateData, page };
    dispatch({ type: FETCH_ALL_LIFE_EXPECTANCIES, payload: results });
    dispatch({ type: SET_LES_PAGINATION_DATA, payload: paginateData });
  } catch (error) {
    notifications.error("Error searching Life Expectancies");
  }
  dispatch({ type: LOADING, payload: { le: false } });
};

export const fetchLifeExpectanciesWithCurrentPage = () => async (dispatch, getState) => {
  const filters = storedLeFiltersSelector(getState());
  const pageSize = pageSizeSelector(getState());
  dispatch({ type: LOADING, payload: { cases: true } });
  const sort_by = sortBySelector(getState());
  const activeAgency = activeAgencySelector(getState());
  const page = currentPageLeSelector(getState());
  const response = await advisorhq.get("/underwriting/api/life_expectancies/", {
    params: {
      agency: activeAgency.id,
      page: page,
      page_size: pageSize,
      ...filters,
      sort_by: sortByParam(sort_by),
    },
  });
  let { results, ...paginateData } = response.data;
  paginateData = { ...paginateData, page };
  dispatch({ type: FETCH_ALL_LIFE_EXPECTANCIES, payload: results });
  dispatch({ type: SET_LES_PAGINATION_DATA, payload: paginateData });
  dispatch({ type: LOADING, payload: { cases: false } });
};

export const updateLifeExpectancy = (id, data) => async dispatch => {
  dispatch({ type: EDIT_LIFE_EXPECTANCY, payload: { id: id, ...data } });
};

export const editLifeExpectancy = id => async (dispatch, getState) => {
  const state = getState();
  let lifeExpectancyData = state.lifeSettlement.management.lifeExpectancies.lifeExpectancies[id];
  delete lifeExpectancyData.vendor; // this is handled in the database during CaseDocument save
  const data = (({ file, ...o }) => o)(lifeExpectancyData); // remove file
  const response = await advisorhq.patch(`/underwriting/api/life_expectancies/${id}/`, data);
  dispatch({ type: EDIT_LIFE_EXPECTANCY, payload: response.data });
};

export const fetchLifeExpectancyChoices = () => async dispatch => {
  const response = await advisorhq.get("/underwriting/api/choices/");
  dispatch({ type: FETCH_LIFE_EXPECTANCY_CHOICES, payload: response.data });
};

export const deleteLifeExpectancyFile = lifeExpectancyId => async dispatch => {
  const response = await advisorhq.delete(`/underwriting/api/life_expectancies/${lifeExpectancyId}/delete_file/`);
  dispatch({ type: EDIT_LIFE_EXPECTANCY, payload: response.data });
  notifications.success("Life expectancy file deleted successfully");
};

export const deleteLifeExpectancy = id => async dispatch => {
  await advisorhq.delete(`/underwriting/api/life_expectancies/${id}/`);
  dispatch({ type: DELETE_LIFE_EXPECTANCY, payload: id });
};

export const fetch21stMedicalConditionsSchema = () => async (dispatch, getState) => {
  // Check if schema was already fetched
  const state = getState();
  const schema = twentyFirstMedicalConditionsSchemaSelector(state);
  if (schema.length === 0) {
    const response = await advisorhq.get("/underwriting/api/21st_medical_conditions_schema/");
    dispatch({ type: FETCH_21ST_MEDICAL_CONDITIONS_SCHEMA, payload: response.data });
  }
};

export const fetchPredictiveMedicalConditionsSchema = () => async (dispatch, getState) => {
  // Check if schema was already fetched
  const state = getState();
  const schema = predictiveMedicalConditionsSchemaSelector(state);
  if (schema.length === 0) {
    const response = await advisorhq.get("/underwriting/api/predictive_medical_conditions_schema/");
    dispatch({
      type: FETCH_PREDICTIVE_MEDICAL_CONDITIONS_SCHEMA,
      payload: response.data,
    });
  }
};

export const storeRelatedLe = list => async (dispatch, getState) => {
  dispatch({ type: STORE_RELATED_LE, payload: list });
};

export const setActiveTab = applyOrderByParam => async (dispatch, getState) => {
  if (applyOrderByParam === "cases") {
    dispatch({ type: SORT_BY, payload: { id: "submission_date", desc: true } });
  } else if (applyOrderByParam === "lifeexpectancy") {
    dispatch({ type: SORT_BY, payload: { id: "certificate_date", desc: false } });
  } else if (applyOrderByParam === "tasks") {
    dispatch({ type: SORT_BY, payload: { id: "due_date", desc: false } });
  } else if (applyOrderByParam === "leads") {
    dispatch({ type: SORT_BY, payload: { id: "system_id", desc: true } });
  } else if (applyOrderByParam === "parameters") {
    dispatch({ type: SORT_BY, payload: { id: "code", desc: false } });
  }
  dispatch({ type: SET_ACTIVE_TAB, payload: applyOrderByParam });
};

export const storeLesFiltersData = filters => async (dispatch, getState) => {
  dispatch({ type: STORE_LE_FILTERS_DATA, payload: filters });
};

export const initPagination = () => async (dispatch, getState) => {
  const initValues = {
    next: "",
    previous: "",
    page: 1,
  };
  dispatch({ type: SET_CASES_PAGINATION_DATA, payload: initValues });
};

export const setCurrentLETableIndex = index => async (dispatch, getState) => {
  dispatch({ type: SET_LE_TABLE_INDEX, payload: index });
};
