import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { EditorField, FormCollapsible, Select, StateSelect, SubmitButton, TextInput } from "components/forms";
import { shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";
import { isAuthorized, partial } from "../../utils";
import { createCarrier, editCarrier, unsetShouldSave } from "actions";
import * as notifications from "../../notifications";
import {
  IRREVOCABLE_BENEFICIARY_HANDLING_CHOICES,
  PERMISSIONS,
  POLICY_AUTH_HANDLING_CHOICES,
  POS_SUPPLEMENTARY_CONTACT_CHOICES,
} from "../../constants";

const CarrierForm = ({ carrierInfo, hasAdminPermission, ...props }) => {
  if (!carrierInfo) {
    // Still didn't fetch data
    return null;
  }

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...carrierInfo,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);

        const method =
          isEmpty(carrierInfo) || carrierInfo.id === undefined
            ? props.createCarrier
            : partial(props.editCarrier, carrierInfo.id);

        try {
          await method(values);
          notifications.success("Carrier saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Carrier");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <FormCollapsible onSubmit={handleSubmit} title="Carrier">
              <Form.Row>
                <TextInput label="Name" name="name" disabled={!hasAdminPermission} />
                <TextInput label="Phone" name="phone" disabled={!hasAdminPermission} />
                <TextInput label="Fax" name="fax" disabled={!hasAdminPermission} />
                <TextInput label="Email" name="email" lowercase={true} disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <TextInput label="Street Address" name="street_address" disabled={!hasAdminPermission} />
                <TextInput label="City" name="city" disabled={!hasAdminPermission} />
                <StateSelect label="State" name="state" placeholder="Select State" disabled={!hasAdminPermission} />
                <TextInput label="Zip Code" name="zipcode" disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <TextInput label="Short Name" name="short_name" disabled={!hasAdminPermission} />
                <TextInput label="Website" name="website" disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Policy Auth Handling"
                  name="policy_auth_handling"
                  placeholder="Select"
                  options={POLICY_AUTH_HANDLING_CHOICES}
                  disabled={!hasAdminPermission}
                  md={4}
                />
                <Select
                  label="Irrevocable Beneficiary Handling"
                  name="irrevocable_beneficiary_handling"
                  placeholder="Select"
                  options={IRREVOCABLE_BENEFICIARY_HANDLING_CHOICES}
                  disabled={!hasAdminPermission}
                  md={4}
                />
                <Select
                  label="POS Supplementary Contacts"
                  name="pos_supplementary_contacts"
                  placeholder="Select"
                  options={POS_SUPPLEMENTARY_CONTACT_CHOICES}
                  disabled={!hasAdminPermission}
                  md={4}
                />
              </Form.Row>
              <Form.Row>
                <EditorField label="Notes" name="notes" disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <EditorField
                  label="Term Conversion Guidelines"
                  name="term_conversion_guidelines"
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
            </FormCollapsible>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const roles = state.auth.activeAgencyRoles;
  return {
    shouldSave: shouldSaveSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  createCarrier,
  editCarrier,
  unsetShouldSave,
})(CarrierForm);
