import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { FormCollapsible, Select, SubmitButton, TextInput } from "components/forms";
import { activeParameterSelector } from "reducers";
import * as notifications from "notifications";
import { createParameter, editParameter, fetchPolicy } from "actions";
import { Form } from "react-bootstrap";
import {
  PARAMETER_CATEGORY_CHOICES,
  PARAMETER_SUB_CATEGORY_CHOICES,
  PARAMETER_SUB_CATEGORY_CHOICES__CONDITIONS,
  PARAMETER_SUB_CATEGORY_CHOICES__PROFILES,
  PARAMETER_SUB_CATEGORY_CHOICES__TREATMENTS,
} from "../constants";

const ParameterForm = ({ parameter, ...props }) => {
  let [parameterSubChoices, setParameterChoices] = React.useState(PARAMETER_SUB_CATEGORY_CHOICES);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...parameter,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = props.createParameter;

        try {
          await method(values);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Parameter");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <FormCollapsible onSubmit={handleSubmit} title={"New Parameter"}>
              <Form.Row>
                <Select
                  label="Health Parameter Category"
                  name="category"
                  placeholder="Select Category"
                  options={PARAMETER_CATEGORY_CHOICES}
                  onChangeCallback={option => {
                    if (option?.value === "CONDITION") {
                      setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES__CONDITIONS);
                    } else if (option?.value === "PROFILE") {
                      setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES__PROFILES);
                    } else if (option?.value === "TREATMENT") {
                      setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES__TREATMENTS);
                    } else {
                      setParameterChoices(PARAMETER_SUB_CATEGORY_CHOICES);
                    }
                  }}
                />
                <Select
                  label="Health Parameter Sub-Category"
                  name="sub_category"
                  placeholder="Select Sub-Category"
                  options={parameterSubChoices}
                />
                <TextInput label="Health Parameter Name" name="name" />
              </Form.Row>
              <SubmitButton />
            </FormCollapsible>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const currentParameter = activeParameterSelector(state) || {};

  return {
    parameter: currentParameter,
  };
};

export default connect(mapStateToProps, {
  createParameter,
  editParameter,
  fetchPolicy,
})(ParameterForm);
