import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  Select,
  FormFilter,
  SubmitSearchButton,
  ClearFiltersButton,
  DateInput,
  TextInput,
  RowSeparator,
} from "components/forms";
import { fetchRecords } from "actions/life_settlement/records";
import { managementChoicesSelector, userChoicesSelector } from "reducers";
import { fetchLifeSettlementChoices, fetchManagerAgents, storeRecordsFiltersData } from "actions";
import { TASK_TYPES } from "../accounts/constants";
import { PERMISSIONS, requiredOfChoices, tasksStageChoices, tasksStatusChoices } from "../../constants";
import { TaskTab, TaskTypesChoices } from "./constants";
import { isAuthorized } from "../../utils";

const RecordsFilterForm = ({ type = TaskTab.ALL, record_owner, ...props }) => {
  if (!record_owner) {
    return null;
  }
  React.useEffect(() => {
    props.fetchManagerAgents();
    props.fetchLifeSettlementChoices();
  }, []);

  const handleStageChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedValues = selectedOptions.map(o => o.value).join(",");
      setStage(commaSeparatedValues);
    } else {
      setStage(undefined);
    }
  };

  let stage_initial = [
    { value: "Opportunity", label: "Opportunity" },
    { value: "Price Discovery", label: "Price Discovery" },
    { value: "In Closing", label: "In Closing" },
    { value: "Asset", label: "Asset" },
  ];
  let [stage, setStage] = React.useState("Opportunity,Price Discovery,In Closing,Asset");

  return (
    <Formik
      initialValues={{ stage, record_owner, status: 1 }}
      onSubmit={async (values, { setSubmitting }) => {
        if (type !== TaskTab.ALL) {
          values.record_type = type;
        }
        props.storeRecordsFiltersData({ ...values, stage });
        await props.fetchTasks({ ...values, stage });
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, resetForm, setFieldValue }) => {
        const stageRef = useRef();
        return (
          <FormFilter onSubmit={handleSubmit} title={"Task Search"}>
            <Form.Row>
              <TextInput label="Record ID" name="record_id" md={3} />
              <TextInput label="Record Name" name="record_name" md={3} />
              {type !== TaskTab.CASE_REQUIREMENT && type !== TaskTab.CASE && (
                <Select label="Task Type" name="task_type" options={TASK_TYPES} md={3} />
              )}
            </Form.Row>
            <Form.Row>
              <DateInput label="Due Date Start Range" name="due_date_start" />
              <DateInput label="Due Date End Range" name="due_date_end" />
              <DateInput label="Create Date Start Range" name="create_date_start" />
              <DateInput label="Create Date End Range" name="create_date_end" />
            </Form.Row>
            <Form.Row>
              <Select
                label="Task Owner"
                name="record_owner"
                options={props.userChoices}
                disabled={!props.hasAdminPermission}
                md={3}
              />
              {type !== TaskTab.CASE_REQUIREMENT && (
                <Select label="Task Status" name="status" options={tasksStatusChoices} md={3} />
              )}
              <Select label="Task Creator" name="record_creator" options={props.userChoices} md={3} />
              <Select label="Last Editor" name="last_editor" options={props.userChoices} md={3} />
            </Form.Row>
            <RowSeparator />
            <Form.Row>
              {type !== TaskTab.LEAD && type !== TaskTab.ACCOUNT && (
                <Select
                  label="Stage"
                  name="stage"
                  placeholder="Select Stage"
                  isMulti={true}
                  onChange={handleStageChange}
                  options={tasksStageChoices}
                  defaultValue={stage_initial}
                  submitRef={stageRef}
                  md={3}
                />
              )}
              {(type === TaskTab.ACCOUNT || type === TaskTab.ALL) && (
                <Select
                  label="National Account"
                  name="national_account"
                  placeholder="Select National Account"
                  options={props.nationalAccountChoices.getChoices({ sortByLabel: true })}
                  md={3}
                />
              )}
              {(type === TaskTab.CASE_REQUIREMENT || type === TaskTab.ALL) && (
                <Select
                  label="Responsible Party"
                  name="responsible_party"
                  placeholder="Select Responsible Party"
                  options={requiredOfChoices}
                  md={3}
                />
              )}
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetForm} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const userChoices = userChoicesSelector(state).getChoices({ sortByLabel: true });
  const roles = state.auth.activeAgencyRoles;

  return {
    record_owner: state.auth.user_id,
    userChoices,
    userId: state.auth.user_id,
    nationalAccountChoices: choices.national_accounts,
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  fetchTasks: fetchRecords,
  fetchManagerAgents,
  fetchLifeSettlementChoices,
  storeRecordsFiltersData,
})(RecordsFilterForm);
