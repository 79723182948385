import React from "react";
import { connect } from "react-redux";

import {
  selectAuctionRound,
  selectAuctionActivity,
  fetchAuction,
  fetchFundersForClosing,
  showModal,
  downloadFile,
  unsetShouldSave,
} from "actions";
import {
  activeCaseSelector,
  auctionAccessEnabledSelector,
  auctionIdSelector,
  loadingSelector,
  selectedFundersSelector,
  shouldSaveSelector,
} from "reducers";
import CaseClosingDetailForm from "./CaseClosingDetailForm";

const ClosingPage = props => {
  const closingDetailFormSubmitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      setTimeout(() => {
        closingDetailFormSubmitRef.current.click();
      }, 500);
      setTimeout(() => {
        unsetShouldSave();
      }, 1000);
    }
  }, [props.shouldSave]);

  React.useEffect(() => {
    if (props.caseInfo.submission_id) {
      document.title = `LifeRoc | ${props.caseInfo.submission_id} | Closing`;
    }
    props.fetchFundersForClosing();
  }, [props.caseInfo.id]);

  if (props.loading.error404) {
    return <></>;
  }

  return (
    <>
      <CaseClosingDetailForm activeCaseId={props.caseInfo.id} submitRef={closingDetailFormSubmitRef} />
    </>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    loading: loadingSelector(state),
    auctionAccessEnabled: auctionAccessEnabledSelector(state),
    selectedFunders: selectedFundersSelector(state),
    auctionId: auctionIdSelector(state),
    shouldSave: shouldSaveSelector(state),
  };
};

export default connect(mapStateToProps, {
  selectAuctionRound,
  selectAuctionActivity,
  showModal,
  downloadFile,
  fetchAuction,
  fetchFundersForClosing,
})(ClosingPage);
