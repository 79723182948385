import * as types from "../types";
import { activeCaseFileSelector, activeCaseIdSelector } from "reducers";
import advisorhq from "../../apis/advisorhq";
import * as notifications from "../../notifications";
import {
  DELETE_CASE_FILE,
  DELETE_CASE_FILE_FILE,
  EDIT_LIFE_EXPECTANCY,
  EXTRACT_LIFE_EXPECTANCY_PDF_DATA,
  FETCH_LIFE_EXPECTANCIES,
  STORE_CASE_FILE_CATEGORY,
  CLEAR_CASE_FILE_CATEGORY,
  STORE_CASE_FILE_DESCRIPTION,
} from "../types";
import { hideModal, showModal } from "../modals";
import { LOADING_MODAL } from "../../components/modals";

export const selectCaseFile = data => ({
  type: types.SELECT_CASE_FILE,
  payload: data,
});

export const fetchCaseFiles = () => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { caseFiles: true } });
  dispatch({ type: types.CLEAR_CASE_FILES });
  const activeCaseId = activeCaseIdSelector(getState());
  const response = await advisorhq.get("/life_settlement/api/case_file/", {
    params: { case: activeCaseId },
  });
  dispatch({ type: types.FETCH_CASE_FILES, payload: response.data });
  dispatch({ type: types.LOADING, payload: { caseFiles: false } });
};

export const fetchCaseFilesByCaseDocumentId = id => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { caseFiles: true } });
  const response = await advisorhq.get("/life_settlement/api/case_file/" + id + "/");
  dispatch({ type: types.FETCH_CASE_FILE, payload: response.data });
  dispatch({ type: types.SELECT_CASE_FILE, payload: response.data });
  dispatch({ type: types.LOADING, payload: { caseFiles: false } });
};

export const createCaseFile = (data, skipLoading) => async (dispatch, getState) => {
  if (!skipLoading) {
    dispatch({ type: types.LOADING, payload: { caseFiles: true } });
  }
  const endpoint = `/life_settlement/api/case_file/`;
  data.case = activeCaseIdSelector(getState());
  const response = await advisorhq.post(endpoint, data);
  dispatch({ type: types.CREATE_CASE_FILE, payload: response.data });
  if (!skipLoading) {
    dispatch({ type: types.LOADING, payload: { caseFiles: false } });
  }
};

export const editCaseFile = (caseFileId, newValues, row) => async (dispatch, getState) => {
  if (caseFileId) {
    dispatch({ type: types.LOADING, payload: { caseFiles: true } });
    const endpoint = `/life_settlement/api/case_file/${caseFileId}/`;
    const response = await advisorhq.patch(endpoint, newValues);
    dispatch({ type: types.EDIT_CASE_FILE, payload: response.data });
    dispatch({ type: types.LOADING, payload: { caseFiles: false } });
  } else {
    // Attempting to edit with empty id will create it instead
    dispatch({ type: types.LOADING, payload: { caseFiles: true } });
    const endpoint = `/life_settlement/api/case_file/`;
    let data = {
      ...row,
      ...newValues,
      case: activeCaseIdSelector(getState()),
    };
    const response = await advisorhq.post(endpoint, data);
    dispatch({ type: types.CREATE_CASE_FILE, payload: response.data });
    dispatch({ type: types.LOADING, payload: { caseFiles: false } });
  }
};

export const editMultipleCaseFiles = data => async (dispatch, getState) => {
  notifications.success("Saving...");
  dispatch({ type: types.LOADING, payload: { caseFiles: true } });
  const endpoint = "/life_settlement/api/case_multiple_documents/";
  const response = await advisorhq.post(endpoint, data);
  dispatch({ type: types.EDIT_MULTIPLE_CASE_FILES, payload: response.data });
  dispatch({ type: types.LOADING, payload: { caseFiles: false } });
  notifications.success("Saved");
};

export const uploadCaseFile = file => async (dispatch, getState) => {
  const activeCaseFile = activeCaseFileSelector(getState());
  const activeCaseFileId = activeCaseFile.id;
  const isLifeExpectancy = activeCaseFile.sub_category === "LE";
  const formData = new FormData();
  formData.append("file", file, file.name);

  let message = "Uploading file...";
  if (isLifeExpectancy) {
    message = "Processing uploaded file, this might take a few seconds...";
  }

  dispatch({ type: types.LOADING, payload: { caseFiles: true } });
  dispatch(showModal(LOADING_MODAL, { message: message }));
  try {
    const response = await advisorhq.post(`/life_settlement/api/case_file/${activeCaseFileId}/upload_file/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    dispatch(hideModal(LOADING_MODAL));
    if (isLifeExpectancy) {
      dispatch({ type: EDIT_LIFE_EXPECTANCY, payload: response.data });
      dispatch(extractLifeExpectancyPdfData(activeCaseFileId));
    } else {
      dispatch({ type: types.EDIT_CASE_FILE, payload: response.data });
      dispatch({ type: types.LOADING, payload: { caseFiles: false } });
    }
    notifications.success("Case File uploaded successfully");
  } catch (error) {
    notifications.error(`Problem uploading file: ${error.response.data}`);
    dispatch(hideModal(LOADING_MODAL));
    dispatch({ type: types.LOADING, payload: { caseFiles: false } });
  }
};

export const uploadRedactedFile = (file, type) => async (dispatch, getState) => {
  const activeCaseFile = activeCaseFileSelector(getState());
  const activeCaseFileId = activeCaseFile.id;
  const formData = new FormData();
  formData.append("file", file, file.name);

  let message = "Uploading file...";
  dispatch({ type: types.LOADING, payload: { caseFiles: true } });
  dispatch(showModal(LOADING_MODAL, { message: message }));
  try {
    const response = await advisorhq.post(`/life_settlement/api/case_file/${activeCaseFileId}/upload_file/`, formData, {
      params: {
        type: type,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    dispatch(hideModal(LOADING_MODAL));
    dispatch({ type: types.EDIT_CASE_FILE, payload: response.data });
    dispatch({ type: types.LOADING, payload: { caseFiles: false } });
    notifications.success("Case File uploaded successfully");
  } catch (error) {
    notifications.error(`Problem uploading file: ${error.response.data}`);
    dispatch(hideModal(LOADING_MODAL));
    dispatch({ type: types.LOADING, payload: { caseFiles: false } });
  }
};

export const extractLifeExpectancyPdfData = caseFileId => async dispatch => {
  try {
    const response = await advisorhq.get(`/life_settlement/api/case_file/${caseFileId}/extract_pdf_data/`);
    dispatch({ type: EXTRACT_LIFE_EXPECTANCY_PDF_DATA, payload: response.data });
  } catch (error) {
    notifications.error(error.response.data.detail);
  }
};

export const deleteCaseFile = id => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { caseFiles: true } });
  const url = `/life_settlement/api/case_file/${id}/`;
  await advisorhq.delete(url);
  dispatch({ type: DELETE_CASE_FILE, payload: id });
  dispatch({ type: types.LOADING, payload: { caseFiles: false } });
  notifications.success("Case File deleted successfully");

  // re-fetch
  const activeCaseId = activeCaseIdSelector(getState());
  const response = await advisorhq.get("/underwriting/api/life_expectancies/", {
    params: { case: activeCaseId },
  });
  dispatch({ type: FETCH_LIFE_EXPECTANCIES, payload: response.data });
};

export const deleteCaseFileFile = id => async (dispatch, getState) => {
  // Note: This deletes the File inside the CaseFile
  dispatch({ type: types.LOADING, payload: { caseFiles: true } });
  const url = `/life_settlement/api/case_file/${id}/delete_file/`;
  const response = await advisorhq.delete(url);
  dispatch({ type: DELETE_CASE_FILE_FILE, payload: response.data });
  dispatch({ type: types.LOADING, payload: { caseFiles: false } });
  notifications.success("Case File deleted successfully");
};

export const deleteMultipleCaseFiles = selectedRows => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { caseFiles: true } });
  let selected_ids = [...selectedRows];
  await Promise.all(
    selected_ids.map(async selected_id => {
      const url = `/life_settlement/api/case_file/${selected_id}/`;
      await advisorhq.delete(url);
      dispatch({ type: DELETE_CASE_FILE, payload: selected_id });
    }),
  );
  dispatch({ type: types.LOADING, payload: { caseFiles: false } });
  notifications.success("Case File deleted successfully");
};

export const secondaryGenerate = () => async (dispatch, getState) => {
  const activeCaseId = activeCaseIdSelector(getState());
  dispatch({ type: types.LOADING, payload: { caseFiles: true } });
  const url = "/life_settlement/api/secondary_generate/";
  try {
    const response = await advisorhq.post(url, { case_id: activeCaseId });
    dispatch({ type: types.FETCH_CASE_FILES, payload: response.data });
    dispatch({ type: types.LOADING, payload: { caseFiles: false } });
    notifications.success("Secondary Generate completed successfully");
  } catch (error) {
    notifications.error("Problem during Secondary Generate");
  }
};

export const storeCaseFileCategory = value => async dispatch => {
  dispatch({ type: STORE_CASE_FILE_CATEGORY, payload: value });
};

export const clearCaseFileCategory = list => async dispatch => {
  dispatch({ type: CLEAR_CASE_FILE_CATEGORY });
};

export const storeCaseFileDescription = value => async dispatch => {
  dispatch({ type: STORE_CASE_FILE_DESCRIPTION, payload: value });
};

export const clearCaseFileDescription = list => async dispatch => {
  dispatch({ type: CLEAR_CASE_FILE_CATEGORY });
};

export const fetchDoctors = values => async (dispatch, getState) => {
  console.log("fetching doctors...");
  try {
    const response = await advisorhq.get("/life_settlement/api/doctors/", {
      params: values,
    });
    console.log("fetch doctors response", response);
    return response.data;
  } catch (e) {
    console.log(e);
    if (e.response?.data?.errors) {
      notifications.error(e.response.data.errors[0].description);
    } else {
      notifications.error("Problem requesting doctors");
    }
  }
};

export const selectDoctorFromNPIData = data => async (dispatch, getState) => {
  // Prepare values for DoctorDetailForm
  let npi = data ? data.number : "";
  let npi_name = "";
  let npi_enumeration_date = "";
  if (data && data.basic) {
    if (data.basic.organization_name) {
      npi_name = data.basic.organization_name;
    } else if (data.basic.first_name || data.basic.last_name) {
      npi_name = `${data.basic.first_name} ${data.basic.last_name}`;
    }
    npi_enumeration_date = data?.basic.enumeration_date;
  }
  let npi_specialty = "";
  if (data && data.taxonomies) {
    npi_specialty = data.taxonomies[0]?.desc;
  }
  let npi_phone_number = "";
  let npi_street_address = "";
  let npi_city = "";
  let npi_state = "";
  let npi_zip_code = "";
  let npi_fax_number = "";
  if (data && data.addresses) {
    npi_phone_number = data.addresses[0].telephone_number;
    npi_street_address = data.addresses[0].address_1;
    npi_city = data.addresses[0].city;
    npi_state = data.addresses[0].state;
    npi_zip_code = data.addresses[0].postal_code;
    npi_fax_number = data.addresses[0].fax_number;
  }
  let doctorValues = {
    npi,
    npi_name,
    npi_specialty,
    npi_street_address,
    npi_city,
    npi_state,
    npi_zip_code,
    npi_enumeration_date,
    npi_phone_number,
    npi_fax_number,
    street_address: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    fax: "",
    email: "",
    payment_method: "",
    medical_records_person: "",
    delivery_method: "",
    copy_service: "",
    notes: "",
  };

  // Check the database for any saved Doctor info
  if (npi) {
    let endpoint = `/life_settlement/api/doctor/${npi}/`;
    try {
      const response = await advisorhq.get(endpoint);
      console.log("selectDoctor LifeRoc response:", response.data);
      // If we have the doctor already saved, concatenate the results
      doctorValues = {
        npi: data.number,
        ...doctorValues,
        ...response.data,
      };
      console.log("doctorValues:", doctorValues);
    } catch (error) {
      // If the doctor wasn't saved, default to NPI data
      doctorValues.npi = data.number;
      doctorValues.street_address = npi_street_address;
      doctorValues.city = npi_city;
      doctorValues.state = npi_state;
      doctorValues.zipcode = npi_zip_code;
      doctorValues.phone = npi_phone_number;
      doctorValues.fax = npi_fax_number;
    }
  }
  dispatch({ type: types.SELECT_DOCTOR, payload: doctorValues });
};

export const selectDoctor = data => ({
  type: types.SELECT_DOCTOR,
  payload: data,
});

export const saveDoctor = values => async (dispatch, getState) => {
  let endpoint = `/life_settlement/api/save_case_document_doctor/`;
  const activeCaseFile = activeCaseFileSelector(getState());
  const activeCaseFileId = activeCaseFile.id;
  const data = {
    case_document: activeCaseFileId,
    ...values,
  };
  try {
    await advisorhq.post(endpoint, data);
    notifications.success("Doctor saved successfully");
  } catch (e) {
    notifications.error("Problem saving Doctor");
  }
};

export const redactFiles = (files, processes, values) => async (dispatch, getState) => {
  const activeCaseId = activeCaseIdSelector(getState());
  const url = "/life_settlement/api/redact_files/";
  const file_ids = files.map(obj => obj.id);
  try {
    const response = await advisorhq.post(url, {
      case_id: activeCaseId,
      file_ids,
      processes,
      values,
    });
    notifications.success("File Redaction in process, we'll email you when the files are redacted");
    console.log("Auto Redact response:", response.data);
  } catch (error) {
    notifications.error("Problem Redacting Files");
  }
};
