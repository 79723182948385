import React from "react";
import { connect } from "react-redux";
import { FaEdit, FaTrash } from "react-icons/fa";

import { showModal, selectProduct } from "actions";
import { loadingProductsSelector, productsSelector } from "reducers";
import IconLink from "components/IconLink";
import Table, { dateCell } from "components/table";
import { PRODUCT_MODAL, DELETE_PRODUCT_MODAL } from "components/modals";

const ProductDatabaseTable = ({ products, showModal, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectProduct(row.original.id);
                showModal(PRODUCT_MODAL);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon red" }}
              tooltip="Delete"
              onClick={() => {
                props.selectProduct(row.original.id);
                showModal(DELETE_PRODUCT_MODAL, {
                  id: row.original.id,
                });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Product ID",
      accessor: "product_id",
      className: "wrap-text",
    },
    {
      Header: "Carrier",
      accessor: "carrier_name",
      className: "wrap-text",
    },
    {
      Header: "Product Type",
      accessor: "product_type",
      className: "wrap-text",
    },
    {
      Header: "Insured Lives",
      accessor: "number_of_insured_lives",
      className: "wrap-text",
    },
    {
      Header: "Product Name",
      accessor: "name",
      className: "wrap-text",
    },
    {
      Header: "Form Number",
      accessor: "form_number",
      className: "wrap-text",
    },
    {
      Header: "Issue State",
      accessor: "issue_states",
      className: "wrap-text",
    },
    {
      Header: "Issue Date Start",
      accessor: "issue_date_start",
      Cell: dateCell,
    },
    {
      Header: "Issue Date End",
      accessor: "issue_date_end",
      Cell: dateCell,
    },
    {
      Header: "Age Basis",
      accessor: "age_basis",
      Cell: ({ row }) => {
        if (row.original.age_basis === "NEAR") {
          return <>Nearest</>;
        } else if (row.original.age_basis === "LAST") {
          return <>Last</>;
        } else {
          return <></>;
        }
      },
    },
    {
      Header: "Product Template?",
      Cell: ({ row }) => {
        if (row.original.product_template_id) {
          return <>Yes</>;
        } else {
          return <>No</>;
        }
      },
    },
    {
      Header: "Audited",
      Cell: ({ row }) => {
        if (row.original.audited) {
          return <>Yes</>;
        } else {
          return <>No</>;
        }
      },
    },
    {
      Header: "Related Cases",
      accessor: "related_cases_number",
      className: "wrap-text",
    },
  ];

  return (
    <Table
      columns={columns}
      data={products}
      emptyMessage={props.productsLoading ? "Loading Products data..." : "No data"}
      initialPageSize={50}
      defaultSort={true}
      defaultPagination={true}
    />
  );
};

const mapStateToProps = state => {
  return {
    products: productsSelector(state),
    productsLoading: loadingProductsSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectProduct,
})(ProductDatabaseTable);
