import React from "react";
import { connect } from "react-redux";

import { showModal, downloadFile, selectClinicalResearch, fetchClinicalResearch } from "actions";
import { activeParameterSelector, clinicalResearchSelector } from "reducers";
import Table, { dateCell } from "components/table";
import IconLink from "components/IconLink";
import { FaDownload, FaEdit, FaTrash } from "react-icons/fa";
import { CLINICAL_RESEARCH_MODAL, DELETE_CLINICAL_RESEARCH_MODAL } from "components/modals";

const ClinicalResearchFilesTable = ({ parameterInfo, ...props }) => {
  if (!parameterInfo) {
    // Still didn't fetch data
    return null;
  }

  React.useEffect(() => {
    props.fetchClinicalResearch({
      parameter_id: parameterInfo.id,
    });
  }, []);

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const clinicalResearchId = row.original.id;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectClinicalResearch(row.original.id);
                props.showModal(CLINICAL_RESEARCH_MODAL);
              }}
            />
            {row.original.file ? (
              <IconLink
                Icon={FaDownload}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="Download"
                onClick={() => {
                  const url = `/api/clinical_research/${clinicalResearchId}/download_file/`;
                  props.downloadFile(url, row.original.file.name);
                }}
              />
            ) : null}
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon btn--red" }}
              tooltip="Delete"
              onClick={() => {
                props.showModal(DELETE_CLINICAL_RESEARCH_MODAL, {
                  id: row.original.id,
                });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Publication",
      accessor: "publication",
    },
    {
      Header: "Authors",
      accessor: "authors",
    },
    {
      Header: "Summary",
      accessor: "summary",
    },
    {
      Header: "Date Added",
      accessor: "date_created",
      Cell: dateCell,
    },
    {
      Header: "Publication Date",
      accessor: "publication_date",
      Cell: dateCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.clinicalResearchFiles}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Clinical Research Files found"
    />
  );
};

const mapStateToProps = state => {
  const parameterInfo = activeParameterSelector(state);
  const clinicalResearchFiles = clinicalResearchSelector(state);

  return {
    parameterInfo,
    clinicalResearchFiles,
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectClinicalResearch,
  downloadFile,
  fetchClinicalResearch,
})(ClinicalResearchFilesTable);
