import {
  CLEAN_RECORDS_PAGINATION_DATA,
  FETCH_RECORDS,
  LOADING,
  SET_RECORDS_PAGINATION_DATA,
  STORE_RECORDS_FILTERS_DATA,
} from "actions/types";
import advisorhq from "apis/advisorhq";
import * as types from "../types";
import { activeAgencySelector, currentPageCaseSelector, pageSizeSelector, sortBySelector } from "../../reducers";
import { sortByParam } from "../../utils";
import { ENDPOINTS_TO_URL_MAPPING } from "../pagination/constants";

export const cleanRecords = () => async dispatch => {
  dispatch({ type: FETCH_RECORDS, payload: [] });
  dispatch({ type: CLEAN_RECORDS_PAGINATION_DATA });
};

export const fetchRecords = (filters, orderBy) => async (dispatch, getState) => {
  const activeAgency = activeAgencySelector(getState());
  const pageSize = pageSizeSelector(getState());
  dispatch({ type: LOADING, payload: { records: true } });
  const page = currentPageCaseSelector(getState()) || 1;
  const sort_by = sortBySelector(getState());
  const sort_by_value = sortByParam(sort_by);
  const response = await advisorhq.get(ENDPOINTS_TO_URL_MAPPING.TASKS, {
    params: {
      agency: activeAgency.id,
      page: page,
      page_size: pageSize,
      sort_by: sort_by_value,
      ...filters,
    },
  });
  let { results, ...paginateData } = response.data;
  paginateData = { ...paginateData, page };
  dispatch({ type: SET_RECORDS_PAGINATION_DATA, payload: paginateData });
  dispatch({ type: FETCH_RECORDS, payload: results });
  dispatch({ type: LOADING, payload: { records: false } });
};

export const selectActiveRecordId = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_RECORD_ID, payload: id });
};

export const storeRecordsFiltersData = filters => async (dispatch, getState) => {
  dispatch({ type: STORE_RECORDS_FILTERS_DATA, payload: filters });
};
