import React from "react";
import { useField, useFormikContext } from "formik";
import { Form, InputGroup, Col } from "react-bootstrap";

import Label from "./Label";
import createMaskedInput from "./createMaskedInput";
import { validateLength, getValidationClassName } from "./validation";
import FieldErrors from "./FieldErrors";

const SSN_LENGTH = 9;

export default ({
  name = "social_security",
  md = "",
  label = "Social Security Number",
  ...props
}) => {
  const [field, meta] = useField({ name, validate: validateLength(SSN_LENGTH, /-/g) });
  const { setFieldValue } = useFormikContext();

  const d = /\d/;
  const ssnMask = [d, d, d, "-", d, d, "-", d, d, d, d];

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <Label label={label} required={props.isRequired} />
      <InputGroup>
        {createMaskedInput({
          mask: ssnMask,
          field,
          setFieldValue,
          className: getValidationClassName(meta),
          disabled: props.disabled,
        })}
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};
