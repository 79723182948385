import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { PRODUCT_MODAL } from "components/modals";
import ProductForm from "./ProductForm";
import ProductTemplateForm from "./ProductTemplateForm";
import ProductRelatedCasesTable from "./ProductRelatedCasesTable";

const ProductModal = ({ ...props }) => {
  return (
    <FullPageModal modalType={PRODUCT_MODAL}>
      <ProductForm callback={props.callback} />
      <ProductTemplateForm />
      <ProductRelatedCasesTable />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(ProductModal);
