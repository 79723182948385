import React from "react";
import { connect } from "react-redux";
import { clearSelectedParameter, selectParameter, showModal } from "actions";
import { activeParameterSelector } from "reducers";
import Table, { choiceCell } from "components/table";
import IconLink from "components/IconLink";
import { FaTrash } from "react-icons/fa";
import { REMOVE_RELATED_PARAMETER_MODAL } from "components/modals";
import { isAuthorized } from "../../../utils";
import { PERMISSIONS } from "../../../constants";
import { PARAMETER_CATEGORY_CHOICES } from "../constants";

const ParameterRelationsTable = ({ activeParameter, ...props }) => {
  const parameters = activeParameter.related_parameters;
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <>
            {props.hasAdminPermission && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon red" }}
                tooltip="Delete"
                onClick={() => {
                  props.showModal(REMOVE_RELATED_PARAMETER_MODAL, {
                    id: activeParameter.id,
                    relatedParameterId: row.original.id,
                  });
                }}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Health Parameter Category",
      accessor: "category",
      Cell: choiceCell(PARAMETER_CATEGORY_CHOICES),
    },
    {
      Header: "Health Parameter Sub-Category",
      accessor: "sub_category",
    },
    {
      Header: "Health Parameter Name",
      accessor: "name",
    },
    {
      Header: "Related ICD 10 CM Codes",
      accessor: "icd_10_codes",
    },
    {
      Header: "Health Parameter Code",
      accessor: "code",
    },
  ];

  return <Table columns={columns} data={parameters} paginated={false} defaultSort={true} />;
};

const mapStateToProps = (state, ownProps) => {
  const roles = state.auth.activeAgencyRoles;
  return {
    activeParameter: activeParameterSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectParameter,
  clearSelectedParameter,
})(ParameterRelationsTable);
