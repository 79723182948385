import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";

import { resetPassword } from "actions";
import { loginInfoSelector } from "reducers";
import { TextInput } from "components/forms";
import { URLS } from "../constants";
import { Link } from "react-router-dom";

const ResetPasswordForm = ({ ...props }) => {
  const [informationText, setInformationText] = React.useState(
    "Enter your email address and we'll send you a link to reset your password.",
  );
  const [resetSent, setResetSent] = React.useState(false);

  React.useEffect(() => {
    document.title = `LifeRoc | Reset Password`;
  }, []);

  return (
    <div className="login-page">
      <h3 className="form-title shadowed">Reset Password</h3>
      <Formik
        enableReinitialize={true}
        initialValues={{ email: props.email }}
        validationSchema={Yup.object({
          email: Yup.string().email("Invalid email address").required("Required"),
        })}
        onSubmit={async (formValues, { setSubmitting, setErrors }) => {
          try {
            const response = await props.resetPassword(formValues);
            if (response.data.msg) setInformationText(response.data.msg);
            setResetSent(true);
          } catch (error) {
            setErrors(error.response.data);
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit} className="login-form shadowed">
            <div className="reset-password-description">{informationText}</div>
            <div className="input">
              <TextInput
                label="Email"
                name="email"
                value={values.email}
                type="email"
                autoComplete="on"
              />
            </div>
            {resetSent ? null : (
              <button type="submit" className="btn login-btn">
                Reset password
              </button>
            )}
            <Link to={URLS.LOGIN}>
              <button type="button" className="reset-password">
                Cancel
              </button>
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = state => {
  return { ...loginInfoSelector(state) };
};

export default connect(mapStateToProps, { resetPassword })(ResetPasswordForm);
