import React from "react";
import { connect } from "react-redux";

import { createEmptyCarrier, setActiveTab, initPagination } from "actions";
import CarriersFilterForm from "./CarriersFilterForm";
import CarriersTable from "./CarriersTable";
import ButtonCreate from "../../components/buttons/ButtonCreate";
import { isAuthorized } from "../../utils";
import { PERMISSIONS } from "../../constants";

const CarriersPage = props => {
  const [activeSection, setActiveSection] = React.useState("carriers");
  React.useEffect(() => {
    props.setActiveTab(activeSection);
    props.initPagination();
    setActiveSection(activeSection);
    document.title = "LifeRoc | Carriers";
  }, []);

  return (
    <div>
      <CarriersFilterForm />
      {props.hasAdminPermission && (
        <ButtonCreate id="create-new-case" onClick={props.createEmptyCarrier} key="create">
          Add Carrier
        </ButtonCreate>
      )}
      <CarriersTable />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const roles = state.auth.activeAgencyRoles;
  return {
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  createEmptyCarrier,
  setActiveTab,
  initPagination,
})(CarriersPage);
