import React from "react";
import { connect } from "react-redux";
import { FieldArray, Formik } from "formik";
import { Form } from "react-bootstrap";

import { editOwnerEntityInfo, createSigner, showModal, fetchOwnerSignersForOwner } from "actions";
import {
  BooleanSelect,
  DateInput,
  FormCollapsible,
  PhoneInput,
  Select,
  StateSelect,
  SubmitButton,
  TIN_Input,
  TextInput,
  Collapsible,
} from "components/forms";
import { ownerEntitySelector, ownerSignersSelector, numberOfSignersSelector } from "reducers";
import * as notifications from "../../../notifications";
import { DELETE_OWNER_SIGNER_MODAL } from "components/modals";
import { ButtonCreate } from "../../../components/buttons";
import { isAuthorized } from "../../../utils";
import { entityTypeChoices, PERMISSIONS } from "../../../constants";

const OwnerEntityForm = ({
  ownerInfo,
  editOwnerEntityInfo,
  fetchOwnerSignersForOwner,
  signers,
  hasAdminPermission,
  ...props
}) => {
  const {
    entity_name,
    entity_type,
    formation_date,
    domicile_state,
    tin_number,
    fled_bk,
    street_address,
    city,
    state,
    zipcode,
  } = ownerInfo;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          entity_name,
          entity_type,
          formation_date,
          domicile_state,
          tin_number,
          fled_bk,
          street_address,
          city,
          state,
          zipcode,
          signers,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await editOwnerEntityInfo(ownerInfo.id, values);
            await fetchOwnerSignersForOwner(ownerInfo.id);
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Error editing owner information");
          }
          setSubmitting(false);
        }}
      >
        {formik => {
          return (
            <FormCollapsible onSubmit={formik.handleSubmit} title={`Owner Entity`}>
              <Form.Row>
                <TextInput label="Entity Name" name="entity_name" isRequired disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <Select
                  label="Entity Type"
                  name="entity_type"
                  placeholder="Select Entity Type"
                  options={entityTypeChoices}
                  isRequired
                  disabled={!hasAdminPermission}
                />
                <DateInput label="Formation Date" name="formation_date" isRequired disabled={!hasAdminPermission} />
                <StateSelect
                  label="Entity Domicile"
                  name="domicile_state"
                  placeholder="Select Entity Domicile State"
                  isRequired
                  disabled={!hasAdminPermission}
                />
              </Form.Row>
              <Form.Row>
                <TIN_Input label="Tax ID Number (TIN)" name="tin_number" isRequired disabled={!hasAdminPermission} />
                <TextInput label="# of Required Signers" name="_" value={props.numberOfSigners} disabled isRequired />
              </Form.Row>
              <Form.Row>
                <TextInput label="Street Address" name="street_address" isRequired disabled={!hasAdminPermission} />
              </Form.Row>
              <Form.Row>
                <TextInput label="City" name="city" isRequired disabled={!hasAdminPermission} />
                <StateSelect isRequired disabled={!hasAdminPermission} />
                <TextInput label="Zip Code" name="zipcode" isRequired disabled={!hasAdminPermission} />
              </Form.Row>
              <FieldArray
                name={`signers`}
                render={arrayHelpers => (
                  <>
                    {signers.map((signer, index) => {
                      let signerValues = arrayHelpers.form.values.signers[index];
                      return (
                        <Collapsible key={index} title={`Signer ${index + 1}`}>
                          <Form.Row>
                            <TextInput
                              label="Full Legal Name"
                              name={`signers[${index}].full_legal_name`}
                              isRequired
                              disabled={!hasAdminPermission}
                            />
                            <TextInput label="Title" name={`signers[${index}].title`} disabled={!hasAdminPermission} />
                          </Form.Row>
                          <Form.Row>
                            <PhoneInput
                              label="Phone Number"
                              name={`signers[${index}].telephone_number`}
                              disabled={!hasAdminPermission}
                            />
                            <TextInput
                              label="Email"
                              lowercase={true}
                              name={`signers[${index}].email`}
                              disabled={!hasAdminPermission}
                            />
                          </Form.Row>
                          <Form.Row>
                            <BooleanSelect
                              label="POA In Effect?"
                              name={`signers[${index}].active_POA`}
                              placeholder="Select POA in effect"
                              md={4}
                              isRequired
                              disabled={!hasAdminPermission}
                            />
                            <TextInput
                              label="POA Name"
                              name={`signers[${index}].POA_name`}
                              isRequired={signerValues && signerValues.active_POA === true}
                              disabled={(signerValues && signerValues.active_POA !== true) || !hasAdminPermission}
                            />
                          </Form.Row>
                          {hasAdminPermission && (
                            <Form.Row>
                              <button
                                className="btn red"
                                type="button"
                                onClick={() => props.showModal(DELETE_OWNER_SIGNER_MODAL, signers[index])}
                              >
                                Delete
                              </button>
                            </Form.Row>
                          )}
                        </Collapsible>
                      );
                    })}
                  </>
                )}
              />
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
              {hasAdminPermission && (
                <Form.Row>
                  <ButtonCreate
                    type={"button"}
                    onClick={() => {
                      props.createSigner(ownerInfo.id);
                    }}
                    // style={{ marginBottom: "2rem" }}
                    disabled={props.numberOfSigners === 5}
                    title={props.numberOfSigners === 5 ? "Max signers reached (5)" : ""}
                  >
                    Add Signer
                  </ButtonCreate>
                </Form.Row>
              )}
            </FormCollapsible>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state, { ownerId }) => {
  const roles = state.auth.activeAgencyRoles;
  return {
    ownerInfo: ownerEntitySelector(state, ownerId),
    signers: ownerSignersSelector(state, ownerId),
    numberOfSigners: numberOfSignersSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  editOwnerEntityInfo,
  createSigner,
  showModal,
  fetchOwnerSignersForOwner,
})(OwnerEntityForm);
