import React, { useCallback, useRef } from "react";
import { useField, useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import debounce from "lodash.debounce";

import Label from "./Label";
import { isInvalid } from "./validation";

import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

export default ({ label, name, initial, disabled = false, ...props }) => {
  const [field, meta] = useField({ name });
  const { setFieldValue } = useFormikContext();
  const width = props.width || "100%";

  const editor = useRef();

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = sunEditor => {
    editor.current = sunEditor;
  };

  React.useEffect(() => {
    // console.log(field.name, "getContents", editor.current.getContents());
    // console.log(field.name, "setContents", initial);
    if (initial !== undefined) {
      editor.current.setContents(initial);
    } else {
      // console.log("- skip setContents:", initial);
      // console.log("- current contents:", editor.current.getContents());
    }
  }, [initial]);

  let changeHandler = o => {
    setFieldValue(field.name, o);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 100), []);
  let buttons = [];
  let disabledStyles = "";
  if (!disabled) {
    buttons = [
      ["font", "fontSize", "formatBlock"],
      ["bold", "underline", "italic", "strike"],
      ["removeFormat"],
      ["outdent", "indent"],
      ["align", "horizontalRule", "list", "table"],
      ["link", "image"],
      ["fullScreen", "codeView"],
    ];
  } else {
    disabledStyles = "background-color: #ced4da;";
  }

  return (
    <>
      {label && (
        <div style={{ width: "100%" }}>
          <Label label={label} tooltip={props.tooltip} />
        </div>
      )}
      <input type="hidden" name={name} {...field} />
      <SunEditor
        getSunEditorInstance={getSunEditorInstance}
        height="100%"
        width={width}
        // hideToolbar={true}
        setContents={field.value}
        onChange={debouncedChangeHandler}
        setOptions={{
          height: "100%",
          width: width,
          buttonList: buttons,
          attributesWhitelist: { all: "style|table", "???": "*" },
          // Other option
        }}
        setDefaultStyle={`font-family: Arial; font-size: 16px; padding: 10px 15px; ${disabledStyles}`}
        disable={disabled}
      />
      {/* Hidden Form.Control so that errors show up */}
      <Form.Control hidden isInvalid={isInvalid(meta)} />
    </>
  );
};
