import React from "react";
import { IconContext } from "react-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

const addTooltip = (component, tooltip) => {
  const renderTooltip = (
    <Tooltip id="tooltip-with-html">
      <div dangerouslySetInnerHTML={{ __html: tooltip }} />
    </Tooltip>
  );

  return (
    <OverlayTrigger key="top" placement="top" overlay={renderTooltip}>
      <span>{component}</span>
    </OverlayTrigger>
  );
};

const IconLink = ({ to = "#", Icon, iconConfig = {}, tooltip, onClick, ...props }) => {
  const isAbsoluteUrl = to.startsWith("http://") || to.startsWith("https://");
  const link = isAbsoluteUrl ? (
    <a href={to} onClick={onClick} {...props}>
      <IconContext.Provider value={iconConfig}>{React.cloneElement(<Icon />)}</IconContext.Provider>
    </a>
  ) : (
    <Link to={to} onClick={onClick} {...props}>
      <IconContext.Provider value={iconConfig}>{React.cloneElement(<Icon />)}</IconContext.Provider>
    </Link>
  );

  if (tooltip) {
    return addTooltip(link, tooltip);
  }
  return link;
};

export default IconLink;
