import React from "react";
import { connect } from "react-redux";
import { activeAccountSelector, availableEntitiesSelector, managementChoicesSelector } from "reducers";
import { Card } from "react-bootstrap";
import AccountForm from "./AccountForm";
import { ACCOUNT_AFFILIATION_MODAL, CREATE_ACCOUNT_MODAL } from "components/modals";
import { RecordsTable } from "components/table";
import {
  fetchLifeSettlementChoices,
  fetchManagerAgents,
  selectAccountAffiliation,
  showModal,
  fetchAccountEntityChoices,
  createAccount,
  fetchEngagementActivities,
} from "actions";
import AccountAffiliationTable from "./affiliations/AccountAffiliationTable";
import TeamMemberTable from "./teamMembers/TeamMemberTable";
import { findAccountTypeFromLabel, getEntityIdFromAccount } from "../utils";
import EngagementActivitiesTable from "./engagementActivities/EngagementActivitiesTable";

const AccountInformationPage = ({
  accountInfo,
  activeHierarchySalesRepName,
  availableEntitiesForUser,
  entityId,
  findAccountTypeFromLabel,
  individualType,
  entityType,
  ...props
}) => {
  if (!accountInfo) {
    return null;
  }

  React.useEffect(() => {
    props.fetchManagerAgents();
    props.fetchLifeSettlementChoices();
    props.fetchAccountEntityChoices();
    props.fetchEngagementActivities();
    if (accountInfo && accountInfo.name) {
      document.title = `LifeRoc | ${accountInfo.name} | Account Information`;
    }
  }, []);

  return (
    <div className={`section-card shadowed case-section`} style={{ marginTop: "5.5rem" }}>
      <Card className={"case-info"}>
        <Card.Body>
          <AccountForm
            accountInfo={accountInfo}
            activeHierarchySalesRepName={activeHierarchySalesRepName}
            availableEntitiesForUser={availableEntitiesForUser}
          />
          <RecordsTable
            title="Affiliations"
            createButtonLabel="Add Affiliation"
            onCreateClick={() => {
              props.selectAccountAffiliation(null);
              props.showModal(ACCOUNT_AFFILIATION_MODAL);
            }}
            Table={AccountAffiliationTable}
          />
          <RecordsTable
            title="Team Members"
            createButtonLabel="Add Team Member"
            onCreateClick={() => {
              props.showModal(CREATE_ACCOUNT_MODAL, { accountType: individualType, entityId });
            }}
            buttonDisabled={!entityId}
            Table={TeamMemberTable}
          />
          <RecordsTable title="Engagement Activity" actionButtons={[]} Table={EngagementActivitiesTable} />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const accountInfo = activeAccountSelector(state);
  const sales_reps = choices.case_sales_representatives.getChoices();
  const availableEntitiesForUser = availableEntitiesSelector(state);

  let activeHierarchySalesRepName = "";
  if (accountInfo?.hierarchies) {
    const active_hierarchy_sales_rep = accountInfo.hierarchies.find(e => e.active);
    if (active_hierarchy_sales_rep?.sales_representative) {
      activeHierarchySalesRepName = sales_reps.find(
        e => e.value === active_hierarchy_sales_rep?.sales_representative,
      )?.label;
    }
  }

  return {
    accountInfo,
    hierarchies: accountInfo ? accountInfo.hierarchies : [],
    activeHierarchySalesRepName,
    availableEntitiesForUser,
    entityId: accountInfo ? getEntityIdFromAccount(accountInfo) : null,
    individualType: findAccountTypeFromLabel("Individual"),
  };
};

export default connect(mapStateToProps, {
  selectAccountAffiliation,
  fetchManagerAgents,
  fetchLifeSettlementChoices,
  fetchAccountEntityChoices,
  showModal,
  createAccount,
  findAccountTypeFromLabel,
  fetchEngagementActivities,
})(AccountInformationPage);
