import React from "react";
import { Form, Col } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import ReactSelect from "react-select";

import { getValidationClassName } from "./validation";
import Label from "./Label";
import FieldErrors from "./FieldErrors";

const Select = ({ label, options = [], emptyValue = "", md = "", submitRef = undefined, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  // Search value into possibly nested options (for now we search up to 1 nested level)
  const findOption = value => {
    let flattenedOpts = [];

    options.forEach(opt => {
      if (opt.options) {
        flattenedOpts = [...flattenedOpts, ...opt.options];
      } else {
        flattenedOpts.push(opt);
      }
    });
    return flattenedOpts.find(opt => opt.value === value);
  };

  let initialValue;
  if (field.value !== undefined && field.value !== emptyValue) {
    initialValue = findOption(field.value);
  } else {
    if (props.defaultValue !== undefined) {
      initialValue = findOption(props.defaultValue);
    } else {
      initialValue = emptyValue;
    }
  }

  const defaultOnChange = option => {
    let value;
    if (option === null) {
      value = props.defaultValue !== undefined ? props.defaultValue : emptyValue;
    } else {
      value = option.value;
    }
    setFieldValue(field.name, value);
    if (props.onChangeCallback) {
      props.onChangeCallback(option);
    }
  };

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999999 }),
    control: (provided, state) => ({
      ...provided,
      background: props.disabled ? "#e9ecef" : "#fff",
      minHeight: "36px",
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: state => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "36px",
    }),
  };

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md} padding0`}>
      {label && <Label label={label} required={props.isRequired} />}
      {props.append ? props.append : null}
      <ReactSelect
        {...props}
        maxMenuHeight={200}
        name={field.name}
        options={options}
        value={initialValue}
        onChange={props.onChange || defaultOnChange}
        className={`select ${getValidationClassName(meta)}`}
        isClearable
        menuPlacement="auto"
        styles={customStyles}
        isDisabled={props.disabled || false}
        ref={submitRef}
        classNamePrefix="case-form-select"
        menuPortalTarget={document.body}
      />
      <FieldErrors meta={meta} />
    </Form.Group>
  );
};

export default Select;
