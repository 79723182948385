import React from "react";
import "./style.css";
import { Card } from "react-bootstrap";
import { activeMortalityAssessmentSelector } from "../../../../reducers";
import { connect } from "react-redux";

import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from "recharts";

const MortalityAssessmentCurveGraphs = ({ mortalityAssessmentInfo, ...props }) => {
  const curvesData = mortalityAssessmentInfo?.curve_data;
  const initialAge = mortalityAssessmentInfo.curve_assessment_age;
  if (!curvesData) return null;
  const durationsMap = [];
  curvesData.forEach(row => {
    if (durationsMap === []) {
      durationsMap.push({ x: row.duration, y: 0 });
    } else if (durationsMap.some(element => element.x === row.duration)) {
    } else {
      durationsMap.push({ x: row.duration, y: 0, age: Number(initialAge) + row.duration });
    }
  });

  let qxCurvesData = durationsMap.map(row => {
    const accumulated_value = curvesData.reduce((accumulator, currentElem) => {
      if (currentElem.duration === row.x) {
        return currentElem.qx + accumulator;
      } else {
        return accumulator;
      }
    }, 0);
    return { x: row.x, y: accumulated_value / 12, age: row.age };
  });
  qxCurvesData.unshift({ x: 0, y: 0, age: Number(initialAge) });

  let survivalCurvesData = durationsMap.map(row => {
    const accumulated_value = curvesData.reduce((accumulator, currentElem) => {
      if (currentElem.duration === row.x) {
        return currentElem.eom_lives + accumulator;
      } else {
        return accumulator;
      }
    }, 0);
    return { x: row.x, y: accumulated_value / 12, age: row.age };
  });
  survivalCurvesData.unshift({ x: 0, y: 1, age: Number(initialAge) });

  let deathCurveData = durationsMap.map(row => {
    const accumulated_value = curvesData.reduce((accumulator, currentElem) => {
      if (currentElem.duration === row.x) {
        return currentElem.monthly_deaths + accumulator;
      } else {
        return accumulator;
      }
    }, 0);
    return { x: row.x, y: accumulated_value, age: row.age };
  });
  deathCurveData.unshift({ x: 0, y: 0, age: Number(initialAge) });

  const cutOffPoint = survivalCurvesData.findIndex(elem => elem.y < 0.001);
  if (cutOffPoint > 1) {
    qxCurvesData = qxCurvesData.slice(0, cutOffPoint);
    survivalCurvesData = survivalCurvesData.slice(0, cutOffPoint);
    deathCurveData = deathCurveData.slice(0, cutOffPoint);
  }

  return (
    <Card>
      <Card.Body style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <h2>qx Curve</h2>
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={qxCurvesData}>
              <Line type="monotone" dataKey="y" stroke="#8884d8" dot={false} />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="x" />
              <XAxis dataKey="age" xAxisId="age" />
              <YAxis />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2>Survival Curve</h2>
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart width={1200} height={400} data={survivalCurvesData}>
              <Line type="monotone" dataKey="y" stroke="#8884d8" dot={false} />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="x" />
              <XAxis dataKey="age" xAxisId="age" />
              <YAxis />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h2>Death Curve</h2>
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart width={1200} height={400} data={deathCurveData}>
              <Line type="monotone" dataKey="y" stroke="#8884d8" dot={false} />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="x" />
              <XAxis dataKey="age" xAxisId="age" />
              <YAxis />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => {
  return {
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {})(MortalityAssessmentCurveGraphs);
