import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";

import {
  activeCaseSelector,
  numberOfInsuredSelector,
  numberOfBeneficiariesSelector,
  numberOfPendingCaseFilesSelector,
  auctionAccessEnabledSelector,
  numberOfOwnersSelector,
  numberOfTasksSelector,
} from "reducers";
import { setShouldSave, pricingSetup, fetchLifeSettlementCase } from "actions";
import SendCasePDFButton from "./SendCasePDFButton";
import { Tab, TabGroup } from "../../../components/Tabs";
import { PERMISSIONS, URLS } from "../../../constants";
import { isAuthorized } from "../../../utils";
import { useNavigate } from "react-router-dom";

const SubNavbar = ({
  caseInfo,
  insuredInfo,
  lifeExpectancyInfo,
  isActive,
  case_title,
  pricingSetup,
  auctionNotesFormSubmitRef,
  caseOverviewFormSubmitRef,
  caseTradingSummaryFormSubmitRef,
  closingDetailFormSubmitRef,
  hasAdminPermission,
  ...props
}) => {
  const history = useNavigate();

  if (!caseInfo) {
    return null;
  }

  const SaveButton = saveFn => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-save-button"
          onClick={async () => {
            await props.setShouldSave();
          }}
          style={{
            width: "100%",
            float: "right",
          }}
        >
          Save Changes
        </button>
      </div>
    );
  };

  const BackToCasesButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button className="btn btn--secondary navbar-save-button" onClick={() => history(URLS.HOME)}>
          <FaArrowLeft /> Back to Cases
        </button>
      </div>
    );
  };

  const h3style = {
    width: "100%",
    top: "7.2rem",
    left: 0,
    zIndex: 1,
  };

  const onClickCallback = () => {
    // Re-fetch the case on every click on the Tabs
    props.fetchLifeSettlementCase(caseInfo.id);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            backgroundColor: "black",
          }}
        >
          <h5
            className="section-title navbar-title"
            style={{
              color: "white",
              alignItems: "center",
              marginBottom: 0,
              lineHeight: 2,
            }}
          >
            Case: {caseInfo.case_title}
          </h5>
          <div style={{ display: "flex" }}>
            <BackToCasesButton />
            {hasAdminPermission && <SendCasePDFButton />}
            {hasAdminPermission && <SaveButton saveFn={props.saveFn} />}
          </div>
        </Card.Header>
      </Card>
      <h3 className={"header tertiary"} style={h3style}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          <Tab link={`${props.managementPath}/policy/`} onClickCallback={onClickCallback}>
            Policy
          </Tab>
          <Tab link={`${props.managementPath}/insured/`} onClickCallback={onClickCallback}>
            Insured ({props.insuredCount})
          </Tab>
          <Tab link={`${props.managementPath}/owners/`} onClickCallback={onClickCallback}>
            Owners ({props.ownersCount})
          </Tab>
          <Tab link={`${props.managementPath}/beneficiaries/`} onClickCallback={onClickCallback}>
            Beneficiaries ({props.beneficiariesCount})
          </Tab>
          {hasAdminPermission && props.auctionAccessEnabled ? (
            <Tab link={`${props.managementPath}/trading/`} onClickCallback={onClickCallback}>
              Trading
            </Tab>
          ) : null}
          {hasAdminPermission && (
            <Tab link={`${props.managementPath}/closing/`} onClickCallback={onClickCallback}>
              Closing
            </Tab>
          )}
          <Tab link={`${props.managementPath}/files/`} onClickCallback={onClickCallback}>
            Files ({props.pendingCaseFilesCount})
          </Tab>
          <Tab link={`${props.managementPath}/tasks/`} onClickCallback={onClickCallback}>
            Tasks ({props.pendingTasksFilesCount})
          </Tab>
          {hasAdminPermission && (
            <Tab link={`${props.managementPath}/activity/`} onClickCallback={onClickCallback}>
              Activity Log
            </Tab>
          )}
        </TabGroup>
      </h3>
    </div>
  );
};

const mapStateToProps = state => {
  const roles = state.auth.activeAgencyRoles;
  return {
    caseInfo: activeCaseSelector(state),
    insuredCount: numberOfInsuredSelector(state) || 0,
    ownersCount: numberOfOwnersSelector(state) || 0,
    beneficiariesCount: numberOfBeneficiariesSelector(state) || 0,
    pendingCaseFilesCount: numberOfPendingCaseFilesSelector(state) || 0,
    pendingTasksFilesCount: numberOfTasksSelector(state) || 0,
    auctionAccessEnabled: auctionAccessEnabledSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  pricingSetup,
  setShouldSave,
  fetchLifeSettlementCase,
})(SubNavbar);
