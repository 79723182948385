import { toast } from "react-toastify";

export const sharedConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  theme: "colored",
};

export const successProgressConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  theme: "colored",
  type: "success",
};

// instance toast const toastId = React.useRef(null);
// update progressbar toast.update(toastId.current, { progress });
// close toast.done(toastId.current);

export const success = message => {
  return toast.success(message, sharedConfig);
};

export const error = message => {
  toast.error(message, sharedConfig);
};

export const warn = message => {
  toast.warn(message, sharedConfig);
};

export const remove = id => {
  return toast.done(id);
};

export const clearAll = () => {
  toast.dismiss();
};
