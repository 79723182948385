import React from "react";
import { connect } from "react-redux";

import { Card } from "react-bootstrap";
import { Tab, TabGroup } from "../../components/Tabs";

const LifeExpectancyTabs = ({ ...props }) => {
  const path = `/life-expectancies`;

  const h3style = {
    width: "100%",
    top: "7.2rem",
    left: 0,
    zIndex: 1,
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }} />
      <h3 className={"header tertiary"} style={h3style}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          <Tab link={`${path}/`}>Search Engine</Tab>
          <Tab link={`${path}-predictor/`}>Predictor</Tab>
        </TabGroup>
      </h3>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(mapStateToProps, {})(LifeExpectancyTabs);
