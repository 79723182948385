import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import * as notifications from "../../../notifications";
import { Select, FormCollapsible, SubmitButton, TextInput } from "components/forms";
import {
  copyCase,
  copyInsured,
  createEmptyCase,
  dataTape,
  baseXML,
  cgFile,
  pricingAndDataTape,
  pricingSetup,
} from "actions";

const PricingDocumentForm = ({ id, ...props }) => {
  let [generateMode, setGenerateMode] = React.useState();

  const initial_generate_mode = "";

  const handleModeChange = selectedOptions => {
    if (selectedOptions) {
      setGenerateMode(selectedOptions.map(o => o.value).join(";"));
    } else {
      setGenerateMode("");
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        show_error: "Data tape already exists; data may be lost.",
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        values.action = generateMode;
        delete values.show_errors;
        if (values.action) {
          try {
            if (values.action === "pricing") {
              // here goes the pricing endpoint
              await props.pricingSetup(id);
            } else if (values.action === "liferoc data tape") {
              await props.dataTape(id);
            } else if (values.action === "cg file") {
              await props.cgFile(id);
            } else if (values.action === "ress xml") {
              await props.baseXML(id, "ress-xml");
            } else if (values.action === "sl xml") {
              await props.baseXML(id, "sl-xml");
            } else if (values.action === "broadriver xml") {
              await props.baseXML(id, "broadriver-xml");
            } else if (values.action === "pricing;liferoc data tape" || values.action === "liferoc data tape;pricing") {
              await props.pricingAndDataTape(id);
            }
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Error generating document");
          }
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Generate Pricing documents">
            <Form.Row>
              <Select
                label="Select document"
                name="action"
                placeholder="Select document"
                options={[
                  { value: "pricing", label: "Pricing" },
                  { value: "liferoc data tape", label: "LifeRoc Data Tape" },
                  { value: "cg file", label: "CG Data Import" },
                  { value: "ress xml", label: "Ress XML" },
                  { value: "sl xml", label: "SL XML" },
                  { value: "broadriver xml", label: "BroadRiver XML" },
                ]}
                isMulti={true}
                onChange={handleModeChange}
                defaultValue={initial_generate_mode}
              />
            </Form.Row>
            {generateMode?.includes("liferoc data tape") && (
              <Form.Row>
                <TextInput label="Warning" name="show_error" disabled />
              </Form.Row>
            )}
            <SubmitButton defaultText={"Generate"} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

export default connect(null, {
  copyCase,
  copyInsured,
  createEmptyCase,
  pricingSetup,
  dataTape,
  cgFile,
  baseXML,
  pricingAndDataTape,
})(PricingDocumentForm);
