import * as types from "../types";
import advisorhq from "../../apis/advisorhq";
import * as notifications from "../../notifications";
import { activeCarrierSelector, activeCarrierFileSelector } from "../../reducers";
import { redirectTo } from "../../utils";

export const selectCarrier = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_CARRIER, payload: id });
  const response = await advisorhq.get("/life_settlement/api/carrier/" + id + "/");
  dispatch({ type: types.FETCH_CARRIER, payload: response.data });
};

export const clearSelectedCarrier = () => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_CARRIER, payload: null });
};

export const fetchCarriers = filters => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { carriers: true } });
  const response = await advisorhq.get("/life_settlement/api/carrier/", {
    params: {
      ...filters,
    },
  });
  dispatch({ type: types.FETCH_CARRIERS, payload: response.data });
  dispatch({ type: types.LOADING, payload: { carriers: false } });
};

export const createCarrier = data => async (dispatch, getState) => {
  const endpoint = `/life_settlement/api/carrier/`;
  const response = await advisorhq.post(endpoint, data);
  dispatch({ type: types.CREATE_CARRIER, payload: response.data });
  notifications.success("A new Carrier was created");
  return response.data;
};

export const editCarrier = (carrierId, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  dispatch({ type: types.LOADING, payload: { carriers: true } });
  const endpoint = `/life_settlement/api/carrier/${carrierId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: types.EDIT_CARRIER, payload: response.data });
  dispatch({ type: types.LOADING, payload: { carriers: false } });
};

export const createEmptyCarrier = () => async (dispatch, getState) => {
  const url = "/life_settlement/api/carrier/";
  try {
    const response = await advisorhq.post(url, {});
    dispatch({ type: types.CREATE_EMPTY_CARRIER, payload: response.data });
    notifications.success("A new Carrier was created");
    window.scrollTo(0, 0);
    redirectTo(`/carriers/${response.data.id}/overview/`);
  } catch (e) {
    notifications.error("Error creating Carrier");
  }
};

export const deleteCarrier = id => async (dispatch, getState) => {
  await advisorhq.delete(`/life_settlement/api/carrier/${id}/`);
  dispatch({ type: types.DELETE_CARRIER, payload: id });
};

export const uploadCarrierFile = file => async (dispatch, getState) => {
  notifications.success("Uploading Carrier File...", file);
  const state = getState();
  const activeCarrierFile = activeCarrierFileSelector(state);
  const formData = new FormData();
  formData.append("id", activeCarrierFile.id);
  formData.append("file", file, file.name);

  try {
    const response = await advisorhq.post(`/life_settlement/api/upload_carrier_file/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    dispatch({ type: types.EDIT_CARRIER_FILE, payload: response.data });

    const activeCarrier = activeCarrierSelector(state);
    let carrierId = activeCarrier && activeCarrier.id;

    // update carrier files
    const getCarrierResponse = await advisorhq.get("/life_settlement/api/carrier/" + carrierId + "/");
    dispatch({ type: types.FETCH_CARRIER, payload: getCarrierResponse.data });

    notifications.success("Carrier File updated successfully");
  } catch (error) {
    notifications.error("Error creating new Carrier File");
    console.error(error);
  }
};

export const deleteCarrierFile = id => async (dispatch, getState) => {
  console.log("deleteCarrierFile", id);
  await advisorhq.delete(`/life_settlement/api/carrier_file/${id}/`);

  // update carrier files
  const activeCarrier = activeCarrierSelector(getState());
  let carrierId = activeCarrier && activeCarrier.id;
  const carrierResponse = await advisorhq.get("/life_settlement/api/carrier/" + carrierId + "/");
  dispatch({ type: types.FETCH_CARRIER, payload: carrierResponse.data });
};

export const createCarrierFile = data => async (dispatch, getState) => {
  const endpoint = `/life_settlement/api/carrier_file/`;
  const activeCarrier = activeCarrierSelector(getState());
  data.carrier_id = activeCarrier.id;
  const response = await advisorhq.post(endpoint, data);
  dispatch({ type: types.CREATE_CARRIER_FILE, payload: response.data });
  notifications.success("A new Carrier File was created");

  // update carrier files
  let carrierId = response.data.carrier_id;
  const carrierResponse = await advisorhq.get("/life_settlement/api/carrier/" + carrierId + "/");
  dispatch({ type: types.FETCH_CARRIER, payload: carrierResponse.data });
};

export const editCarrierFile = (carrierFileId, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");

  const endpoint = `/life_settlement/api/carrier_file/${carrierFileId}/`;
  await advisorhq.patch(endpoint, newValues);

  // update carrier files
  const activeCarrier = activeCarrierSelector(getState());
  let carrierId = activeCarrier && activeCarrier.id;
  const carrierResponse = await advisorhq.get("/life_settlement/api/carrier/" + carrierId + "/");
  dispatch({ type: types.FETCH_CARRIER, payload: carrierResponse.data });
};

export const selectCarrierFile = data => ({
  type: types.SELECT_CASE_FILE,
  payload: data,
});
