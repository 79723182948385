import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { ACCOUNT_TASK_MODAL } from "components/modals";
import AccountTaskForm from "./AccountTaskForm";

const AccountTaskModal = () => {
  return (
    <FullPageModal modalType={ACCOUNT_TASK_MODAL} title="Task Detail" style={{ content: { overflow: "visible" } }}>
      <AccountTaskForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(AccountTaskModal);
