import React from "react";
import { connect, useDispatch } from "react-redux";
import {
  activeAgencySelector,
  leadsSelector,
  loadingLeadsSelector,
  loginInfoSelector,
  managementChoicesSelector,
  selectedLeadsSelector,
} from "reducers";
import Table, { choiceCell, datetimeCell, leftAlignedCurrencyCell } from "components/table";
import IconLink from "components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { isAuthorized } from "../../utils";
import { leadStatusChoices, PERMISSIONS } from "../../constants";
import { DELETE_LEAD_MODAL } from "../../components/modals";
import { clearSelectedLead, showModal } from "../../actions";
import { LEAD_ROLES_CHOICES, LEAD_SOURCES_CHOICES } from "./constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as types from "../../actions/types";

const LeadsTable = ({ accounts, leadsLoading, activeAgency, user_id, ...props }) => {
  const dispatch = useDispatch();
  const tooltipSelectText = "Select to send Email";
  const tooltipDisabledText = "No Email";

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const leadId = row.original.id;
        const isDisabled = !row.original.email;

        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={<Tooltip>{isDisabled ? tooltipDisabledText : tooltipSelectText}</Tooltip>}
            >
              <input
                type="checkbox"
                style={{
                  "margin-right": "5px",
                  width: "16px",
                  cursor: isDisabled ? "not-allowed" : "pointer",
                }}
                checked={props.selectedLeads.indexOf(row.original.id) !== -1}
                disabled={isDisabled}
                onClick={e => {
                  if (e.target.checked) {
                    dispatch({
                      type: types.SELECT_LEAD_LIST,
                      payload: { id: row.original.id },
                    });
                  } else {
                    dispatch({
                      type: types.DESELECT_LEAD_LIST,
                      payload: { id: row.original.id },
                    });
                  }
                }}
              />
            </OverlayTrigger>
            <IconLink
              to={`/leads/${leadId}/overview/`}
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View / Edit"
              onClick={() => {
                props.clearSelectedLead();
                window.scrollTo(0, 0);
              }}
            />
            {props.hasAdminPermission && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon red" }}
                tooltip="Delete Lead"
                onClick={() => props.showModal(DELETE_LEAD_MODAL, { id: leadId })}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Lead ID",
      accessor: "system_id",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: choiceCell(leadStatusChoices),
    },
    {
      Header: "Source",
      accessor: "source",
      Cell: choiceCell(LEAD_SOURCES_CHOICES),
    },
    {
      Header: "Sales Representative",
      accessor: "sales_representative_id",
      Cell: choiceCell(props.salesRepChoices),
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },
    {
      Header: "First Name",
      accessor: "first_name",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "E-mail",
      accessor: "email",
    },
    {
      Header: "State",
      accessor: "state",
    },
    {
      Header: "Lead Role",
      accessor: "role",
      Cell: choiceCell(LEAD_ROLES_CHOICES),
    },
    {
      Header: "Face Amount",
      accessor: "face_amount",
      Cell: leftAlignedCurrencyCell,
    },
    {
      Header: "Lead Score",
      accessor: "score",
    },
    {
      Header: "Lead Create Date",
      accessor: "date_created",
      Cell: datetimeCell,
    },
    {
      Header: "Last Activity",
      accessor: "last_activity",
      Cell: datetimeCell,
    },
  ];

  return (
    <Table columns={columns} data={props.leads} emptyMessage={leadsLoading ? "Loading Leads data..." : "No data"} />
  );
};

const mapStateToProps = (state, ownProps) => {
  const choices = managementChoicesSelector(state);
  const roles = state.auth.activeAgencyRoles;
  const { user_id } = loginInfoSelector(state);
  const leads = Object.values(leadsSelector(state)) || [];

  return {
    salesRepChoices: choices.case_sales_representatives,
    activeAgency: activeAgencySelector(state),
    leadsLoading: loadingLeadsSelector(state),
    selectedLeads: selectedLeadsSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
    user_id,
    leads,
  };
};

export default connect(mapStateToProps, {
  clearSelectedLead,
  showModal,
})(LeadsTable);
