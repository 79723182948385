import * as types from "../types";
import advisorhq from "../../apis/advisorhq";
import * as notifications from "../../notifications";
import { activeCaseIdSelector, activeClinicalResearchSelector } from "../../reducers";
import { hideModal, showModal } from "../modals";
import {
  PARAMETER_MODAL,
  LOADING_MODAL,
  SYNONYM_MODAL,
  RELATED_PARAMETERS_MODAL,
  DELETE_PARAMETER_MODAL,
  successProgressConfig_PARAMETER_MODAL,
  REMOVE_RELATED_PARAMETER_MODAL,
} from "../../components/modals";
import { DELETE_CASE_FILE, FETCH_LIFE_EXPECTANCIES } from "../types";
import { redirectTo } from "../../utils";

export const selectParameter = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_PARAMETER, payload: id });
  const response = await advisorhq.get("/api/parameter/" + id + "/");
  dispatch({ type: types.FETCH_PARAMETER, payload: response.data });
};

export const clearSelectedParameter = () => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_PARAMETER, payload: null });
};

export const fetchParameters = filters => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { parameters: true } });
  const response = await advisorhq.get("/api/parameter/", {
    params: {
      ...filters,
    },
  });
  let { results, ...paginateData } = response.data;
  paginateData = { ...paginateData, page: 1 };
  dispatch({ type: types.SET_PARAMETERS_PAGINATION_DATA, payload: paginateData });
  dispatch({ type: types.FETCH_PARAMETERS, payload: results });
  dispatch({ type: types.LOADING, payload: { parameters: false } });
  return response.data;
};

export const createParameter = data => async (dispatch, getState) => {
  const endpoint = `/api/parameter/`;
  const response = await advisorhq.post(endpoint, data);
  dispatch({ type: types.CREATE_PARAMETER, payload: response.data });
  dispatch(hideModal(PARAMETER_MODAL));
  window.scrollTo(0, 0);
  redirectTo(`/riskx-parameters/${response.data.id}/background/`);
  notifications.success("A new Parameter was created");
};

export const copyParameter = id => async (dispatch, getState) => {
  const endpoint = `/api/parameter/${id}/copy/`;
  const response = await advisorhq.post(endpoint);
  dispatch({ type: types.CREATE_PARAMETER, payload: response.data });
  window.scrollTo(0, 0);
  redirectTo(`/riskx-parameters/${response.data.id}/background/`);
  notifications.success("A new Parameter was created");
};

export const editParameter = (parameterId, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  const endpoint = `/api/parameter/${parameterId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  notifications.clearAll();
  dispatch({ type: types.EDIT_PARAMETER, payload: response.data });
};

export const deleteParameter = id => async (dispatch, getState) => {
  await advisorhq.delete(`/api/parameter/${id}/`);
  dispatch({ type: types.DELETE_PARAMETER, payload: id });
};

export const storeParametersFiltersData = filters => async (dispatch, getState) => {
  dispatch({ type: types.STORE_PARAMETERS_FILTERS_DATA, payload: filters });
};

// Clinical Research

export const selectClinicalResearch = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_CLINICAL_RESEARCH, payload: id });
  // const response = await advisorhq.get("/api/clinical_research/" + id + "/");
  // dispatch({ type: types.FETCH_CLINICAL_RESEARCH, payload: response.data });
};

export const fetchClinicalResearch = filters => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { clinical_research: true } });
  const response = await advisorhq.get("/api/clinical_research/", {
    params: {
      ...filters,
    },
  });
  let { results, ...paginateData } = response.data;
  paginateData = { ...paginateData, page: 1 };
  dispatch({ type: types.SET_CLINICAL_RESEARCH_PAGINATION_DATA, payload: paginateData });
  dispatch({ type: types.FETCH_CLINICAL_RESEARCH_LIST, payload: results });
  dispatch({ type: types.LOADING, payload: { clinical_research: false } });
};

export const deleteClinicalResearch = id => async (dispatch, getState) => {
  await advisorhq.delete(`/api/clinical_research/${id}/`);
  dispatch({ type: types.DELETE_CLINICAL_RESEARCH, payload: id });
};

export const createClinicalResearch = data => async (dispatch, getState) => {
  const endpoint = `/api/clinical_research/`;
  const response = await advisorhq.post(endpoint, data);
  dispatch({ type: types.CREATE_CLINICAL_RESEARCH, payload: response.data });
};

export const editClinicalResearch = (clinicalResearchId, newValues, row) => async (dispatch, getState) => {
  notifications.clearAll();
  notifications.success("Saving...");
  delete newValues.file; // File is saved through a different endpoint
  const endpoint = `/api/clinical_research/${clinicalResearchId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: types.EDIT_CLINICAL_RESEARCH, payload: response.data });
  notifications.clearAll();
};

export const uploadClinicalResearchFile = file => async (dispatch, getState) => {
  const activeClinicalResearch = activeClinicalResearchSelector(getState());
  const activeClinicalResearchId = activeClinicalResearch.id;
  const formData = new FormData();
  formData.append("file", file, file.name);

  let message = "Uploading file...";
  dispatch({ type: types.LOADING, payload: { clinical_research: true } });
  dispatch(showModal(LOADING_MODAL, { message: message }));
  try {
    const response = await advisorhq.post(`/api/clinical_research/${activeClinicalResearchId}/upload_file/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    dispatch(hideModal(LOADING_MODAL));
    dispatch({ type: types.EDIT_CLINICAL_RESEARCH, payload: response.data });
    dispatch({ type: types.LOADING, payload: { clinical_research: false } });
    notifications.success("Clinical Research File uploaded successfully");
  } catch (error) {
    notifications.error(`Problem uploading file: ${error.response.data}`);
    dispatch(hideModal(LOADING_MODAL));
    dispatch({ type: types.LOADING, payload: { clinical_research: false } });
  }
};

export const deleteClinicalResearchFile = id => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { caseFiles: true } });
  const url = `/life_settlement/api/case_file/${id}/`;
  await advisorhq.delete(url);
  dispatch({ type: DELETE_CASE_FILE, payload: id });
  dispatch({ type: types.LOADING, payload: { caseFiles: false } });
  notifications.success("Case File deleted successfully");

  // re-fetch
  const activeCaseId = activeCaseIdSelector(getState());
  const response = await advisorhq.get("/underwriting/api/life_expectancies/", {
    params: { case: activeCaseId },
  });
  dispatch({ type: FETCH_LIFE_EXPECTANCIES, payload: response.data });
};

// Synonyms

export const selectSynonym = id => async (dispatch, getState) => {
  if (!id) {
    dispatch({ type: types.SELECT_SYNONYM, payload: null });
  } else {
    dispatch({ type: types.SELECT_SYNONYM, payload: id });
    const response = await advisorhq.get("/api/synonym/" + id + "/");
    dispatch({ type: types.FETCH_SYNONYM, payload: response.data });
  }
};

export const fetchSynonymsByParameter = (parameterId, setSynonyms) => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { synonyms: true } });
  const response = await advisorhq.get("/api/synonym/", {
    params: {
      parameter_id: parameterId,
    },
  });
  let { results, ...paginateData } = response.data;
  dispatch({ type: types.FETCH_PARAMETER_SYNONYMS, payload: results });
  dispatch({ type: types.LOADING, payload: { synonyms: false } });
};

export const clearSelectedSynonym = () => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_SYNONYM, payload: null });
};

export const fetchSynonyms = filters => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { synonyms: true } });
  const response = await advisorhq.get("/api/synonym/", {
    params: {
      ...filters,
    },
  });
  let { results, ...paginateData } = response.data;
  paginateData = { ...paginateData, page: 1 };
  dispatch({ type: types.SET_SYNONYMS_PAGINATION_DATA, payload: paginateData });
  dispatch({ type: types.FETCH_SYNONYMS, payload: results });
  dispatch({ type: types.LOADING, payload: { synonyms: false } });
  return response.data;
};

export const createSynonym = data => async (dispatch, getState) => {
  const endpoint = `/api/synonym/`;
  const response = await advisorhq.post(endpoint, data);
  if (response.errors) {
    notifications.error("error");
  }
  dispatch({ type: types.CREATE_SYNONYM, payload: response.data });
  dispatch(hideModal(SYNONYM_MODAL));
  notifications.success("A new Synonym was created");
};

export const createParameterSynonym = data => async (dispatch, getState) => {
  const endpoint = `/api/synonym/`;
  const response = await advisorhq.post(endpoint, data);
  if (response.errors) {
    notifications.error("error");
  }
  dispatch({ type: types.CREATE_PARAMETER_SYNONYM, payload: response.data });
  dispatch(hideModal(SYNONYM_MODAL));
  notifications.success("A new Synonym was created");
};

export const editSynonym = (synonymId, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  const endpoint = `/api/synonym/${synonymId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  notifications.clearAll();
  dispatch({ type: types.EDIT_SYNONYM, payload: response.data });
  notifications.success("Saved");
};

export const editParameterSynonym = (synonymId, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  const endpoint = `/api/synonym/${synonymId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  notifications.clearAll();
  dispatch({ type: types.EDIT_PARAMETER_SYNONYM, payload: response.data });
  notifications.success("Saved");
};

export const deleteSynonym = id => async (dispatch, getState) => {
  await advisorhq.delete(`/api/synonym/${id}/`);
  dispatch({ type: types.DELETE_SYNONYM, payload: id });
};

export const deleteParameterSynonym = id => async (dispatch, getState) => {
  await advisorhq.delete(`/api/synonym/${id}/`);
  dispatch({ type: types.DELETE_PARAMETER_SYNONYM, payload: id });
};

export const storeSynonymsFiltersData = filters => async (dispatch, getState) => {
  dispatch({ type: types.STORE_SYNONYMS_FILTERS_DATA, payload: filters });
};

export const fetchParametersChoices = setParameterChoices => async () => {
  const response = await advisorhq.get("/api/parameter_choices/");
  setParameterChoices(response.data);
};

export const removeRelatedParameter = (activeParameterId, relatedParameterId) => async (dispatch, getState) => {
  const endpoint = `/api/parameter/${activeParameterId}/removerelation/`;
  const response = await advisorhq.post(endpoint, {
    related_parameter: relatedParameterId,
  });
  if (response.errors) {
    notifications.error("error");
  }
  dispatch({ type: types.FETCH_PARAMETER, payload: response.data });
  dispatch(hideModal(REMOVE_RELATED_PARAMETER_MODAL));
  notifications.success("Related Parameter removed");
};

export const createRelatedParameter = (activeParameterId, relatedParameterId) => async (dispatch, getState) => {
  const endpoint = `/api/parameter/${activeParameterId}/addrelation/`;
  const response = await advisorhq.post(endpoint, {
    related_parameter: relatedParameterId,
  });
  if (response.errors) {
    notifications.error("error");
  }
  dispatch({ type: types.FETCH_PARAMETER, payload: response.data });
  dispatch(hideModal(RELATED_PARAMETERS_MODAL));
  notifications.success("Related Parameter added");
};
