import {
  SELECT_CARRIER,
  FETCH_CARRIERS,
  EDIT_CARRIER,
  CREATE_CARRIER,
  DELETE_CARRIER,
  FETCH_CARRIER,
  CREATE_EMPTY_FUNDER,
  SELECT_CARRIER_FILE,
  EDIT_CARRIER_FILE,
  CREATE_CARRIER_FILE,
} from "actions/types";
import { arrayToObjectIndexedById } from "reducers/utils";
import { removeKey } from "../../../utils";
import * as types from "../../../actions/types";

export const defaultState = {
  carriers: {},
  activeCarrierId: null,
  activeCarrierFile: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_CARRIER:
      return { ...state, activeCarrierId: action.payload };

    case CREATE_EMPTY_FUNDER:
    case FETCH_CARRIER: {
      return {
        ...state,
        carriers: {
          ...state.carriers,
          [action.payload.id]: action.payload,
        },
      };
    }

    case FETCH_CARRIERS:
      return { ...state, carriers: arrayToObjectIndexedById(action.payload) };

    case EDIT_CARRIER:
    case CREATE_CARRIER: {
      return {
        ...state,
        carriers: {
          ...state.carriers,
          [action.payload.id]: action.payload,
        },
        activeCarrierId: action.payload.id,
      };
    }

    case DELETE_CARRIER: {
      return {
        ...state,
        carriers: removeKey(state.carriers, action.payload),
      };
    }

    case SELECT_CARRIER_FILE: {
      return {
        ...state,
        activeCarrierFile: action.payload,
      };
    }

    case EDIT_CARRIER_FILE:
    case CREATE_CARRIER_FILE: {
      console.log(action.type, action.payload);
      let newState = state;
      newState.activeCarrierFile = {
        ...state.activeCarrierFile,
        ...action.payload,
      };
      return newState;
    }

    default:
      return state;
  }
};

export const activeCarrierSelector = ({ carriers, activeCarrierId }) => {
  if (activeCarrierId) {
    return carriers[activeCarrierId];
  }
};

export const carriersSelector = state => {
  return Object.values(state.carriers);
};

/** Carrier Files */

export const selectCarrierFile = data => ({
  type: types.SELECT_CARRIER_FILE,
  payload: data,
});

export const activeCarrierFileSelector = state => {
  console.log("activeCarrierFileSelector", state);
  return state.activeCarrierFile;
};
