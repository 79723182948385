import React from "react";
import { connect, useDispatch } from "react-redux";

import { ButtonCreate, ButtonExcelDownload, ButtonSendTo } from "components/buttons";
import { RecordsTable, RecordsTableTabs } from "components/table";
import {
  AUCTION_ROUND_MODAL,
  AUCTION_FUNDER_ACTIVITY_MODAL,
  AUCTION_MARKET_ACTIVITY_MODAL,
  SEND_FUNDER_EMAIL_MODAL,
} from "components/modals";
import {
  selectAuctionRound,
  selectAuctionActivity,
  fetchAuction,
  showModal,
  downloadFile,
  setShouldSave,
  unsetShouldSave,
  fetchLightFunders,
} from "actions";
import FunderActivitiesTable from "./activities/FunderActivitiesTable";
import MarketActivitiesTable from "./activities/MarketActivitiesTable";
import RoundsTable from "./rounds/RoundsTable";
import FundersAuctionTable from "./FundersAuctionTable";
import {
  activeCaseSelector,
  auctionAccessEnabledSelector,
  auctionIdSelector,
  loadingSelector,
  selectedFundersSelector,
  shouldSaveSelector,
  funderSearchStringSelector,
} from "reducers";
import * as types from "actions/types";
import Loader from "components/Loader";
import CaseTradingSummary from "./CaseTradingSummary";
import fetchData from "../CaseSetup";
import { SET_EMAIL_TEMPLATE } from "actions/types";

const AuctionPage = ({ funderSearchString, ...props }) => {
  const dispatch = useDispatch();

  const caseTradingSummaryFormSubmitRef = React.useRef();
  const auctionNotesFormSubmitRef = React.useRef();

  React.useEffect(() => {
    const caseId = props.caseInfo.id;
    if (props.auctionAccessEnabled) {
      props.fetchAuction(caseId);
      props.fetchLightFunders();
    }
  }, [props.caseInfo.id]);

  React.useEffect(() => {
    if (props.caseInfo.submission_id) {
      document.title = `LifeRoc | ${props.caseInfo.submission_id} | Trading`;
    }
  }, [props.caseInfo.id]);

  React.useEffect(() => {
    if (props.shouldSave) {
      setTimeout(() => {
        caseTradingSummaryFormSubmitRef.current.click();
      }, 500);
      setTimeout(() => {
        auctionNotesFormSubmitRef.current.click();
      }, 1000);
      setTimeout(() => {
        unsetShouldSave();
      }, 1500);
    }
  }, [props.shouldSave]);

  if (props.loading.error404) {
    return <></>;
  }

  if (!props.auctionAccessEnabled) {
    return null;
  }
  let roundstable =
    props.caseInfo && (props.caseInfo.bidding_format === "LPEX" || props.caseInfo.bidding_format === "BROKER AUCTION");
  return (
    <>
      <CaseTradingSummary
        caseTradingSummaryFormSubmitRef={caseTradingSummaryFormSubmitRef}
        auctionNotesFormSubmitRef={auctionNotesFormSubmitRef}
      />
      <Loader isLoading={props.loading.auction} text={"Loading Auction data"} />
      {roundstable ? (
        <RecordsTable
          title="Round Detail"
          createButtonLabel="Add Round"
          onCreateClick={() => {
            props.selectAuctionRound(null);
            props.showModal(AUCTION_ROUND_MODAL);
          }}
          Table={RoundsTable}
        />
      ) : (
        ""
      )}
      <RecordsTable
        title="Market Activity"
        onCreateClick={() => {
          props.selectAuctionRound(null);
          props.selectAuctionActivity(null);
          props.showModal(AUCTION_MARKET_ACTIVITY_MODAL);
        }}
        Table={MarketActivitiesTable}
        actionButtons={[
          <ButtonExcelDownload
            onClick={() =>
              props.downloadFile(
                `/auctions/api/market_activities/${props.auctionId}/download_excel/`,
                "market_activities_report.csv",
              )
            }
            disabled={props.auctionId === undefined}
          >
            Download Summary
          </ButtonExcelDownload>,
          <ButtonCreate
            onClick={() => {
              props.selectAuctionActivity(null);
              props.showModal(AUCTION_MARKET_ACTIVITY_MODAL);
            }}
          >
            Add Market Activity
          </ButtonCreate>,
        ]}
      />
      <RecordsTableTabs
        onCreateClick={() => {}}
        Table1={FunderActivitiesTable}
        Table2={FundersAuctionTable}
        table2props={funderSearchString}
        actionButtons1={[
          <input
            type="text"
            defaultValue={funderSearchString}
            placeholder="Search Funder"
            style={{ borderRadius: 2, padding: "0 4px" }}
            onChange={e => dispatch({ type: types.FUNDER_SEARCH_STRING, payload: e.target.value })}
          />,
          <ButtonCreate
            onClick={() => {
              props.selectAuctionActivity(null);
              props.showModal(AUCTION_FUNDER_ACTIVITY_MODAL);
            }}
            style={{ marginLeft: "5px" }}
          >
            Add Funder Activity
          </ButtonCreate>,
        ]}
        actionButtons2={[
          <input
            type="text"
            defaultValue={funderSearchString}
            placeholder="Search Funder"
            style={{ borderRadius: 2, padding: "0 4px" }}
            onChange={e => dispatch({ type: types.FUNDER_SEARCH_STRING, payload: e.target.value })}
          />,
          <ButtonSendTo
            onClick={() => {
              dispatch({
                type: SET_EMAIL_TEMPLATE,
                payload: "Funder New Case Notification",
              });
              props.showModal(SEND_FUNDER_EMAIL_MODAL);
            }}
            key="create"
            style={{ marginLeft: "5px" }}
            disabled={props.selectedFunders.length === 0}
          >
            {props.selectedFunders.length === 0
              ? "Select Funders to Send"
              : `Send to Funders (${props.selectedFunders.length})`}
          </ButtonSendTo>,
        ]}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    loading: loadingSelector(state),
    auctionAccessEnabled: auctionAccessEnabledSelector(state),
    selectedFunders: selectedFundersSelector(state),
    auctionId: auctionIdSelector(state),
    shouldSave: shouldSaveSelector(state),
    funderSearchString: funderSearchStringSelector(state),
  };
};

export default connect(mapStateToProps, {
  selectAuctionRound,
  selectAuctionActivity,
  showModal,
  downloadFile,
  fetchAuction,
  fetchLightFunders,
  fetchData,
  setShouldSave,
  unsetShouldSave,
})(AuctionPage);
