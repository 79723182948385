import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { BooleanSelect, DateInput, FormCollapsible, Select, SubmitButton, TextInput } from "components/forms";
import { createTradingDocument, editTradingDocument, fetchFunderEntities, hideModal } from "actions";
import { Form } from "react-bootstrap";
import {
  activeFunderEntityIdSelector,
  activeFunderEntitySelector,
  activeFunderSelector,
  activeTradingDocumentIdSelector,
} from "../../reducers";
import { partial } from "../../utils";
import * as notifications from "../../notifications";
import { makeChoices } from "./utils";

const TradingDocumentForm = ({ ...props }) => {
  let initialValues = props.tradingDocumentInfo || {};

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        let tradingDocumentId = props.tradingDocumentInfo && props.tradingDocumentInfo.id;
        const method = tradingDocumentId
          ? partial(props.editTradingDocument, tradingDocumentId)
          : props.createTradingDocument;

        values.funder_entity_id = props.activeFunderEntityId;
        delete values.file; // File is saved through a different endpoint
        try {
          await method(values);
          await props.fetchFunderEntities(props.activeFunderId);
          notifications.success("Trading Document saved");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error saving Trading Document");
        }
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <FormCollapsible
            onSubmit={handleSubmit}
            title={props.activeTradingDocumentId ? "Edit Trading Document" : "New Trading Document"}
          >
            <Form.Row>
              <Select label="Document Type" name="document_type" options={props.documentTypeChoices} />
              <TextInput label="Description" name="description" />
              <DateInput label="Effective Date" name="effective_date" />
            </Form.Row>
            <Form.Row>
              <BooleanSelect label="In Use?" name="in_use" md={4} />
              <BooleanSelect label="LE Reps & Warranties" name="le_reps_and_warranties" md={4} />
            </Form.Row>
            <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const activeTradingDocumentId = activeTradingDocumentIdSelector(state);
  const activeFunderEntity = activeFunderEntitySelector(state);
  let tradingDocumentInfo;
  if (activeTradingDocumentId && activeFunderEntity) {
    for (const trading_document of activeFunderEntity.trading_documents) {
      if (trading_document.id === activeTradingDocumentId) {
        tradingDocumentInfo = trading_document;
        break;
      }
    }
  }

  const DOCUMENT_TYPES = [
    "MOA - Base Agreement",
    "MOA - Initial Purchase Order",
    "MOA - Final Purchase Order",
    "MOA - Escrow Disbursement Instructions",
    "MOA - Assignment/Bill of Sale",
    "ELSA MATT - Base Agreement",
    "PSA - Base with SI-SI",
    "PSA - Base with EA",
    "PSA - Escrow Disbursement Instructions",
    "PSA - Assignment/Bill of Sale",
    "Entitle Order - Tri-Party (CS)",
    "Entitle Order - Debit (CS)",
    "Entitle Order - Credit (CS)",
    "Entitle Order - Tri-Party (Wells)",
    "Entitle Order - Debit (Wells)",
    "Entitle Order - Credit (Wells)",
    "Entitle Order - Tri-Party (Wilmington)",
    "Entitle Order - Debit (Wilmington)",
    "Entitle Order - Credit (Wilmington)",
    "Direct Placement",
    "Tertiary",
  ];
  const documentTypeChoices = makeChoices(DOCUMENT_TYPES);
  let funderInfo = activeFunderSelector(state);
  return {
    tradingDocumentInfo,
    activeFunderId: funderInfo.id,
    activeFunderEntityId: activeFunderEntityIdSelector(state),
    activeTradingDocumentId: activeTradingDocumentId,
    documentTypeChoices,
  };
};

export default connect(mapStateToProps, {
  fetchFunderEntities,
  editTradingDocument,
  createTradingDocument,
  hideModal,
})(TradingDocumentForm);
