export const TaskTab = {
  ALL: 0,
  CASE: 4,
  CASE_REQUIREMENT: 1,
  ACCOUNT: 2,
  LEAD: 3,
};

export const TaskTypesChoices = [
  { value: 2, label: "Account" },
  { value: 4, label: "Case Task" },
  { value: 1, label: "Case Requirement" },
  { value: 3, label: "Lead" },
];
