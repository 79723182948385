import React from "react";
import { useField } from "formik";
import { Form, InputGroup, Col } from "react-bootstrap";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import FieldErrors from "./FieldErrors";
import FieldHelpText from "./FieldHelpText";
import Label from "./Label";
import { getValidationClassName } from "./validation";

/* Percentage Input field
 *
 * Internally it has 2 inputs, one visible that holds the user input
 * and one hidden that converts the entered value to a float value.
 */
export default ({ label, name, md = "", isRequired = false, helpText, ...props }) => {
  const [field, meta, { setValue }] = useField({ name });

  function percentageMask(rawValue) {
    // Define the number mask function

    let numberMask = createNumberMask({
      allowDecimal: false,
      integerLimit: 3, // up to 3 digits for the integer part
      prefix: "",
      includeThousandsSeparator: true,
      requireDecimal: false,
      allowLeadingZeroes: false,
    });

    let mask = numberMask(rawValue);

    // Add percentage symbol at the end
    mask.push(" %");

    return mask;
  }

  const handleChange = value => {
    if (value === "") {
      setValue(null);
    } else {
      let cleanedValue = value.replace(" %", "");
      cleanedValue = parseFloat(cleanedValue);
      setValue(Number(cleanedValue));
    }
  };

  const maskValue = field.value === null || field.value === undefined || isNaN(field.value) ? "" : field.value;

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <Label label={label} required={isRequired} />
      <input type="hidden" {...field} value="" />
      <InputGroup>
        <MaskedInput
          mask={percentageMask}
          value={maskValue}
          className={`form-control ${getValidationClassName(meta) || ""}`}
          autoComplete="off"
          onChange={e => handleChange(e.target.value)}
          {...props}
        />
        <FieldErrors meta={meta} />
      </InputGroup>
      {helpText && <FieldHelpText>{helpText}</FieldHelpText>}
    </Form.Group>
  );
};
