import * as types from "../types";
import { activeCaseIdSelector } from "reducers";
import advisorhq from "../../apis/advisorhq";
import { FETCH_LIFE_SETTLEMENT_CASE } from "../types";

export const selectCaseTask = data => ({
  type: types.SELECT_CASE_TASK,
  payload: data,
});

export const createCaseTask = data => async (dispatch, getState) => {
  const endpoint = `/life_settlement/api/case_task/`;
  data.case = activeCaseIdSelector(getState());
  const response = await advisorhq.post(endpoint, data);
  dispatch({ type: types.CREATE_CASE_TASK, payload: response.data });
};

export const editCaseTask = (caseTaskId, newValues, row) => async (dispatch, getState) => {
  const endpoint = `/life_settlement/api/case_task/${caseTaskId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: types.EDIT_CASE_TASK, payload: response.data });
};

export const deleteCaseTask = caseTaskId => async (dispatch, getState) => {
  const endpoint = `/life_settlement/api/case_task/${caseTaskId}/`;
  await advisorhq.delete(endpoint);

  // re-fetch
  const activeCaseId = activeCaseIdSelector(getState());
  const response2 = await advisorhq.get(`/life_settlement/api/cases/v2/${activeCaseId}/`);
  dispatch({ type: FETCH_LIFE_SETTLEMENT_CASE, payload: response2.data });
};
