import React from "react";

import Collapsible from "components/Collapsible";

export default ({ title, children, onSubmit, className = "", ...props }) => {
  return (
    <div className={className + " form shadowed"} {...props}>
      <Collapsible title={title} {...props}>
        {children}
      </Collapsible>
    </div>
  );
};
