import {
  SET_CASES_PAGINATION_DATA,
  SET_TRADING_PAGINATION_DATA,
  SET_RECORDS_PAGINATION_DATA,
  SET_LES_PAGINATION_DATA,
  SET_PAGE_SIZE,
  CLEAN_LIFE_SETTLEMENT_SEARCH_DATA,
  CLEAN_CASES_PAGINATION_DATA,
  CLEAN_RECORDS_PAGINATION_DATA,
  SET_ACCOUNTS_PAGINATION_DATA,
  SET_LEADS_PAGINATION_DATA,
  SET_PARAMETERS_PAGINATION_DATA,
} from "actions/types";

export const defaultState = {
  cases: {
    next: "",
    previous: "",
    page: 1,
  },
  les: {
    next: "",
    previous: "",
    page: 1,
  },
  records: {
    next: "",
    previous: "",
    page: 1,
    pastDueRecordsCount: 0,
    pendingRecordsCount: 0,
  },
  accounts: {
    next: "",
    previous: "",
    page: 1,
  },
  leads: {
    next: "",
    previous: "",
    page: 1,
  },
  parameters: {
    next: "",
    previous: "",
    page: 1,
  },
  synonyms: {
    next: "",
    previous: "",
    page: 1,
  },
  pageSize: 25,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAN_LIFE_SETTLEMENT_SEARCH_DATA:
      return defaultState;
    case CLEAN_CASES_PAGINATION_DATA:
      return defaultState;
    case SET_CASES_PAGINATION_DATA:
      return {
        ...state,
        cases: action.payload,
      };
    case SET_LES_PAGINATION_DATA:
      return {
        ...state,
        les: action.payload,
      };
    case SET_TRADING_PAGINATION_DATA:
      return {
        ...state,
        trading: action.payload,
      };
    case CLEAN_RECORDS_PAGINATION_DATA: {
      return {
        ...state,
        records: defaultState.records,
      };
    }
    case SET_RECORDS_PAGINATION_DATA: {
      // pastDueRecordsCount will be equal to action.payload.past_due_records
      // pendingRecordsCount will be equal to action.payload.pending_records
      // paginationData will contain the remaining properties from action.payload
      const {
        past_due_records: pastDueRecordsCount,
        pending_records: pendingRecordsCount,
        ...paginationData
      } = action.payload;
      return {
        ...state,
        records: {
          pastDueRecordsCount,
          pendingRecordsCount,
          ...paginationData,
        },
      };
    }
    case SET_ACCOUNTS_PAGINATION_DATA:
      return {
        ...state,
        accounts: action.payload,
      };
    case SET_LEADS_PAGINATION_DATA:
      return {
        ...state,
        leads: action.payload,
      };
    case SET_PARAMETERS_PAGINATION_DATA:
      return {
        ...state,
        parameters: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    default:
      return state;
  }
};

export const pageSizeSelector = state => state.pageSize;

// TRADING

export const tradingCasesCountSelector = state => (state.trading && state.trading.count) || "";

// CASES

export const nextPageCaseSelector = state => state.cases.next;

export const previousPageCaseSelector = state => state.cases.previous;

export const currentPageCaseSelector = state => state.cases.page;

export const caseCountSelector = state => state.cases.count;

export const getPagesCaseCount = state => {
  /* Calculates page count for Cases */
  const pageSize = pageSizeSelector(state);
  const count = caseCountSelector(state);
  let add_to_count = 1;
  if (count % pageSize === 0) {
    add_to_count = 0;
  }
  return parseInt(count / pageSize) + add_to_count;
};

// LIFE EXPECTANCY

export const nextPageLeSelector = state => state.les.next;

export const previousPageLeSelector = state => state.les.previous;

export const currentPageLeSelector = state => state.les.page;

export const leCountSelector = state => state.les.count;

export const getPagesLeCount = state => {
  /* Calculates page count for Les */
  const pageSize = pageSizeSelector(state);
  const count = leCountSelector(state);
  let add_to_count = 1;
  if (count % pageSize === 0) {
    add_to_count = 0;
  }
  return parseInt(count / pageSize) + add_to_count;
};

// RECORDS

export const nextPageRecordsSelector = state => state.records.next;

export const previousPageRecordsSelector = state => state.records.previous;

export const currentPageRecordsSelector = state => state.records.page;

export const recordsCountSelector = state => state.records.count;

export const pendingRecordsCountSelector = state => state.records.pendingRecordsCount;

export const pastDueRecordsCountSelector = state => state.records.pastDueRecordsCount;

export const getPagesRecordsCount = state => {
  /* Calculates page count for Cases */
  const pageSize = pageSizeSelector(state);
  const count = recordsCountSelector(state);
  let add_to_count = 1;
  if (count % pageSize === 0) {
    add_to_count = 0;
  }
  return parseInt(count / pageSize) + add_to_count;
};

// ACCOUNTS

export const nextPageAccountsSelector = state => state.accounts.next;

export const previousPageAccountsSelector = state => state.accounts.previous;

export const currentPageAccountsSelector = state => state.accounts.page;

export const accountsCountSelector = state => state.accounts.count;

export const getPagesAccountsCount = state => {
  /* Calculates page count for Accounts */
  const pageSize = pageSizeSelector(state);
  const count = accountsCountSelector(state);
  let add_to_count = 1;
  if (count % pageSize === 0) {
    add_to_count = 0;
  }
  return parseInt(count / pageSize) + add_to_count;
};

// LEADS

export const calculateCount = (state, method) => {
  /* Generic: calculate page count for an object using the given method */
  const pageSize = pageSizeSelector(state);
  const count = method(state);
  let add_to_count = 1;
  if (count % pageSize === 0) {
    add_to_count = 0;
  }
  return parseInt(count / pageSize) + add_to_count;
};

// leads
export const nextPageLeadsSelector = state => state.leads.next;
export const previousPageLeadsSelector = state => state.leads.previous;
export const currentPageLeadsSelector = state => state.leads.page;
export const leadsCountSelector = state => state.leads.count;
export const getPagesLeadsCount = state => calculateCount(state, leadsCountSelector);

// parameters
export const nextPageParametersSelector = state => state.parameters.next;
export const previousPageParametersSelector = state => state.parameters.previous;
export const currentPageParametersSelector = state => state.parameters.page;
export const parametersCountSelector = state => state.parameters.count;
export const getPagesParametersCount = state => calculateCount(state, parametersCountSelector);

// synonyms
export const nextPageSynonymsSelector = state => state.synonyms.next;
export const previousPageSynonymsSelector = state => state.synonyms.previous;
export const currentPageSynonymsSelector = state => state.synonyms.page;
export const synonymsCountSelector = state => state.synonyms.count;
export const getPagesSynonymsCount = state => calculateCount(state, synonymsCountSelector);
