import {
  dateDisplay,
  datetimeDisplay,
  dateOverdueDisplay,
  getLabel,
  currencyCellMoneyDisplay,
  timeDisplay,
} from "utils.js";
import React from "react";
import * as moment from "moment";

export const dateCell = ({ value }) => {
  return dateDisplay(value) || "";
};

export const dateCellorPresent = ({ value }) => {
  return dateDisplay(value) || "Present";
};

export const dateOverdueCell = ({ value }) => {
  let now = moment();
  let date_formatted = moment(value);
  if (now > date_formatted) {
    return dateOverdueDisplay(value) || "";
  }
  return dateDisplay(value) || "";
};

export const datetimeCell = ({ value }) => {
  return datetimeDisplay(value) || "";
};

export const sortDateCell = (a, b) => {
  return new Date(a.values.date) - new Date(b.values.date);
};

export const sortNumberCell = (a, b) => {
  return a - b;
};

export const choiceCell =
  choices =>
  ({ value }) => {
    if (choices.getLabel) {
      return choices.getLabel(value) || "";
    } else {
      return getLabel(choices, value) || "";
    }
  };

export const percentageCell = ({ value }) => {
  if (value) {
    return `${Number(value).toFixed(3)} %`;
  }
  return "";
};

export const currencyCell = ({ value }) => {
  if (value) {
    const output = currencyCellMoneyDisplay(value);
    return <p style={{ textAlign: "center", margin: 0 }}>{output}</p>;
  }
  return "";
};

export const timeCell = ({ value }) => {
  if (value) {
    const output = timeDisplay(value);
    return <p style={{ textAlign: "center", margin: 0 }}>{output}</p>;
  }
  return "";
};

export const leftAlignedCurrencyCell = ({ value }) => {
  if (value) {
    const output = currencyCellMoneyDisplay(value);
    return <p style={{ textAlign: "left", marginBottom: 0 }}>{output}</p>;
  }
  return "";
};

export const idCell = ({ value }) => {
  if (value) {
    let prefix = "LR";
    let numeric_part = value % 1000;
    let alphabetic_natural_division_1 = Math.floor(value / 1000);
    let alphabetic_natural_division_2 = Math.floor(value / (1000 * 26));
    let first_letter = String.fromCharCode("A".charCodeAt(0) + (alphabetic_natural_division_1 % 26));
    let second_letter = String.fromCharCode("A".charCodeAt(0) + (alphabetic_natural_division_2 % 26));
    return `${prefix}-${second_letter}${first_letter}${numeric_part.toString().padStart(3, "0")}`;
  }
  return "";
};

export const notesCell = ({ value }) => {
  if (value) {
    return (
      <div className="text-wrap">
        {value
          .replaceAll(/(<([^>]+)>)/gi, " ")
          .replaceAll("&amp;nbsp;", " ")
          .replaceAll("&nbsp;", " ")}{" "}
      </div>
    );
  }
  return "";
};

export const shortNotesCell = ({ value }) => {
  if (value) {
    if (typeof value !== "string") return "";
    let notes = value
      .replaceAll(/(<([^>]+)>)/gi, " ")
      .replaceAll("&amp;nbsp;", " ")
      .replaceAll("&nbsp;", " ");
    if (notes.length > 250) {
      notes = notes.slice(0, 250) + "...";
    }
    return <div className="text-wrap">{notes} </div>;
  }
  return "";
};
