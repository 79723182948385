import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import * as notifications from "notifications";
import { Select, FormCollapsible, SubmitButton, TextInput, EditorField } from "components/forms";
import { sendFunderEmail, fetchActivityLog, fetchInsured, setActiveEmailTemplate } from "actions";
import {
  activeAgencySelector,
  activeCaseIdSelector,
  activeCaseSelector,
  activeEmailTemplateSelector,
  agentsChoicesSelector,
  auctionIdSelector,
  auctionSelector,
  emailTemplateSelector,
  fundersForAuctionSelector,
  insuredListSelector,
  loginInfoSelector,
  selectedFundersSelector,
  suggestedCCSelector,
} from "reducers";
import { arrayToObjectIndexedById } from "reducers/utils";
import MultipleFileDropZone from "components/MultipleFileDropZone";
import { commaNameToFirstLastName, findObjectByPropertyValue, getAge, moneyDisplay, toTitleCase } from "utils";

const SendFunderEmailForm = ({ insuredInfo, ...props }) => {
  const formikRef = useRef();
  const getTemplateName = id => props.funderEmailTemplates[id].name;

  let case_notification_template;
  for (let template of Object.values(props.funderEmailTemplates)) {
    if (template.name === props.activeEmailTemplate) {
      case_notification_template = template;
    }
  }

  const getEmailSource = templateName => {
    if (templateName === "Funder Contract Diligence Notification") {
      return "Funder Submission & Closing Emails";
    } else if (funderSubmissionEmailTemplates.includes(templateName)) {
      return "Funder Submission Email";
    } else {
      return "General Email";
    }
  };

  // List of template names that should use Funder Submission Email
  const funderSubmissionEmailTemplates = [
    "Funder New Case Notification",
    "Funder Pending Case Follow Up",
    "Funder Winning Bid Notification",
  ];

  let emailSource = getEmailSource(case_notification_template.name);
  let toOption = { value: emailSource, label: emailSource };
  const [selectedToOption, setSelectedToOption] = React.useState(toOption);

  const getInsuredNames = () => {
    if (insuredInfo.length === 1) {
      return `${insuredInfo[0].last_name}, ${insuredInfo[0].first_name}`;
    } else if (insuredInfo.length === 2) {
      return `${insuredInfo[0].last_name}, ${insuredInfo[0].first_name} & ${insuredInfo[1].last_name}, ${insuredInfo[1].first_name}`;
    } else {
      return `{Insured Names}`;
    }
  };

  const getInsuredAges = () => {
    if (insuredInfo.length === 1) {
      return getAge(insuredInfo[0].date_of_birth);
    } else if (insuredInfo.length === 2) {
      return `${getAge(insuredInfo[0].date_of_birth)} & ${getAge(insuredInfo[1].date_of_birth)}`;
    } else {
      return `{Insured Ages}`;
    }
  };

  const getInsuredGenders = () => {
    if (insuredInfo.length === 1) {
      return insuredInfo[0].gender;
    } else if (insuredInfo.length === 2) {
      return `${insuredInfo[0].gender} & ${insuredInfo[1].gender}`;
    } else {
      return `{Insured Gender}`;
    }
  };

  const prepareSubject = title => {
    let subject = title;
    let insuredNames = getInsuredNames() || "";
    subject = subject.replace("{Insured Names}", insuredNames);
    subject = subject.replace("{Application ID}", props.caseInfo.submission_id);
    return subject;
  };

  const prepareBody = html => {
    let body = html;
    body = body.replace("{Organization Logo}", `<img src="${props.organizationLogo}" />`);
    let insured_names = getInsuredNames();
    let insured_ages = getInsuredAges();
    let insured_gender = getInsuredGenders();

    let marketType = props.caseInfo.market_type || "";
    if (marketType) {
      marketType = toTitleCase(marketType);
    }
    body = body.replace("{Insured Names}", insured_names);
    body = body.replace("{Insured Ages}", insured_ages);
    body = body.replace("{Insured Gender}", insured_gender);
    body = body.replace("{Face Amount}", moneyDisplay(props.caseInfo.face_amount));
    body = body.replace("{Product Type}", props.caseInfo.product_type);
    body = body.replace("{Market Type}", marketType);
    body = body.replace("{Funder Initial Review Google Link}", props.caseInfo.drive_initial_review_folder_url || "");
    body = body.replace("{Case Notes}", props.auction?.notes_to_funder || "-");
    let auction_manager = props.auctionManagerChoices
      .getChoices()
      .find(a => a.value === props.caseInfo.auction_manager);
    if (auction_manager) {
      let auction_manager_name = auction_manager.label || "";
      let auction_manager_email = auction_manager.email || "";
      let auction_manager_phone = auction_manager.phone || "";
      body = body.replace("{Trading Manager Agency User Name}", auction_manager_name);
      body = body.replace("{Trading Manager Agency User Email}", auction_manager_email);
      body = body.replace("{Trading Manager Agency User Office Phone}", auction_manager_phone);
    }
    body = body.replace("{User Name}", props.user_name);
    body = body.replace("{User Email}", props.email);
    body = body.replace("{User Phone}", props.user_phone);
    body = body.replace("{Agency Name}", props.agency_name);
    return body;
  };

  const handleEmailTemplateChange = e => {
    if (!e) return;
    const id = e.value;
    const template = props.funderEmailTemplates[id];
    formikRef.current.setFieldValue("email_template", id);
    let subject = prepareSubject(template.subject);
    let body = prepareBody(template.html);
    props.setActiveEmailTemplate(template.name);
    let newEmailSource = getEmailSource(template.name);
    formikRef.current.setFieldValue("subject", subject);
    formikRef.current.setFieldValue("body", body);
    formikRef.current.setFieldValue("_to", newEmailSource);
    formikRef.current.setFieldValue("recipients", getSelectedFunderEmails(newEmailSource));
    setSelectedToOption({ value: newEmailSource, label: newEmailSource });
  };

  const getSelectedFunderEmails = email_source => {
    if (props.selectedFunderIds) {
      let result = "";
      let winningBidTemplate = props.activeEmailTemplate === "Funder Winning Bid Notification";
      for (let id of props.selectedFunderIds) {
        let funder = props.funders[id];
        if (funder) {
          if (winningBidTemplate && !funder.winning_bid_notification) {
            result += funder.name + " (No Winning Bid Notification Sent); ";
          } else if (email_source === "General Email") {
            result += funder.name + " <" + funder.email + ">; ";
          } else if (email_source === "Funder Submission Email") {
            result += funder.name + " <" + funder.case_submission_email + ">; ";
          } else if (email_source === "Funder Closing Email") {
            result += funder.name + " <" + funder.funder_closing_email + ">; ";
          } else if (email_source === "Funder Submission & Closing Emails") {
            let emails = [];
            if (funder.case_submission_email) {
              emails.push(funder.case_submission_email);
            }
            if (funder.funder_closing_email) {
              emails.push(funder.funder_closing_email);
            }
            result += funder.name + " <" + emails.join("; ") + ">; ";
          }
        }
      }
      return result;
    } else {
      return "";
    }
  };

  const handleToChange = email_source => {
    if (email_source) {
      formikRef.current.setFieldValue("_to", email_source.label);
      formikRef.current.setFieldValue("recipients", getSelectedFunderEmails(email_source.value));
    }
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          recipients: getSelectedFunderEmails(emailSource),
          cc: props.suggestedCC,
          bcc: "",
          email_template: case_notification_template.id,
          subject: prepareSubject(case_notification_template.subject),
          body: prepareBody(case_notification_template.html),
          _from: props.email,
          _to: selectedToOption.value,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          let _to = values._to;
          if (typeof values._to === "object") {
            _to = values._to.value;
          }
          try {
            let payload = {
              ...values,
              selected_funder_ids: props.selectedFunderIds,
              bidding_format: props.caseInfo.bidding_format,
              _to: _to,
              email_template_name: getTemplateName(values.email_template),
              auction_id: props.auction_id,
            };
            if (props.caseInfo && props.caseInfo.provider && props.caseInfo.bidding_format === "LPEX") {
              payload.provider = props.caseInfo.provider;
            }
            const response = await props.sendFunderEmail(payload, props.auction.id);
            if (response && response.status === 500) {
              notifications.error("Error sending email to Funders");
              return false;
            }

            const data = response.data;
            if (data && data.error) {
              notifications.error(data.error);
              setSubmitting(false);
              return false;
            }
            if (data && data.date === "There are no rounds on this date") {
              notifications.error(
                "There are no rounds on this date for this auction. Please create a round so the activity can be created",
              );
              setSubmitting(false);
              return false;
            }
            notifications.success("Email sent to Funders successfully");
            props.fetchActivityLog(props.activeCaseId);
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Error sending email to Funders");
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          const handleSuggestedCCChange = e => {
            if (e) {
              let cc = "";
              if (values.cc) {
                cc = values.cc;
              }
              if (cc.includes(e.value)) {
                formikRef.current.setFieldValue("cc", cc.replace(e.value, ""));
              } else {
                formikRef.current.setFieldValue("cc", cc + e.value);
              }
            }
          };
          return (
            <>
              <FormCollapsible onSubmit={handleSubmit} title="Send Funder Email">
                <Form.Row>
                  <Select
                    label="Email Template"
                    name="email_template"
                    placeholder="Select Email Template"
                    onChange={handleEmailTemplateChange}
                    options={props.templateChoices}
                  />
                </Form.Row>
                <Form.Row>
                  <Select
                    label="From"
                    name="_from"
                    placeholder="Select sender email"
                    options={[
                      { value: props.email, label: props.email },
                      { value: props.agency_trading_email, label: props.agency_trading_email },
                      { value: props.agency_closing_email, label: props.agency_closing_email },
                    ]}
                  />
                  <Select
                    label="To"
                    name="_to"
                    placeholder="Select receiver email options"
                    options={[
                      { value: "General Email", label: "General Email" },
                      { value: "Funder Submission Email", label: "Funder Submission Email" },
                      { value: "Funder Closing Email", label: "Funder Closing Email" },
                      { value: "Funder Submission & Closing Emails", label: "Funder Submission & Closing Emails" },
                    ]}
                    onChange={handleToChange}
                  />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Recipients" name="recipients" as="textarea" rows={3} disabled={true} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="CC" name="cc" placeholder="Enter CC Email(s)" />
                </Form.Row>
                <Form.Row>
                  <Select
                    label="Suggested CC"
                    placeholder="Suggested CC"
                    name="suggested_cc"
                    options={props.suggestedCCChoices}
                    onChange={handleSuggestedCCChange}
                  />
                  <TextInput label="BCC" name="bcc" placeholder="Enter BCC Email(s)" />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Subject Line" name="subject" isRequired />
                </Form.Row>
                <Form.Row>
                  <EditorField
                    label={values.email_template ? getTemplateName(values.email_template) : "Email Template"}
                    name="body"
                    isRequired
                  />
                </Form.Row>
                <SubmitButton defaultText={"Send"} />
              </FormCollapsible>
              <MultipleFileDropZone setFieldValue={setFieldValue} />
            </>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = state => {
  const agentsChoices = agentsChoicesSelector(state);
  const { email, user_name, user_phone, agencies } = loginInfoSelector(state);
  const activeAgency = activeAgencySelector(state);
  const agency_closing_email = agencies.find(a => a.id === activeAgency.id).closing_email || "";
  const agency_trading_email = agencies.find(a => a.id === activeAgency.id).trading_email || "";
  const auction = auctionSelector(state);
  const funderEmailTemplates = arrayToObjectIndexedById(emailTemplateSelector(state));
  let templateChoices = [];
  for (let choice of Object.values(funderEmailTemplates)) {
    if (choice.name.includes("Funder")) {
      templateChoices.push({ value: choice.id, label: choice.name });
    }
  }
  // Sort by label
  templateChoices.sort((a, b) => a.label.localeCompare(b.label));

  let caseInfo = activeCaseSelector(state);
  let suggestedCCChoices = [
    {
      value: "Aaron Giroux <aaron@liferoccapital.com>;",
      label: "Aaron Giroux",
    },
    {
      value: "Brandon Marz <bmarz@liferoccapital.com>;",
      label: "Brandon Marz",
    },
    {
      value: "Geoff Palya <gpalya@liferoccapital.com>;",
      label: "Geoff Palya",
    },
    {
      value: "Mahmoud Afia <m.afia@liferoccapital.com>;",
      label: "Mahmoud Afia",
    },
  ];
  let closingManagerChoices = agentsChoices.closing_managers.getChoices();
  const closingManagerId = caseInfo.closing_manager;
  if (closingManagerId) {
    const closingManager = findObjectByPropertyValue(closingManagerChoices, "value", closingManagerId);
    if (closingManager) {
      let name = commaNameToFirstLastName(closingManager.label);
      suggestedCCChoices.push({
        value: `${name} <${closingManager.email}>;`,
        label: `${name} (Closing Manager)`,
      });
    }
  }

  return {
    activeCaseId: activeCaseIdSelector(state),
    auctionId: auctionIdSelector(state),
    auction,
    funders: arrayToObjectIndexedById(Object.values(fundersForAuctionSelector(state))),
    selectedFunderIds: selectedFundersSelector(state),
    suggestedCC: suggestedCCSelector(state),
    templateChoices,
    funderEmailTemplates,
    caseInfo,
    suggestedCCChoices,
    insuredInfo: insuredListSelector(state),
    auctionManagerChoices: agentsChoices.auction_managers,
    organizationLogo: activeAgency.logo,
    agency_name: activeAgency.name,
    email,
    user_name,
    user_phone,
    agency_closing_email,
    agency_trading_email,
    activeEmailTemplate: activeEmailTemplateSelector(state),
  };
};

export default connect(mapStateToProps, {
  sendFunderEmail,
  fetchActivityLog,
  fetchInsured,
  setActiveEmailTemplate,
})(SendFunderEmailForm);
