import { CLEAN_LIFE_SETTLEMENT_CASE_DATA, FETCH_LIFE_SETTLEMENT_CASE_ERROR, LOADING } from "actions/types";

export const defaultState = {
  login: false,
  case: false,
  cases: false,
  caseFiles: true,
  policy: true,
  funders: false,
  funder_cases: true,
  funder_contacts: true,
  funder_entities: true,
  le: false,
  insured: true,
  owners: true,
  beneficiaries: true,
  auction: true,
  error404: false,
  products: false,
  carriers: false,
  accounts: false,
  records: false,
  accountHierarchies: false,
  activityLog: false,
  predictions: false,
  leads: false,
  parameters: false,
  synonyms: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, ...action.payload };
    case FETCH_LIFE_SETTLEMENT_CASE_ERROR:
      return {
        ...state,
        error404: true,
        caseFiles: false,
        policy: false,
        funder_cases: false,
        funder_contacts: false,
        funder_entities: false,
        insured: false,
        owners: false,
        beneficiaries: false,
        auction: false,
        products: false,
        carriers: false,
        accounts: false,
        records: false,
        accountHierarchies: false,
      };
    case CLEAN_LIFE_SETTLEMENT_CASE_DATA:
      return defaultState;
    default:
      return state;
  }
};

// Selectors
export const loadingSelector = state => state;
export const loadingLoginSelector = state => state.login === true;
export const loadingCasesSelector = state => state.cases === true;
export const loadingProductsSelector = state => state.products === true;
export const loadingCarriersSelector = state => state.carriers === true;
export const loadingAccountsSelector = state => state.accounts === true;
export const loadingLeadsSelector = state => state.leads === true;
export const loadingFundersSelector = state => state.funders === true;
export const loadingFunderCasesSelector = state => state.funder_cases === true;
export const loadingFunderCaseFitsSelector = state => state.funder_case_fits === true;
export const loadingFunderContactsSelector = state => state.funder_contacts === true;
export const loadingFunderEntitiesSelector = state => state.funder_entities === true;
export const loadingInsuredSelector = state => state.insured === true;
export const loadingOwnersSelector = state => state.owners === true;
export const loadingBeneficiariesSelector = state => state.beneficiaries === true;
export const loadingCaseFilesSelector = state => state.caseFiles === true;
export const loadingLeSelector = state => state.le === true;
export const loadingRecordsSelector = state => state.records === true;
export const loadingAccountHierarchiesSelector = state => state.accountHierarchies === true;
export const loadingPredictionsSelector = state => state.predictions === true;
export const loadingParametersSelector = state => state.parameters === true;
export const loadingSynonymsSelector = state => state.synonyms === true;
