import React from "react";
import { useDispatch } from "react-redux";

import { PRICING_DOCUMENTS_MODAL } from "../../../components/modals";
import { hideModal } from "actions";
import SimpleModal from "../../../components/modals/SimpleModal";
import PricingDocumentsForm from "./PricingDocumentsForm";

// Case
export const PricingDocumentModal = ({ id }) => {
  const dispatch = useDispatch();
  return (
    <SimpleModal
      header={`Generate Pricing documents`}
      modalType={PRICING_DOCUMENTS_MODAL}
      onConfirm={() => {
        dispatch(hideModal(PRICING_DOCUMENTS_MODAL));
      }}
    >
      <PricingDocumentsForm id={id} />
    </SimpleModal>
  );
};

export default PricingDocumentModal;
