import React from "react";

const TableHeader = ({ children, ...props }) => (
  <div className="table-header">{children}</div>
);

const TableHeaderTabs = ({ children, ...props }) => (
  <div className="table-header" id="funders-records-table-header">{children}</div>
);

const Title = ({ children, ...props }) => (
  <h4 className="title" style={{ marginBottom: "0" }} {...props}>
    {children}
  </h4>
);

const Actions = ({ children, ...props }) => (
  <div className="actions" {...props}>
    {children}
  </div>
);

TableHeader.Title = Title;
TableHeader.Actions = Actions;
TableHeader.Tabs = TableHeaderTabs;

export default TableHeader;
