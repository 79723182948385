import React from "react";

import { Select } from ".";

export default props => {
  return (
    <Select
      options={[
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ]}
      {...props}
    />
  );
};
