import React from "react";
import Table, { dateCell } from "../../components/table";
import IconLink from "../../components/IconLink";
import { FaDownload, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import { activeFunderEntitySelector } from "../../reducers";
import { DELETE_TRADING_DOCUMENT_MODAL, TRADING_DOCUMENT_MODAL } from "../../components/modals";
import { downloadFile, selectTradingDocument, showModal } from "actions";

const TradingDocumentTable = ({ ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const tradingDocumentId = row.original.id;
        const file = row.original.file;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectTradingDocument(row.original.id);
                props.showModal(TRADING_DOCUMENT_MODAL);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon btn--red" }}
              tooltip="Delete"
              onClick={() => {
                props.showModal(DELETE_TRADING_DOCUMENT_MODAL, {
                  id: row.original.id,
                });
              }}
            />
            {file ? (
              <>
                <IconLink
                  Icon={FaDownload}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Download"
                  onClick={() => {
                    const url = `/life_settlement/api/trading_document/${tradingDocumentId}/download_file/`;
                    props.downloadFile(url, row.original.file.name);
                  }}
                />
                <IconLink
                  Icon={FaEye}
                  iconConfig={{ className: "table--action-icon" }}
                  tooltip="Preview"
                  onClick={() => window.open(row.original.file.url, "_blank")}
                />
              </>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Document Type",
      accessor: "document_type",
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Effective Date",
      accessor: "effective_date",
    },
    {
      Header: "In Use",
      accessor: "in_use",
      Cell: ({ row }) => {
        let value = "";
        if (row.original.in_use === true) {
          value = "Yes";
        } else if (row.original.in_use === false) {
          value = "No";
        }
        return <div className="text-wrap">{value}</div>;
      },
    },
    {
      Header: "LE Reps & Warranties",
      accessor: "le_reps_and_warranties",
      Cell: ({ row }) => {
        let value = "";
        if (row.original.le_reps_and_warranties === true) {
          value = "Yes";
        } else if (row.original.le_reps_and_warranties === false) {
          value = "No";
        }
        return <div className="text-wrap">{value}</div>;
      },
    },
    {
      Header: "File Type",
      accessor: "file_type",
      Cell: ({ row }) => {
        if (row.original.file && row.original.file.type) {
          return row.original.file.type;
        } else {
          return "";
        }
      },
    },
    {
      Header: "File Size",
      accessor: "file_size",
      Cell: ({ row }) => {
        if (row.original.file) {
          return row.original.file.size;
        } else {
          return "";
        }
      },
    },
    {
      Header: "Date Added",
      accessor: "date_added",
      Cell: dateCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.funderEntityInfo ? props.funderEntityInfo.trading_documents : []}
      paginated={false}
      emptyMessage="No records found"
    />
  );
};

const mapStateToProps = state => {
  return {
    funderEntityInfo: activeFunderEntitySelector(state),
  };
};

export default connect(mapStateToProps, {
  selectTradingDocument,
  showModal,
  downloadFile,
})(TradingDocumentTable);
