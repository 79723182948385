import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { FaEdit, FaTrash, FaCopy } from "react-icons/fa";

import {
  fetchLifeSettlementCases,
  copyCase,
  showModal,
  editCase,
  getFundersForClosing,
  fetchEmailTemplates,
  fetchLifeSettlementCase,
  fetchInsured,
  selectFunder,
  fetchFunderAuctionActivities,
} from "actions";
import {
  activeAgencySelector,
  casesSelector,
  managementChoicesSelector,
  loadingCasesSelector,
  storedCasesFiltersSelector,
  emailTemplateSelector,
  funderWinningBidSelector,
} from "reducers";
import IconLink from "components/IconLink";
import Table, { choiceCell, currencyCell, dateCell, dateOverdueCell, idCell } from "components/table";
import { COPY_CASE_MODAL, DELETE_CASE_MODAL, SEND_FUNDER_EMAIL_MODAL } from "components/modals";
import { usStatesChoices } from "../../../options";
import ReactSelect from "react-select";
import * as notifications from "../../../notifications";
import { LOADING, SET_EMAIL_TEMPLATE, SET_SELECTED_FUNDERS } from "../../../actions/types";
import * as types from "../../../actions/types";
import { isAuthorized } from "../../../utils";
import { caseStatusChoices, PERMISSIONS } from "../../../constants";

const CaseTable = ({ cases, casesLoading, activeAgency, currentFilters, ...props }) => {
  // Fetch life settlement cases when the active agency changes.
  React.useEffect(() => {
    props.fetchLifeSettlementCases({
      ...currentFilters,
      status: currentFilters.status,
    });
  }, [activeAgency.id]);

  const dispatch = useDispatch();

  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const caseId = row.original.id;
        return (
          <>
            <IconLink
              to={`/cases/${caseId}/policy/`}
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Manage Life Settlement Case"
              onClick={() => {
                dispatch({ type: LOADING, payload: { error404: false } });
                window.scrollTo(0, 0);
              }}
            />
            {props.hasAdminPermission && (
              <IconLink
                Icon={FaCopy}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="Copy Case"
                onClick={() => props.showModal(COPY_CASE_MODAL, { id: caseId })}
              />
            )}
            {props.hasAdminPermission && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon red" }}
                tooltip="Delete Life Settlement Case"
                onClick={() => props.showModal(DELETE_CASE_MODAL, { id: caseId })}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "Case ID",
      accessor: "id",
      Cell: idCell,
    },
    {
      Header: "Status",
      Cell: ({ row }) => {
        const customStyles = {
          control: (provided, state) => ({
            ...provided,
            background: "#fff",
            minHeight: "36px",
            boxShadow: state.isFocused ? null : null,
            width: "200px",
          }),

          menu: (provided, state) => ({
            ...provided,
            width: "240px",
          }),
        };

        let initialValue = {};
        if (row.original.status) {
          initialValue = {
            value: row.original.status,
            label: row.original.status_name,
          };
        }
        let [statusValue, setStatusValue] = useState(initialValue);

        return props.hasAdminPermission ? (
          <ReactSelect
            menuPortalTarget={document.body}
            maxMenuHeight={200}
            name={"status"}
            options={caseStatusChoices}
            onChange={async e => {
              setStatusValue({ value: e.value, label: e.label });
              await props.editCase(row.original.id, { status: e.value }, false);
              notifications.success("Case Status saved successfully");

              // If the new status is Offer Declined, show notification popup
              let newStatus = e.value;
              let offerDeclinedChange = newStatus === "I-OD";
              if (offerDeclinedChange) {
                // Request a list of funders for closing
                let caseId = row.original.id;
                let fundersData = await props.getFundersForClosing(caseId);
                if (fundersData && fundersData.length > 0) {
                  notifications.warn("Case Closed with Bidding Funder(s) - showing Funder Case Closed Notification");
                  let funderIdsWithBids = fundersData.map(funder => funder.id);
                  await dispatch({ type: SET_SELECTED_FUNDERS, payload: funderIdsWithBids });
                  await dispatch({ type: types.PARTIAL_FETCH_FUNDERS, payload: fundersData });
                  await props.fetchLifeSettlementCase(caseId);
                  await props.fetchInsured(caseId);
                  await props.fetchEmailTemplates();
                  await dispatch({ type: SET_EMAIL_TEMPLATE, payload: "Funder Case Closed Notification" });
                  props.showModal(SEND_FUNDER_EMAIL_MODAL);
                }
              }

              // If the new status is Contract Diligence, show notification popup
              let statusChangedToContractDiligence = newStatus === "IC-ACDR";
              if (statusChangedToContractDiligence) {
                let caseId = row.original.id;
                // Request updated funder auction activities to determine if there is a winning bid
                await props.fetchFunderAuctionActivities(caseId);
                if (props.funderWinningBid) {
                  notifications.warn("Case Status changed to Contract Diligence - Notify Winning Bidder");
                  let fundersData = await props.selectFunder(props.funderWinningBid.funder);
                  await props.fetchInsured(caseId);
                  await dispatch({ type: SET_SELECTED_FUNDERS, payload: [props.funderWinningBid.funder] });
                  await dispatch({ type: types.PARTIAL_FETCH_FUNDERS, payload: [fundersData] });
                  await dispatch({ type: SET_EMAIL_TEMPLATE, payload: "Funder Contract Diligence Notification" });
                  props.showModal(SEND_FUNDER_EMAIL_MODAL);
                } else {
                  notifications.warn(
                    "Case Status changed to Contract Diligence - no Winning Bid on Case, no Winning Bidder to notify.",
                  );
                }
              }
            }}
            value={statusValue}
            className={`select`}
            menuPlacement="auto"
            styles={customStyles}
            isDisabled={false}
            placeholder="Select Case Status"
          />
        ) : (
          <p style={{ marginTop: "1rem" }}>{row.original.status_name}</p>
        );
      },
    },
    {
      Header: "Insured Names",
      accessor: "insured_names",
      className: "wrap-text",
    },
    {
      Header: "Carrier",
      accessor: "carrier_name",
      className: "wrap-text",
    },
    {
      Header: "Policy #",
      accessor: "policy_number",
    },
    {
      Header: "Face Amount",
      accessor: "face_amount",
      Cell: currencyCell,
    },
    {
      Header: "Product Type",
      accessor: "product_type",
      className: "wrap-text",
    },
    {
      Header: "Jurisdiction",
      accessor: "jurisdiction",
      Cell: choiceCell(usStatesChoices),
      className: "wrap-text",
    },
    {
      Header: "Source",
      accessor: "source_name",
      className: "wrap-text",
    },
    {
      Header: "Sales Rep",
      accessor: "sales_representative_name",
      className: "wrap-text",
    },
    {
      Header: "Create Date",
      accessor: "submission_date",
      Cell: dateCell,
    },
    {
      Header: "Next Due Date",
      accessor: "next_due_date",
      Cell: dateOverdueCell,
    },
    {
      Header: "Last Edit Date",
      accessor: "last_edit_date",
      Cell: dateCell,
    },
  ];

  return <Table columns={columns} data={cases} emptyMessage={casesLoading ? "Loading Cases data..." : "No data"} />;
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const currentFilters = storedCasesFiltersSelector(state);
  const roles = state.auth.activeAgencyRoles;
  return {
    cases: casesSelector(state),
    casesLoading: loadingCasesSelector(state),
    activeAgency: activeAgencySelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
    accountChoices: choices.accounts,
    salesRepChoices: choices.case_sales_representatives,
    currentFilters: currentFilters,
    funderEmailTemplates: emailTemplateSelector(state),
    funderWinningBid: funderWinningBidSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchLifeSettlementCases,
  showModal,
  editCase,
  copyCase,
  getFundersForClosing,
  fetchEmailTemplates,
  fetchLifeSettlementCase,
  fetchInsured,
  fetchFunderAuctionActivities,
  selectFunder,
})(CaseTable);
