import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";

import {
  createOwnerEntity,
  createOwnerInsured,
  createOwnerPerson,
  editOwnerEntityInfo,
  editOwnerInsured,
  editOwnerPersonInfo,
  showModal,
} from "actions";
import { activeCaseIdSelector, activeOwnerSelector, insuredListSelector, ownerInsuredListSelector } from "reducers";
import { EditorField, DateInput, FormCollapsible, PercentageInput, Select, SubmitButton } from "components/forms";
import { fullNameDisplay, isAuthorized } from "../../../utils";
import moment from "moment/moment";
import DateInputWithPresentButton from "../../../components/forms/DateInputWithPresentButton";
import { PERMISSIONS } from "../../../constants";

const OwnerBasicForm = ({ activeOwner, hasAdminPermission, ...props }) => {
  const action = activeOwner.id ? "edit" : "create";

  const getOwnerTypeOptions = () => {
    let options = [
      { value: "entity", label: action === "create" ? "Add Entity" : "Entity" },
      { value: "person", label: action === "create" ? "Add Person" : "Person" },
    ];

    // Exclude Insured that have been already selected as Owners
    let availableInsuredList = [];
    let ownerInsuredIds = props.ownerInsuredList.map(insured => insured.insured);
    for (let insured of props.insuredList) {
      if (ownerInsuredIds.indexOf(insured.id) === -1) {
        availableInsuredList.push(insured);
      }
    }
    if (availableInsuredList) {
      options.push({
        label: "Insured",
        options: availableInsuredList.map(insured => ({
          value: insured.id,
          label: fullNameDisplay(insured.last_name, insured.first_name),
        })),
      });
    }

    return options;
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          owner_type: activeOwner.type,
          notes: activeOwner.notes,
          ownership_percentage: activeOwner.ownership_percentage,
          ownership_start_date: activeOwner.ownership_start_date || moment().format("YYYY-MM-DD"),
          ownership_end_date: activeOwner.ownership_end_date,
          present: !activeOwner.ownership_end_date,
        }}
        validationSchema={Yup.object({
          ownership_percentage: Yup.number()
            .nullable()
            .min(0, "Number must be positive")
            .max(1, "Number can't be greater than 100%"),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const { owner_type, ownership_percentage, ownership_start_date, ownership_end_date, notes } = values;

          try {
            if (action === "create") {
              if (owner_type === "person") {
                await props.createOwnerPerson(
                  props.caseId,
                  ownership_percentage,
                  ownership_start_date,
                  ownership_end_date,
                  notes,
                );
              } else if (owner_type === "entity") {
                await props.createOwnerEntity(
                  props.caseId,
                  ownership_percentage,
                  ownership_start_date,
                  ownership_end_date,
                  notes,
                );
              } else {
                await props.createOwnerInsured(
                  owner_type,
                  ownership_percentage,
                  ownership_start_date,
                  ownership_end_date,
                  notes,
                );
              }
            }

            if (action === "edit") {
              const { id } = activeOwner;
              if (owner_type === "person") {
                await props.editOwnerPersonInfo(id, {
                  ownership_percentage,
                  ownership_start_date,
                  ownership_end_date,
                  notes,
                });
              } else if (owner_type === "entity") {
                await props.editOwnerEntityInfo(id, {
                  ownership_percentage,
                  ownership_start_date,
                  ownership_end_date,
                  notes,
                });
              } else {
                await props.editOwnerInsured(id, {
                  ownership_percentage,
                  ownership_start_date,
                  ownership_end_date,
                  notes,
                });
              }
            }
          } catch (error) {
            setErrors(error.response.data);
          }

          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values }) => {
          return (
            <FormCollapsible onSubmit={handleSubmit} title="Owner Detail">
              <Form.Row>
                <Select
                  label="Owner Type"
                  name="owner_type"
                  placeholder="Select Owner Type"
                  options={getOwnerTypeOptions()}
                  md={3}
                  isRequired
                  disabled={action === "edit" || !hasAdminPermission}
                />
                <PercentageInput
                  label="Ownership %"
                  name="ownership_percentage"
                  md={3}
                  isRequired
                  helpText={
                    values.owner_type === "undefined"
                      ? "Select an owner type before filling this field"
                      : "Value must be between 0 and 100"
                  }
                  disabled={values.owner_type === "undefined" || !hasAdminPermission}
                />
                <DateInput label="Ownership start date" name="ownership_start_date" disabled={!hasAdminPermission} />
                <>
                  <DateInputWithPresentButton
                    label="Ownership end date"
                    name="ownership_end_date"
                    disabled={!hasAdminPermission}
                  />
                </>
              </Form.Row>
              <Form.Row>
                <EditorField label="Notes" name="notes" disabled={!hasAdminPermission} />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </FormCollapsible>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = state => {
  const roles = state.auth.activeAgencyRoles;
  return {
    activeOwner: activeOwnerSelector(state),
    caseId: activeCaseIdSelector(state),
    insuredList: insuredListSelector(state),
    ownerInsuredList: ownerInsuredListSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  createOwnerEntity,
  createOwnerInsured,
  createOwnerPerson,
  editOwnerEntityInfo,
  editOwnerInsured,
  editOwnerPersonInfo,
  showModal,
})(OwnerBasicForm);
