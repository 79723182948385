import React from "react";
import { connect } from "react-redux";
import { FieldArray, Formik } from "formik";
import { Form } from "react-bootstrap";

import { createBeneficiary, createBeneficiarySigner, editBeneficiary, showModal } from "actions";
import { OWNER_ENTITY, OWNER_PERSON, ENTITY, PERSON } from "actions/life_settlement/beneficiaries";
import { ButtonCreate, ButtonDelete } from "components/buttons";
import {
  FormCollapsible,
  Select,
  BooleanSelect,
  SubmitButton,
  PercentageInput,
  TextInput,
  DateInput,
  StateSelect,
  TIN_Input,
  Collapsible,
  PhoneInput,
} from "components/forms";
import * as notifications from "../../../notifications";
import {
  ownerEntitiesSelector,
  ownerPersonsSelector,
  activeCaseIdSelector,
  ownerPersonSelector,
  ownerEntitySelector,
  beneficiaryEntitiesSelector,
} from "reducers";
import { partial, fullNameDisplay, percentageDisplay, isAuthorized } from "../../../utils";
import OwnerEntityInfo from "./OwnerEntityInfo";
import { DELETE_BENEFICIARY_MODAL, DELETE_BENEFICIARY_SIGNER_MODAL } from "components/modals";
import { entityTypeChoices, PERMISSIONS } from "../../../constants";

let OwnershipPercentage = ({ owner }) => {
  if (!owner) {
    return null;
  }

  let percentage = "--"; // Empty value display
  if (owner.ownership_percentage) {
    percentage = percentageDisplay(owner.ownership_percentage);
  }
  return (
    <Form.Row>
      <p>{`Ownership Percentage: ${percentage}`}</p>
    </Form.Row>
  );
};

const ownershipPercentageMapStateToProps = (state, ownProps) => {
  let owner;
  const { ownerEntityId, ownerPersonId } = ownProps;
  if (ownerEntityId) {
    owner = ownerEntitySelector(state, ownerEntityId);
  } else if (ownerPersonId) {
    owner = ownerPersonSelector(state, ownerPersonId);
  }
  return { owner };
};

OwnershipPercentage = connect(ownershipPercentageMapStateToProps)(OwnershipPercentage);

const BeneficiaryForm = ({ beneficiary, hasAdminPermission, ...props }) => {
  const [selectedBeneficiary, setSelectedBeneficiary] = React.useState({
    type: beneficiary.beneficiaryType,
    id: beneficiary.id,
    get value() {
      if (beneficiary.beneficiaryType === OWNER_ENTITY) {
        return beneficiary.beneficiaryType + "-" + beneficiary.owner_entity;
      } else if (beneficiary.beneficiaryType === OWNER_PERSON) {
        return beneficiary.beneficiaryType + "-" + beneficiary.owner_person;
      } else {
        return beneficiary.beneficiaryType + "-" + beneficiary.id;
      }
    },
  });

  const getBeneficiaryOptions = () => {
    let options = [];

    if (props.ownerEntities.length > 0) {
      options.push({
        label: "Owner Entities",
        options: props.ownerEntities.map(ownerEntity => ({
          label: ownerEntity.entity_name || `(Unnamed Entity)`,
          value: OWNER_ENTITY + "-" + ownerEntity.id,
          type: OWNER_ENTITY,
          id: ownerEntity.id,
        })),
      });
    }

    if (props.ownerPersons.length > 0) {
      options.push({
        label: "Owner Persons",
        options: props.ownerPersons.map(ownerPerson => ({
          label: fullNameDisplay(ownerPerson.last_name, ownerPerson.first_name) || `(Unnamed Person)`,
          value: OWNER_PERSON + "-" + ownerPerson.id,
          type: OWNER_PERSON,
          id: ownerPerson.id,
        })),
      });
    }

    options.push({
      label: beneficiary.id ? "Entity" : "Add Entity",
      value: ENTITY + "-" + beneficiary.id,
      type: ENTITY,
    });
    options.push({
      label: beneficiary.id ? "Person" : "Add Person",
      value: PERSON + "-" + beneficiary.id,
      type: PERSON,
    });

    return options;
  };

  let signers;
  if (selectedBeneficiary.type === ENTITY) {
    let beneficiaries = props.beneficiaryEntities.filter(e => e.id === beneficiary.id);
    signers = beneficiaries[0] && Object.values(beneficiaries[0].signers);
  }

  let beneficiaryType = "";
  switch (beneficiary.beneficiaryType) {
    case OWNER_ENTITY:
      beneficiaryType = "(Owner Entity)";
      break;
    case OWNER_PERSON:
      beneficiaryType = "(Owner Person)";
      break;
    case ENTITY:
      beneficiaryType = "(Entity)";
      break;
    case PERSON:
      beneficiaryType = "(Person)";
      break;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        selectedBeneficiary: selectedBeneficiary.value,
        beneficiary_percentage: beneficiary.beneficiary_percentage,

        /** For PERSON */
        first_name: beneficiary.first_name,
        last_name: beneficiary.last_name,
        active_POA: beneficiary.active_POA,
        POA_name: beneficiary.POA_name,
        name: beneficiary.name,
        full_legal_name: beneficiary.full_legal_name,
        title: beneficiary.title,

        /** For ENTITY */
        entity_name: beneficiary.entity_name,
        entity_type: beneficiary.entity_type,
        formation_date: beneficiary.formation_date,
        domicile_state: beneficiary.domicile_state,
        tin_number: beneficiary.tin_number,
        fled_bk: beneficiary.fled_bk,
        street_address: beneficiary.street_address,
        city: beneficiary.city,
        state: beneficiary.state,
        zipcode: beneficiary.zipcode,
        signers: signers,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = beneficiary.id
          ? partial(props.editBeneficiary, selectedBeneficiary.type, beneficiary.id)
          : partial(props.createBeneficiary, selectedBeneficiary.type);

        if (selectedBeneficiary.type === OWNER_PERSON) {
          values = { ...values, owner_person: selectedBeneficiary.id };
        } else if (selectedBeneficiary.type === OWNER_ENTITY) {
          values = { ...values, owner_entity: selectedBeneficiary.id };
        }
        try {
          await method({ ...values, case: props.caseId });
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing beneficiary");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title={`Beneficiary (${props.beneficiaryIdx}) ${beneficiaryType}`}>
            <Form.Row>
              <Select
                label="Beneficiary"
                name="selectedBeneficiary"
                defaultValue={selectedBeneficiary.value}
                options={getBeneficiaryOptions()}
                onChange={o => setSelectedBeneficiary(o)}
                disabled={beneficiary.id || !hasAdminPermission}
              />
              <PercentageInput label="Beneficiary %" name="beneficiary_percentage" disabled={!hasAdminPermission} />
            </Form.Row>
            {(selectedBeneficiary.type === OWNER_ENTITY || selectedBeneficiary.type === OWNER_PERSON) && (
              <OwnershipPercentage
                ownerEntityId={selectedBeneficiary.type === OWNER_ENTITY && beneficiary.owner_entity}
                ownerPersonId={selectedBeneficiary.type === OWNER_PERSON && beneficiary.owner_person}
              />
            )}
            {selectedBeneficiary.type === OWNER_ENTITY && (
              <Form.Row>
                <OwnerEntityInfo ownerEntityId={values.owner_entity} />
              </Form.Row>
            )}
            {selectedBeneficiary.type === PERSON && (
              <>
                <Form.Row>
                  <TextInput label="First Name" name="first_name" disabled={!hasAdminPermission} />
                  <TextInput label="Last Name" name="last_name" disabled={!hasAdminPermission} />
                </Form.Row>
                <Form.Row>
                  <BooleanSelect
                    label="POA In Effect?"
                    name="active_POA"
                    placeholder="Select POA in effect"
                    disabled={!hasAdminPermission}
                  />
                  <TextInput
                    label="POA Name"
                    name="POA_name"
                    isRequired={values.active_POA === true}
                    disabled={values.active_POA !== true || !hasAdminPermission}
                  />
                </Form.Row>
              </>
            )}
            {selectedBeneficiary.type === ENTITY && (
              <>
                <Form.Row>
                  <TextInput label="Entity Name" name="entity_name" isRequired disabled={!hasAdminPermission} />
                </Form.Row>
                <Form.Row>
                  <Select
                    label="Entity Type"
                    name="entity_type"
                    placeholder="Select Entity Type"
                    options={entityTypeChoices}
                    isRequired
                    disabled={!hasAdminPermission}
                  />
                  <DateInput label="Formation Date" name="formation_date" isRequired disabled={!hasAdminPermission} />
                  <StateSelect
                    label="Entity Domicile"
                    name="domicile_state"
                    placeholder="Select Entity Domicile State"
                    isRequired
                    disabled={!hasAdminPermission}
                  />
                </Form.Row>
                <Form.Row>
                  <TIN_Input label="Tax ID Number (TIN)" name="tin_number" isRequired disabled={!hasAdminPermission} />
                  <TextInput
                    label="# of Required Signers"
                    name="_"
                    value={signers && signers.length}
                    disabled
                    isRequired
                  />
                  <BooleanSelect
                    label="Ever Fled BK?"
                    placeholder="Select choice"
                    name="fled_bk"
                    isRequired
                    disabled={!hasAdminPermission}
                  />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Street Address" name="street_address" isRequired disabled={!hasAdminPermission} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="City" name="city" isRequired disabled={!hasAdminPermission} />
                  <StateSelect isRequired disabled={!hasAdminPermission} />
                  <TextInput label="Zip Code" name="zipcode" isRequired disabled={!hasAdminPermission} />
                </Form.Row>

                <FieldArray
                  name={`signers`}
                  render={arrayHelpers => (
                    <>
                      {signers &&
                        signers.map((signer, index) => {
                          let signerValues = arrayHelpers.form.values.signers[index];
                          return (
                            <Collapsible key={index} title={`Signer ${index + 1}`}>
                              <Form.Row>
                                <TextInput
                                  label="Full Legal Name"
                                  name={`signers[${index}].full_legal_name`}
                                  isRequired
                                  disabled={!hasAdminPermission}
                                />
                                <TextInput
                                  label="Title"
                                  name={`signers[${index}].title`}
                                  disabled={!hasAdminPermission}
                                />
                              </Form.Row>
                              <Form.Row>
                                <PhoneInput
                                  label="Phone Number"
                                  name={`signers[${index}].telephone_number`}
                                  disabled={!hasAdminPermission}
                                />
                                <TextInput
                                  label="Email"
                                  lowercase={true}
                                  name={`signers[${index}].email`}
                                  disabled={!hasAdminPermission}
                                />
                              </Form.Row>
                              <Form.Row>
                                <BooleanSelect
                                  label="POA In Effect?"
                                  name={`signers[${index}].active_POA`}
                                  placeholder="Select POA in effect"
                                  md={4}
                                  isRequired
                                  disabled={!hasAdminPermission}
                                />
                                <TextInput
                                  label="POA Name"
                                  name={`signers[${index}].POA_name`}
                                  isRequired={signerValues && signerValues.active_POA === true}
                                  disabled={(signerValues && signerValues.active_POA !== true) || !hasAdminPermission}
                                />
                              </Form.Row>
                              <Form.Row>
                                <button
                                  className="btn red"
                                  type="button"
                                  onClick={() => props.showModal(DELETE_BENEFICIARY_SIGNER_MODAL, signers[index])}
                                >
                                  Delete Signer
                                </button>
                              </Form.Row>
                            </Collapsible>
                          );
                        })}
                    </>
                  )}
                />
              </>
            )}
            <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            {beneficiary.id && hasAdminPermission && (
              <ButtonDelete
                onClick={() =>
                  props.showModal(DELETE_BENEFICIARY_MODAL, {
                    beneficiaryType: selectedBeneficiary.type,
                    id: beneficiary.id,
                  })
                }
                style={{ marginLeft: "1rem" }}
              />
            )}
            {selectedBeneficiary.type === ENTITY && beneficiary.id && hasAdminPermission ? (
              <Form.Row>
                <ButtonCreate
                  type={"button"}
                  onClick={() => {
                    props.createBeneficiarySigner(beneficiary.id);
                  }}
                  disabled={signers && signers.length === 5}
                  title={signers && signers.length === 5 ? "Max signers reached (5)" : ""}
                >
                  Add Signer
                </ButtonCreate>
              </Form.Row>
            ) : null}
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const roles = state.auth.activeAgencyRoles;
  return {
    ownerEntities: ownerEntitiesSelector(state),
    beneficiaryEntities: beneficiaryEntitiesSelector(state),
    ownerPersons: ownerPersonsSelector(state),
    caseId: activeCaseIdSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  editBeneficiary,
  createBeneficiary,
  createBeneficiarySigner,
  showModal,
})(BeneficiaryForm);
