import React from "react";
import { connect } from "react-redux";

import { setActiveEmailTemplate, setActiveEmailTemplateType, showModal } from "actions";
import Table from "components/table";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import IconLink from "components/IconLink";
import { DELETE_EMAIL_TEMPLATE_MODAL, EMAIL_TEMPLATE_MODAL } from "components/modals";
import { BiShow } from "react-icons/bi";
import IconButton from "../../../components/IconButton";
import { getViewUrl } from "../../../utils";

const EmailTemplatesTable = ({ templateType, data, ...props }) => {
  /* Generic Table - the underlying object is the same EmailTemplate but it's split in different types */

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.setActiveEmailTemplate(row.original);
                props.setActiveEmailTemplateType(row.original.type);
                props.showModal(EMAIL_TEMPLATE_MODAL);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon btn--red" }}
              tooltip="Delete"
              onClick={() => {
                props.showModal(DELETE_EMAIL_TEMPLATE_MODAL, {
                  id: row.original.id,
                });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Email ID",
      accessor: "email_id",
    },
    {
      Header: "Template Name",
      accessor: "name",
    },
    {
      Header: "Subject",
      accessor: "subject",
    },
    {
      Header: "Attachment",
      accessor: "attachment",
      Cell: ({ row }) => {
        const file = row.original.file;
        if (file) {
          let viewUrl = getViewUrl(row.original.file);
          return (
            <>
              <IconButton
                Icon={FaEye}
                onClick={() => window.open(viewUrl, "_blank")}
                iconConfig={{ size: "1.2rem" }}
                title="View File"
              />
            </>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Last Modified By",
      accessor: "last_edited_by",
    },
  ];

  return (
    <Table
      columns={columns}
      data={data}
      defaultSort={true}
      paginated={false}
      emptyMessage={`No ${templateType} Email Templates found`}
    />
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  showModal,
  setActiveEmailTemplate,
  setActiveEmailTemplateType,
})(EmailTemplatesTable);
