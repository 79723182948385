import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import {
  Select,
  EditorField,
  FormCollapsible,
  SubmitButton,
  DateInput,
  TextInput,
  DateTimeInput,
} from "components/forms";
import { createAccountTask, editAccountTask } from "actions";
import { activeAccountIdSelector, activeTaskSelector, userChoicesSelector } from "reducers";
import { partial } from "../../../../utils";
import { TASK_STATUS, TASK_TYPES } from "../../constants";

const AccountTaskForm = ({ activeTask, ...props }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...activeTask,
        owner: activeTask.owner_id || props.userId,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeTask) ? props.createAccountTask : partial(props.editAccountTask, activeTask.id);

        values.account = props.activeAccountId;

        try {
          await method(values);
          notifications.success("Task saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Task");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Task Detail">
            <Form.Row style={{ padding: 0 }}>
              <Select label="Type" name="type" placeholder="Select Type" options={TASK_TYPES} />
              <Select label="Status" name="status" placeholder="Select Status" options={TASK_STATUS} />
              <Select label="Owner" name="owner" placeholder="Select Owner" options={props.userChoices.getChoices()} />
              <DateInput label="Due Date" name="due_date" />
            </Form.Row>
            <Form.Row style={{ padding: 0 }}>
              <TextInput label="Task Subject" name="subject" />
            </Form.Row>
            <Form.Row style={{ padding: 0 }}>
              <TextInput label="Task Creator" name="created_by" disabled />
              <DateInput label="Create Date" name="date_created" disabled />
              <TextInput label="Last Edited By" name="last_edited_by_name" disabled />
              <DateTimeInput label="Last Edit Date" name="last_edit_date" disabled />
            </Form.Row>
            <Form.Row style={{ padding: 0 }}>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <SubmitButton />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    activeAccountId: activeAccountIdSelector(state),
    activeTask: activeTaskSelector(state) || {},
    userChoices: userChoicesSelector(state),
    userId: state.auth.user_id,
  };
};

export default connect(mapStateToProps, {
  createAccountTask,
  editAccountTask,
})(AccountTaskForm);
