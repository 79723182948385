import { Formik } from "formik";
import { SubmitButton } from "../../components/forms";
import { Form } from "react-bootstrap";
import { pendingRecordsCountSelector, pastDueRecordsCountSelector } from "reducers";
import { connect } from "react-redux";
import { editCase, selectCaseFile, showModal } from "../../actions";
import React from "react";
import { DisplayValueWithTitle } from "../../components/forms/DisplayValueWithTitle";

const RecordsSummaryForm = ({ caseInfo, ...props }) => {
  return (
    <Formik enableReinitialize initialValues={{}} onSubmit={{}}>
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <Form onSubmit={handleSubmit}>
              <Form.Row style={{ padding: 0 }}>
                <DisplayValueWithTitle title={"Number of Pending Tasks"} value={props.pendingRecordsCount} md={3} />
                <DisplayValueWithTitle title={"Number of Past Due Tasks"} value={props.pastDueRecordsCount} md={3} />
              </Form.Row>
              <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    pendingRecordsCount: pendingRecordsCountSelector(state),
    pastDueRecordsCount: pastDueRecordsCountSelector(state),
  };
};

export default connect(mapStateToProps, {
  editCase,
  selectCaseFile,
  showModal,
})(RecordsSummaryForm);
