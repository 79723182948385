/* Helper function to convert an array of objects into an object whose keys are the
 * object's id.
 * The conversion is done because it is much simpler to update/find by id in an object
 * (whose keys are the ids) than in an array.
 *
 * The array's objects must have an id attribute.
 *
 * @example
 *
 *    arrayToObjectIndexedById([
 *     { id: 1, otherData: 1 },
 *     { id: 2, otherData: 2 },
 *    ])
 *
 *    returns:
 *    {
 *      1: { id: 1, otherData: 1 },
 *      2: { id: 2, otherData: 2 },
 *    }
 */
export const arrayToObjectIndexedById = arr => {
  return arr.reduce((acc, obj) => {
    acc[obj.id] = obj;
    return acc;
  }, {});
};

export const addBusinessDays = (currentDate, daysToAdd) => {
  let count = 0;

  while (count < daysToAdd) {
    currentDate.setDate(currentDate.getDate() + 1);
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      // It's not a weekend (Saturday or Sunday)
      count++;
    }
  }

  return currentDate;
};

export const addThreeBusinessDays = () => addBusinessDays(new Date(), 3);
