import * as types from "../types";
import advisorhq from "../../apis/advisorhq";
import * as notifications from "../../notifications";
import { sortBySelector } from "../../reducers";
import { redirectTo, sortByParam } from "../../utils";

export const selectLead = id => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_LEAD, payload: id });
  const response = await advisorhq.get("/leads/api/lead/" + id + "/");
  dispatch({ type: types.FETCH_LEAD, payload: response.data });
};

export const clearSelectedLead = () => async dispatch => {
  dispatch({ type: types.CLEAR_SELECTED_LEAD });
};

export const fetchLeads = filters => async (dispatch, getState) => {
  dispatch({ type: types.LOADING, payload: { leads: true } });
  const sort_by = sortBySelector(getState());
  const sort_by_value = sortByParam(sort_by);
  const response = await advisorhq.get("/leads/api/lead/", {
    params: {
      ...filters,
      page: 1,
      sort_by: sort_by_value,
    },
  });
  let { results, ...paginateData } = response.data;
  paginateData = { ...paginateData, page: 1 };
  dispatch({ type: types.SET_LEADS_PAGINATION_DATA, payload: paginateData });
  dispatch({ type: types.FETCH_LEADS, payload: results });
  dispatch({ type: types.LOADING, payload: { leads: false } });
};

export const fetchLeadTasks = id => async (dispatch, getState) => {
  const response = await advisorhq.get("/api/lead_task/", {
    params: {
      lead_id: id,
    },
  });
  dispatch({ type: types.FETCH_LEAD_TASKS, payload: response.data });
};

export const createEmptyLead = () => async (dispatch, getState) => {
  const url = "/leads/api/lead/";
  try {
    const response = await advisorhq.post(url, {});
    dispatch({ type: types.CREATE_LEAD, payload: response.data });
    notifications.success("A new Lead was created");
    window.scrollTo(0, 0);
    redirectTo(`/leads/${response.data.id}/overview/`);
  } catch (e) {
    notifications.error("Error creating Lead");
  }
};

export const editLead = (leadId, newValues) => async (dispatch, getState) => {
  notifications.success("Saving...");
  const endpoint = `/leads/api/lead/${leadId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: types.EDIT_LEAD, payload: response.data });
};

export const selectAccountForAdvisorLead = (leadId, newValues) => async (dispatch, getState) => {
  notifications.success("Saving...");
  const endpoint = `/leads/api/select-account-for-advisor-lead/${leadId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: types.EDIT_LEAD, payload: response.data });
};

export const updateLeadsFilters = filters => async (dispatch, getState) => {
  dispatch({ type: types.STORE_LEADS_FILTERS_DATA, payload: filters });
};

export const deleteLead = id => async (dispatch, getState) => {
  try {
    await advisorhq.delete(`/leads/api/lead/${id}/`);
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  dispatch({ type: types.DELETE_LEAD, payload: { id } });
  dispatch({ type: types.LOADING, payload: { leads: true } });
  const response = await advisorhq.get("/leads/api/lead/", {
    params: {
      page: 1,
    },
  });
  let { results, ...paginateData } = response.data;
  paginateData = { ...paginateData, page: 1 };
  dispatch({ type: types.SET_LEADS_PAGINATION_DATA, payload: paginateData });
  dispatch({ type: types.FETCH_LEADS, payload: results });
  dispatch({ type: types.LOADING, payload: { leads: false } });
};

export const createLeadTask = values => async (dispatch, getState) => {
  const url = "/api/lead_task/";
  try {
    const response = await advisorhq.post(url, values);
    dispatch({ type: types.FETCH_LEAD_TASK, payload: response.data });
    notifications.success("A new Task was created");
  } catch (e) {
    notifications.error("Error creating Task");
  }
};

export const editLeadTask = (taskId, newValues, row) => async (dispatch, getState) => {
  notifications.success("Saving...");
  dispatch({ type: types.LOADING, payload: { tasks: true } });
  const endpoint = `/api/lead_task/${taskId}/`;
  const response = await advisorhq.patch(endpoint, newValues);
  dispatch({ type: types.EDIT_LEAD_TASK, payload: response.data });
  dispatch({ type: types.FETCH_LEAD_TASK, payload: response.data });
};

export const deleteLeadTask = (taskId, leadId) => async (dispatch, getState) => {
  try {
    await advisorhq.delete(`/api/lead_task/${taskId}/`);
  } catch (e) {
    notifications.error(e.response.data.error);
  }
  const response = await advisorhq.get("/api/lead_task/", {
    params: {
      lead_id: parseInt(leadId, 10),
    },
  });
  dispatch({ type: types.FETCH_LEAD_TASKS, payload: response.data });
};

export const selectLeadTask = data => async (dispatch, getState) => {
  dispatch({ type: types.SELECT_LEAD_TASK, payload: data });
};

export const runAllocation = lead_id => async (dispatch, getState) => {
  try {
    const response = await advisorhq.get(`/leads/api/run_lead_allocation/${lead_id}/`);
    dispatch({ type: types.EDIT_LEAD, payload: response.data });
    notifications.success("Sales Rep Assigned");
    return response.data;
  } catch (e) {
    let errorMsg = "Error during Run Allocation";
    if (e.response.data) {
      errorMsg += ": " + e.response.data;
    }
    notifications.error(errorMsg);
  }
};
