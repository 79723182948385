import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import * as notifications from "notifications";
import { SubmitButton, EditorField } from "components/forms";
import { editMortalityAssessment, unsetShouldSave, showModal } from "actions";
import { activeMortalityAssessmentSelector, shouldSaveSelector } from "reducers";
import { partial } from "utils";
import { RecordsTable } from "../../../../components/table";
import ConditionListTable from "./conditionList/ConditionListTable";
import MedicationListTable from "./MedicationListTable";
import { ADD_CONDITION_MODAL } from "components/modals";
import { ButtonCreate } from "../../../../components/buttons";

const SummaryForms = ({ mortalityAssessmentInfo, ...props }) => {
  const submitRef = React.useRef();
  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  return (
    <Formik
      enableReinitialize
      initialValues={mortalityAssessmentInfo}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        let data = {
          narrative_summary: values.narrative_summary,
          family_history_summary: values.family_history_summary,
        };
        const method = partial(props.editMortalityAssessment, mortalityAssessmentInfo.id);

        try {
          await method(data);
          notifications.success("Mortality Assessment saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Mortality Assessment");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div
              className="form-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <EditorField label="Narrative Summary" name="narrative_summary" />
            </div>
            <RecordsTable
              title="Condition List"
              defaultSort={true}
              paginated={false}
              Table={ConditionListTable}
              actionButtons={[
                <ButtonCreate
                  onClick={e => {
                    e.preventDefault();
                    props.showModal(ADD_CONDITION_MODAL);
                  }}
                >
                  Add Condition
                </ButtonCreate>,
              ]}
            />
            <RecordsTable
              title="Medication List"
              defaultSort={true}
              paginated={false}
              Table={MedicationListTable}
              actionButtons={[]}
            />
            <div
              className="form-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0px",
              }}
            >
              <EditorField label="Family History Summary" name="family_history_summary" />
            </div>
            <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    shouldSave: shouldSaveSelector(state),
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {
  editMortalityAssessment,
  unsetShouldSave,
  showModal,
})(SummaryForms);
