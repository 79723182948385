import {
  FETCH_ACCOUNTS,
  FETCH_ALL_LIFE_EXPECTANCIES,
  FETCH_LEADS,
  FETCH_LIFE_SETTLEMENT_CASES,
  FETCH_PARAMETERS,
  FETCH_RECORDS,
  SET_ACCOUNTS_PAGINATION_DATA,
  SET_CASES_PAGINATION_DATA,
  SET_LEADS_PAGINATION_DATA,
  SET_LES_PAGINATION_DATA,
  SET_PARAMETERS_PAGINATION_DATA,
  SET_RECORDS_PAGINATION_DATA,
} from "../types";
import {
  accountsCountSelector,
  caseCountSelector,
  currentPageAccountsSelector,
  currentPageCaseSelector,
  currentPageLeSelector,
  currentPageRecordsSelector,
  getPagesAccountsCount,
  getPagesCaseCount,
  getPagesLeCount,
  getPagesRecordsCount,
  leCountSelector,
  nextPageAccountsSelector,
  nextPageCaseSelector,
  nextPageLeSelector,
  nextPageRecordsSelector,
  previousPageAccountsSelector,
  previousPageCaseSelector,
  previousPageLeSelector,
  previousPageRecordsSelector,
  recordsCountSelector,
  storedAccountsFiltersSelector,
  storedCasesFiltersSelector,
  storedLeFiltersSelector,
  storedRecordsFiltersSelector,
  leadsCountSelector,
  currentPageLeadsSelector,
  getPagesLeadsCount,
  nextPageLeadsSelector,
  previousPageLeadsSelector,
  storedLeadsFiltersSelector,
  storedParametersFiltersSelector,
  nextPageParametersSelector,
  previousPageParametersSelector,
  currentPageParametersSelector,
  parametersCountSelector,
  getPagesParametersCount,
} from "../../reducers";

export const ENDPOINTS_TO_URL_MAPPING = {
  CASES: "/life_settlement/api/cases/v2/",
  LIFE_EXPECTANCY: "/underwriting/api/life_expectancies/",
  TASKS: "/life_settlement/api/tasks/",
  ACCOUNTS: "/api/account/",
  LEADS: "/leads/api/lead/",
  PARAMETERS: "/api/parameter/",
};

export const ENDPOINTS_TO_ACTION_TYPES_MAPPING = {
  CASES: FETCH_LIFE_SETTLEMENT_CASES,
  LIFE_EXPECTANCY: FETCH_ALL_LIFE_EXPECTANCIES,
  TASKS: FETCH_RECORDS,
  ACCOUNTS: FETCH_ACCOUNTS,
  LEADS: FETCH_LEADS,
  PARAMETERS: FETCH_PARAMETERS,
};

export const ENDPOINTS_TO_PAGINATION_ACTION_TYPES_MAPPING = {
  CASES: SET_CASES_PAGINATION_DATA,
  LIFE_EXPECTANCY: SET_LES_PAGINATION_DATA,
  TASKS: SET_RECORDS_PAGINATION_DATA,
  ACCOUNTS: SET_ACCOUNTS_PAGINATION_DATA,
  LEADS: SET_LEADS_PAGINATION_DATA,
  PARAMETERS: SET_PARAMETERS_PAGINATION_DATA,
};

export const ENDPOINTS_TO_LOADING_VALUE_MAPPING = {
  CASES: "cases",
  LIFE_EXPECTANCY: "le",
  TASKS: "tasks",
  ACCOUNTS: "accounts",
  LEADS: "leads",
  PARAMETERS: "parameters",
};

export const ENDPOINTS_TO_FILTERS_SELECTOR_MAPPING = {
  CASES: storedCasesFiltersSelector,
  LIFE_EXPECTANCY: storedLeFiltersSelector,
  TASKS: storedRecordsFiltersSelector,
  ACCOUNTS: storedAccountsFiltersSelector,
  LEADS: storedLeadsFiltersSelector,
  PARAMETERS: storedParametersFiltersSelector,
};

export const ENDPOINTS_TO_ACTIVE_SECTIONS_MAPPING = {
  CASES: "cases",
  LIFE_EXPECTANCY: "lifeexpectancy",
  TASKS: "tasks",
  ACCOUNTS: "accounts",
  LEADS: "leads",
  PARAMETERS: "parameters",
};

// DETERMINES THE STARTING POINT X - Y OF Z RESULTS IN PAGINATION
export const ENDPOINTS_TO_INITIAL_PAGE_RESULTS_SELECTOR = {
  CASES: 1,
  LIFE_EXPECTANCY: 2,
  TASKS: "tasks",
  ACCOUNTS: "accounts",
};

export const ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING = {
  CASES: {
    NEXT: nextPageCaseSelector,
    PREVIOUS: previousPageCaseSelector,
    CURRENT: currentPageCaseSelector,
    COUNT: caseCountSelector,
    PAGES: getPagesCaseCount,
  },
  LIFE_EXPECTANCY: {
    NEXT: nextPageLeSelector,
    PREVIOUS: previousPageLeSelector,
    CURRENT: currentPageLeSelector,
    COUNT: leCountSelector,
    PAGES: getPagesLeCount,
  },
  TASKS: {
    NEXT: nextPageRecordsSelector,
    PREVIOUS: previousPageRecordsSelector,
    CURRENT: currentPageRecordsSelector,
    COUNT: recordsCountSelector,
    PAGES: getPagesRecordsCount,
  },
  ACCOUNTS: {
    NEXT: nextPageAccountsSelector,
    PREVIOUS: previousPageAccountsSelector,
    CURRENT: currentPageAccountsSelector,
    COUNT: accountsCountSelector,
    PAGES: getPagesAccountsCount,
  },
  LEADS: {
    NEXT: nextPageLeadsSelector,
    PREVIOUS: previousPageLeadsSelector,
    CURRENT: currentPageLeadsSelector,
    COUNT: leadsCountSelector,
    PAGES: getPagesLeadsCount,
  },
  PARAMETERS: {
    NEXT: nextPageParametersSelector,
    PREVIOUS: previousPageParametersSelector,
    CURRENT: currentPageParametersSelector,
    COUNT: parametersCountSelector,
    PAGES: getPagesParametersCount,
  },
};
