import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { isEmpty } from "lodash";

import { FormCollapsible, DateInput, TextInput, DateTimeInput, EditorField } from "components/forms";
import { createEmailTemplate, editEmailTemplate } from "actions";
import { activeEmailTemplateSelector, activeEmailTemplateTypeSelector } from "reducers";
import WideSaveButton from "components/buttons/WideSaveButton";
import { FaQuestionCircle } from "react-icons/all";

const EmailTemplateForm = ({ activeEmailTemplate, activeEmailTemplateType, ...props }) => {
  const helpText = "Use <b>{Footer}</b> to inject a footer in the email body<br><br>";
  return (
    <Formik
      enableReinitialize
      initialValues={activeEmailTemplate}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        delete values.file; // File is saved through a different endpoint
        if (isEmpty(activeEmailTemplate)) {
          // Handle type of email template
          values.type = activeEmailTemplateType;

          props.createEmailTemplate(values, setErrors);
        } else {
          props.editEmailTemplate(activeEmailTemplate.id, values, setErrors);
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Email Template Detail">
            <Form.Row>
              <TextInput label="Template Name" name="name" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Subject" name="subject" />
            </Form.Row>
            <Form.Row>
              <DateInput label="Date Account Created" name="date_created" disabled />
              <DateTimeInput label="Last Modified Date" name="last_edit_date" disabled />
              <TextInput label="Last Modified By" name="last_edited_by" disabled />
            </Form.Row>
            <Form.Row>
              <EditorField label="Body" name="html" tooltip={helpText} />
            </Form.Row>
            <WideSaveButton submitRef={props.submitRef} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    activeEmailTemplate: activeEmailTemplateSelector(state) || {},
    activeEmailTemplateType: activeEmailTemplateTypeSelector(state),
  };
};

export default connect(mapStateToProps, {
  createEmailTemplate,
  editEmailTemplate,
})(EmailTemplateForm);
