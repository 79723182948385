import React from "react";
import { connect } from "react-redux";
import { clearSelectedMortalityAssessment, selectMortalityAssessment, showModal } from "actions";
import { activeAgencySelector, mortalityAssessmentsSelector } from "reducers";
import Table, { dateCell } from "components/table";
import IconLink from "components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DELETE_MORTALITY_ASSESSMENT_MODAL } from "components/modals";
import { isAuthorized } from "../../../utils";
import { PERMISSIONS } from "../../../constants";
import { loadingParametersSelector } from "../../../reducers/life_settlement/loading";

const MortalityAssessmentTable = ({ mortalityAssessments, mortalityAssessmentsLoading, activeAgency, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <>
            <IconLink
              to={`/riskx-assessments/${row.original.id}/summary/`}
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View / Edit"
              onClick={() => {
                props.clearSelectedMortalityAssessment();
                window.scrollTo(0, 0);
              }}
            />
            {props.hasAdminPermission && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon red" }}
                tooltip="Delete"
                onClick={() => {
                  props.selectMortalityAssessment(row.original.id);
                  props.showModal(DELETE_MORTALITY_ASSESSMENT_MODAL, { id: row.original.id });
                }}
              />
            )}
          </>
        );
      },
    },
    {
      Header: "MA ID",
      accessor: "ma_id",
    },
    {
      Header: "MA Date",
      accessor: "date_created",
      Cell: dateCell,
    },
    {
      Header: "Associated Case ID",
      accessor: "case_submission_id",
    },
    {
      Header: "Insured Name",
      Cell: ({ row }) => {
        const first_name = row.original.first_name;
        const last_name = row.original.last_name;
        return `${last_name}, ${first_name}`;
      },
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "MA DOB",
      accessor: "date_of_birth",
      Cell: dateCell,
    },
    {
      Header: "MA Age",
      accessor: "age",
    },
    {
      Header: "Mortality Rating",
      accessor: "mortality_rating",
    },
    {
      Header: "Tobacco Use",
      accessor: "tobacco_use",
    },
    {
      Header: "MA Mean",
      accessor: "mean",
    },
    {
      Header: "MA Median",
      accessor: "median",
    },
    {
      Header: "Primary Impairment",
      accessor: "primary_impairment",
    },
    {
      Header: "Secondary Impairment",
      accessor: "secondary_impairment",
    },
  ];

  return (
    <Table
      columns={columns}
      data={mortalityAssessments}
      emptyMessage={
        mortalityAssessmentsLoading ? "Loading Mortality Assessment data..." : "Select a Mortality Assessment"
      }
      showPaginationResults={true}
      initialPageSize={100}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const roles = state.auth.activeAgencyRoles;
  return {
    mortalityAssessments: mortalityAssessmentsSelector(state),
    activeAgency: activeAgencySelector(state),
    mortalityAssessmentsLoading: loadingParametersSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectMortalityAssessment,
  clearSelectedMortalityAssessment,
})(MortalityAssessmentTable);
