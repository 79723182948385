import React from "react";
import { Form, Col, InputGroup } from "react-bootstrap";

import Label from "./Label";

const TextInput = ({ label, md = "", value, ...props }) => {
  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <Label label={label} />
      <InputGroup>
        <div
          className="editable form-control"
          dangerouslySetInnerHTML={{ __html: value }}
          style={{
            backgroundColor: "#E3E2E2FF",
            pointerEvents: "none",
            height: "100%",
            minHeight: 36,
          }}
        />
      </InputGroup>
    </Form.Group>
  );
};

export default TextInput;
