import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../notifications";
import {
  Select,
  EditorField,
  FormCollapsible,
  SubmitButton,
  DateInput,
  TextInput,
  DateTimeInput,
} from "components/forms";
import { createLeadTask, editLeadTask, fetchManagerAgents } from "actions";
import { activeLeadIdSelector, activeLeadTaskSelector, userChoicesSelector } from "reducers";
import { partial } from "../../../utils";
import { TASK_STATUS, TASK_TYPES } from "../../accounts/constants";
import { addThreeBusinessDays } from "../../../reducers/utils";

const LeadTaskForm = ({ activeTask, ...props }) => {
  React.useEffect(() => {
    props.fetchManagerAgents();
  }, []);
  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...activeTask,
        owner: isEmpty(activeTask) ? props.userId : activeTask.owner,
        due_date: isEmpty(activeTask) ? props.defaultDueDate : activeTask.due_date,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeTask) ? props.createLeadTask : partial(props.editLeadTask, activeTask.id);

        values.lead_id = props.activeLeadId;

        try {
          await method(values);
          notifications.success("Task saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Task");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Task Detail">
            <Form.Row style={{ padding: 0 }}>
              <Select label="Type" name="type" placeholder="Select Type" options={TASK_TYPES} />
              <Select label="Status" name="status" placeholder="Select Status" options={TASK_STATUS} />
              <Select label="Owner" name="owner" placeholder="Select Owner" options={props.userChoices} />
              <DateInput label="Due Date" name="due_date" />
            </Form.Row>
            <Form.Row style={{ padding: 0 }}>
              <TextInput label="Task Subject" name="subject" />
            </Form.Row>
            <Form.Row style={{ padding: 0 }}>
              <TextInput label="Task Creator" name="created_by" disabled />
              <DateInput label="Create Date" name="date_created" disabled />
              <TextInput label="Last Edited By" name="last_edited_by_name" disabled />
              <DateTimeInput label="Last Edit Date" name="last_edit_date" disabled />
            </Form.Row>
            <Form.Row style={{ padding: 0 }}>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <SubmitButton />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const userChoices = userChoicesSelector(state).getChoices({ sortByLabel: true });
  const defaultDueDate = addThreeBusinessDays().toISOString().split("T")[0];
  return {
    activeLeadId: activeLeadIdSelector(state),
    activeTask: activeLeadTaskSelector(state) || {},
    userChoices,
    defaultDueDate,
    userId: state.auth.user_id,
  };
};

export default connect(mapStateToProps, {
  createLeadTask,
  editLeadTask,
  fetchManagerAgents,
})(LeadTaskForm);
