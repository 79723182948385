import React from "react";
import { Link, useLocation } from "react-router-dom";

export const TabGroup = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        transform: "None",
      }}
      className="tab-group"
    >
      {children}
    </div>
  );
};

export const Tab = ({ link, children, ...props }) => {
  const location = useLocation();
  const isActive = location.pathname === link;

  let className = "tab";
  if (isActive) className += " active";

  return (
    <Link className={className} to={link} onClick={props.onClickCallback}>
      {children}
    </Link>
  );
};
