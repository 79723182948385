import React from "react";
import { connect } from "react-redux";

import { activeFunderSelector } from "reducers";
import { Card } from "react-bootstrap";
import { Tab, TabGroup } from "../../components/Tabs";
import { downloadFile, setShouldSave } from "../../actions";

const FunderDetailTopHeader = ({ ...props }) => {
  const path = `/funders`;

  const h3style = {
    width: "100%",
    top: "7.2rem",
    left: 0,
    zIndex: 1,
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}></Card>
      <h3 className={"header tertiary"} style={h3style}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          <Tab link={`${path}/`}>Funder List</Tab>
          <Tab link={`${path}-report/`}>Funder Activity Report</Tab>
          <Tab link={`${path}-profile/`}>Funder Buying Profile</Tab>
        </TabGroup>
      </h3>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let funderInfo = activeFunderSelector(state);
  let funderName = "";
  if (funderInfo) {
    funderName = funderInfo.name;
  }
  return {
    funderInfo,
    funderName,
  };
};

export default connect(mapStateToProps, {
  setShouldSave,
  downloadFile,
})(FunderDetailTopHeader);
