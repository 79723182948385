import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";

import {
  EditorField,
  FormCollapsible,
  SubmitButton,
  TextInput,
  DateTimeInput,
  Select,
  DateInput,
} from "components/forms";
import { activeCaseIdSelector, activeCaseTaskSelector, managementChoicesSelector } from "reducers";
import * as notifications from "notifications";
import { createCaseTask, editCaseTask, fetchLifeSettlementCase, hideModal } from "actions";
import { Form } from "react-bootstrap";
import { partial } from "utils";
import { TASK_STATUS } from "../../accounts/constants";
import { CASE_TASK_MODAL } from "../../../components/modals";
import { isEmpty } from "lodash";
import { addThreeBusinessDays } from "../../../reducers/utils";

const CaseTaskForm = ({ activeCaseTask, ...props }) => {
  let due_date = isEmpty(activeCaseTask) ? props.defaultDueDate : activeCaseTask.due_date;

  let initialValues = {
    ...activeCaseTask,
    ...activeCaseTask?.task_obj,
    due_date,
  };

  const dispatch = useDispatch();
  let isSaved = activeCaseTask?.task_obj?.id;
  let title = isSaved ? "Edit Task" : "New Task";

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isSaved ? partial(props.editCaseTask, activeCaseTask?.task_obj?.id) : props.createCaseTask;
        try {
          await method(values);
          // Refresh table by loading the case
          if (props.activeCaseId) {
            await props.fetchLifeSettlementCase(props.activeCaseId);
          }
          notifications.success("Case Task saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Case Task");
        }
        dispatch(hideModal(CASE_TASK_MODAL));
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <FormCollapsible onSubmit={handleSubmit} title={title}>
              <Form.Row>
                <TextInput label="Subject" name="subject" />
              </Form.Row>
              <Form.Row>
                <Select label="Status" name="status" placeholder="Select Status" options={TASK_STATUS} />
                <Select label="Owner" name="owner" placeholder="Select Owner" options={props.salesRepChoices} />
                <DateInput label="Due Date" name="due_date" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Created By" name="record_creator" disabled={true} />
                <TextInput label="Last Edited By" name="last_editor" disabled={true} />
                <DateTimeInput label="Last Edited Date" name="last_edit_date" disabled={true} />
              </Form.Row>
              <Form.Row>
                <EditorField label="Notes" name="notes" />
              </Form.Row>
              <SubmitButton />
            </FormCollapsible>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const activeCaseTask = activeCaseTaskSelector(state);
  const activeCaseId = activeCaseIdSelector(state);
  const choices = managementChoicesSelector(state);
  const defaultDueDate = addThreeBusinessDays().toISOString().split("T")[0];

  return {
    activeCaseTask,
    activeCaseId,
    salesRepChoices: choices.case_sales_representatives.getChoices({ sortByLabel: true }),
    defaultDueDate,
  };
};

export default connect(mapStateToProps, {
  createCaseTask,
  editCaseTask,
  fetchLifeSettlementCase,
})(CaseTaskForm);
