import React from "react";
import { connect } from "react-redux";
import { FaEdit, FaTrash } from "react-icons/fa";

import {
  copyCase,
  showModal,
  editCase,
  selectOwner,
  editOwnerInsured,
  editOwnerEntityInfo,
  editOwnerPersonInfo,
} from "actions";
import {
  activeAgencySelector,
  casesSelector,
  managementChoicesSelector,
  loadingCasesSelector,
  administratorEnabledSelector,
  storedCasesFiltersSelector,
} from "reducers";
import IconLink from "components/IconLink";
import Table, { dateCell, dateCellorPresent, percentageCell } from "components/table";
import { fullNameDisplay, isAuthorized } from "../../../utils";
import {
  DELETE_OWNER_ENTITY_MODAL,
  DELETE_OWNER_INSURED_MODAL,
  DELETE_OWNER_PERSON_MODAL,
} from "../../../components/modals";
import { PERMISSIONS } from "../../../constants";

const OwnersTable = ({ owners = [], casesLoading, activeAgency, currentFilters, hasAdminPermission, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="Edit Owner"
              onClick={() => {
                props.selectOwner({
                  type: row.original.owner_type.toLowerCase(),
                  id: row.original.id,
                });
              }}
            />
            {hasAdminPermission ? (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon red" }}
                tooltip="Delete Owner"
                onClick={() => {
                  if (row.original.owner_type === "Insured") {
                    props.showModal(DELETE_OWNER_INSURED_MODAL, {
                      id: row.original.id,
                    });
                  } else if (row.original.owner_type === "Person") {
                    props.showModal(DELETE_OWNER_PERSON_MODAL, {
                      id: row.original.id,
                      first_name: row.original.first_name,
                      last_name: row.original.last_name,
                    });
                  } else if (row.original.owner_type === "Entity") {
                    props.showModal(DELETE_OWNER_ENTITY_MODAL, {
                      id: row.original.id,
                      entity_name: row.original.entity_name,
                    });
                  }
                }}
              />
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Owner Name",
      accessor: "id",
      Cell: ({ row }) => {
        if (row.original.owner_type === "Person") {
          return (
            <div className="text-wrap">
              {fullNameDisplay(row.original.last_name, row.original.first_name) || `(Unnamed Person)`}
            </div>
          );
        } else if (row.original.owner_type === "Entity") {
          return <div className="text-wrap">{row.original.entity_name}</div>;
        } else if (row.original.owner_type === "Insured") {
          return (
            <div className="text-wrap">
              {fullNameDisplay(row.original.last_name, row.original.first_name) || `(Unnamed Person)`}
            </div>
          );
        }
      },
    },
    {
      Header: "Owner Type",
      accessor: "owner_type",
      className: "wrap-text",
    },
    {
      Header: "State",
      accessor: "state",
      className: "wrap-text",
    },
    {
      Header: "Share",
      accessor: "ownership_percentage",
      Cell: ({ value }) => {
        return percentageCell({ value: value * 100 });
      },
    },
    {
      Header: "Ownership Start Date",
      accessor: "ownership_start_date",
      Cell: dateCell,
    },
    {
      Header: "Ownership End Date",
      accessor: "ownership_end_date",
      Cell: dateCellorPresent,
    },
    {
      Header: "Notes",
      accessor: "notes",
      Cell: ({ row }) => {
        return (
          <div className="text-wrap">
            {row.original.notes
              .replace(/(<([^>]+)>)/gi, " ")
              .replace("&amp;nbsp;", " ")
              .replace("&nbsp;", " ")}{" "}
          </div>
        );
      },
    },
  ];

  return owners ? (
    <Table
      columns={columns}
      data={owners}
      emptyMessage={casesLoading ? "Loading data..." : "No data"}
      initialPageSize={50}
      paginated={false}
    />
  ) : null;
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const currentFilters = storedCasesFiltersSelector(state);
  const roles = state.auth.activeAgencyRoles;
  return {
    cases: casesSelector(state),
    casesLoading: loadingCasesSelector(state),
    activeAgency: activeAgencySelector(state),
    administratorEnabled: administratorEnabledSelector(state),
    accountChoices: choices.accounts,
    salesRepChoices: choices.case_sales_representatives,
    currentFilters: currentFilters,
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  showModal,
  editCase,
  copyCase,
  selectOwner,
  editOwnerInsured,
  editOwnerEntityInfo,
  editOwnerPersonInfo,
})(OwnersTable);
