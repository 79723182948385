import React from "react";

import { ButtonCreate } from "components/buttons";
import { TableHeader } from "components/table";

// Table to a list of records, with a title and a create button
const RecordsTable = ({
  Table,
  title,
  onCreateClick,
  createButtonLabel = "Create New",
  buttonDisabled = false,
  ...props
}) => {
  const createButton = (
    <ButtonCreate onClick={onCreateClick} disabled={buttonDisabled} key="create">
      {createButtonLabel}
    </ButtonCreate>
  );
  const renderActionButtons = () => {
    let actions = props.actionButtons ? props.actionButtons : [createButton];
    return <TableHeader.Actions>{actions.map(a => a)}</TableHeader.Actions>;
  };

  return (
    <div className="records-table">
      <TableHeader>
        <TableHeader.Title>{title}</TableHeader.Title>
        {renderActionButtons()}
      </TableHeader>
      <Table />
    </div>
  );
};

export default RecordsTable;
