import { makeChoices } from "./pages/funders/utils";

export const URLS = {
  LOGIN: "/auth/",
  LOGIN_NEXT: "/auth/next=",
  RESET_PASSWORD: "/auth/reset-password",
  NEW_PASSWORD: "/auth/new-password",
  SET_PASSWORD: "/auth/set-password",
  HOME: "/cases/",
  TASKS: "/tasks/",
  CASE_TASKS: "/case-tasks/",
  CASE_REQUIREMENT_TASKS: "/case-requirement-tasks/",
  ACCOUNT_TASKS: "/account-tasks/",
  LEAD_TASKS: "/lead-tasks/",
  ACCOUNTS: "/accounts/",
  LIFE_EXPECTANCY: "/life-expectancies/",
  FUNDERS: "/funders/",
  FUNDER_REPORT: "/funders-report/",
  FUNDER_PROFILE: "/funders-profile/",
  PRODUCTS: "/products/",
  CARRIERS: "/carriers/",
  TRADING: "/trading/",
  LEADS: "/leads/",
  PARAMETERS: "/riskx-parameters/",
  PARAMETER_DETAIL: "/riskx-parameters/:id",
  PARAMETER_DETAIL_BACKGROUND: "/riskx-parameters/:id/background/",
  PARAMETER_DETAIL_RISK: "/riskx-parameters/:id/risk/",
  PARAMETER_DETAIL_RESEARCH: "/riskx-parameters/:id/research/",
  PARAMETER_DETAIL_RATING: "/riskx-parameters/:id/rating/",
  PARAMETER_DETAIL_RELATIONS: "/riskx-parameters/:id/relations/",
  SYNONYMS: "/riskx-synonyms/",
  MORTALITY_ASSESSMENTS: "/riskx-assessments/",
  MORTALITY_ASSESSMENT_DETAIL: "/riskx-assessments/:id",
  MORTALITY_ASSESSMENT_DETAIL_SUMMARY: "/riskx-assessments/:id/summary/",
  MORTALITY_ASSESSMENT_DETAIL_CURVE: "/riskx-assessments/:id/curve/",
  ADMIN_EMAILS: "/admin-emails/",
  ADMIN_TEMPLATES: "/admin-templates/",
  ADMIN_USERS: "/admin-users/",
};

// MOMENT refers to momentjs formats. See https://momentjs.com/
export const DATE_FORMAT = {
  SERVER: {
    MOMENT: "YYYY-MM-DD",
  },
  DISPLAY: {
    MOMENT: "MM/DD/YYYY",
    PICKER: "MM/dd/yyyy", // react-datepicker equivalent of "MM/dd/yyyy"
  },
};

export const DATETIME_FORMAT = {
  SERVER: {
    MOMENT: undefined, // Default value is iso-8601, same as drf
  },
  DISPLAY: {
    PICKER: "MM/dd/yyyy h:mm aa",
  },
};

// Taken from underwriting.choices.py
export const VENDORS = {
  TWENTY_FIRST: "21st SERVICES",
  AVS: "AVS",
  CLARITY: "CLARITY",
  CONVERGENCE: "CONVERGENCE",
  FASANO: "FASANO",
  FOCUS: "FOCUS",
  ISC: "ISC",
  LAPETUS: "LAPETUS",
  LIFEROC: "LIFEROC",
  LSI: "LSI",
  POLARIS: "POLARIS",
  PREDICTIVE: "PREDICTIVE",
};

export const VENDOR_IDS = {
  TWENTY_FIRST: "21ST",
  AVS: "AVS",
  CLARITY: "CLAR",
  CONVERGENCE: "CONV",
  FASANO: "FAS",
  FOCUS: "FOCUS",
  ISC: "ISC",
  LAPETUS: "LAP",
  LSI: "LSI",
  POLARIS: "POLAR",
  PREDICTIVE: "PRED",
};

// Matches against the API names for each resource
export const FILE_TYPES = {
  BILLING_NOTICE: "billing_notices",
  CHAIN_OF_TITLE: "chain_of_titles",
  ILLUSTRATION: "illustrations",
  POLICY_AND_APPLICATION: "policy_and_applications",
  STATEMENT: "statements",
  VERIFICATION: "verifications",
  SECONDARY_MARKET_CLOSING: "secondary_market_closings",
  TERTIARY_MARKET_CLOSING: "tertiary_market_closings",
  HIPAA_AUTHORIZATION: "hipaa_authorizations",
  LIFE_EXPECTANCY: "life_expectancies",
  MEDICAL_RECORD: "medical_records",
  INSURED_DILIGENCE: "insured_diligences",
  ENTITY_DILIGENCE: "entity_diligences",
  PERSON_DILIGENCE: "person_diligences",
  SIGNER_DILIGENCE: "signer_diligences",
};

export const POLICY_AUTH_HANDLING_CHOICES = [
  { value: 0, label: "Unknown" },
  { value: 1, label: "Accepts LifeRoc Forms" },
  { value: 2, label: "Only Accepts Carrier Form" },
  { value: 3, label: "Does Not Accept Any 3rd Party Authorization" },
  { value: 4, label: "Other" },
];
export let POLICY_AUTH_HANDLING_CHOICES_MAP = {};
for (const p of POLICY_AUTH_HANDLING_CHOICES) {
  POLICY_AUTH_HANDLING_CHOICES_MAP[p.value] = p.label;
}

export const IRREVOCABLE_BENEFICIARY_HANDLING_CHOICES = [
  { value: 0, label: "Unknown" },
  { value: 1, label: "Cannot Tier IB DB in System" },
  { value: 2, label: "Cannot Expire IB DB In System" },
  { value: 3, label: "Can Tier IB DB In System" },
  { value: 4, label: "Can Expire IB DB In System" },
  { value: 5, label: "Will not accept Letter of Instruction For Tiers/Expiration" },
  {
    value: 6,
    label: "Will accept Letter of Instruction For Tiers/Expiration to put on file",
  },
];

export const POS_SUPPLEMENTARY_CONTACT_CHOICES = [
  { value: 0, label: "Unknown" },
  { value: 1, label: "Yes - Use Carrier Form" },
  { value: 2, label: "Yes - Use Letter of Instruction" },
  { value: 3, label: "No" },
  { value: 4, label: "Other" },
];

export const PRODUCT_TYPES = {
  UL: "Universal Life",
  VUL: "Variable Universal Life",
  IUL: "Index Universal Life",
  WL: "Whole Life",
  TC: "Term - Convertible",
  TNC: "Term - Non Convertible",
  GUL: "Guaranteed Universal Life",
  GL: "Group Life",
};

export const POLICY_TYPES = {
  I: "Individual",
  G: "Group",
};

export const REVENUE_MODEL_CHOICES = [
  { label: "Direct Placement", value: "DIRECT" },
  { label: "Trading - Pre-Booked", value: "TRADING" },
  { label: "Trading - Blind", value: "TRADING_BLIND" },
];

// advisorhq.quotes.models.AgencyUserRelation.AgencyUserRole

export const PERMISSIONS = {
  SALES_REPRESENTATIVE: "Sales Representative",
  ADMINISTRATOR: "Administrator",
  AUCTION_MANAGER: "Auction Manager",
  CASE_MANAGER: "Case Manager",
  CLOSING_MANAGER: "Closing Manager",
  PRICING_MANAGER: "Pricing Manager",
};

export const marketRoleChoices = makeChoices([
  "Direct Funder",
  "Provider",
  "Fund Advisor",
  "Family Office",
  "Fund of Funds",
  "Tertiary Broker",
]);
export const buyingStatusChoices = makeChoices([
  "Active",
  "Fund Formation",
  "Intermittent",
  "Not Active",
  "On Hold",
  "Unknown",
]);
export const pricingSystemChoices = makeChoices(["ClariNet", "Internal", "MAPS"]);
export const survivalCurveChoices = makeChoices(["Internal Curve", "LE Paper Curve", "VBT Curve"]);
export const acquisitionMarketChoices = makeChoices(["Both", "Secondary", "Tertiary"]);
export const inforcePeriodChoices = makeChoices(["24+", "36+", "60+"]);
export const policyMaturityChoices = makeChoices(["None", "95", "100", "101+", "105"]);
export const premiumFinanceBuyerChoices = makeChoices(["No", "Yes - Case by Case"]);
export const rdbChoices = makeChoices(["Yes - All", "Yes - Case by Case", "Yes - No CA or NY", "No"]);
export const sulChoices = makeChoices(["Yes", "Case by Case", "No"]);
export const vulChoices = makeChoices(["Yes", "Case by Case", "Portfolio Only", "No"]);
export const loanPolicyChoices = makeChoices(["Yes", "Yes - Prefer Not", "No"]);
export const caseStageChoices = makeChoices(["Opportunity", "In Closing", "Price Discovery", "Inactive", "Asset"]);
export const yesNoNotSelectedChoices = [
  { value: 1, label: "Yes" },
  { value: 2, label: "No" },
  { value: 3, label: "Past" },
  { value: 99, label: "Not Selected" },
];
export const affiliationStatusChoices = [
  { value: 1, label: "Active" },
  { value: 2, label: "Inactive" },
];

// BIDDING_FORMAT_CHOICES
export const biddingFormatChoices = [
  { value: "BROKER AUCTION", label: "Broker Auction" },
  { value: "LPEX", label: "LPEX" },
  { value: "TRADITIONAL", label: "Traditional" },
  { value: "TBD", label: "TBD" },
];

// STATUS_CHOICES_CASE_TABLE
export const STATUS_CASE_TABLE_PENDING = 1;
export const STATUS_CASE_TABLE_OUTSTANDING = 2;
export const STATUS_CASE_TABLE_RECEIVED_NGO = 10;
export const STATUS_CASE_TABLE_RECEIVED_PENDING_REVIEW = 11;
export const STATUS_CASE_TABLE_NOT_YET_REQUESTED = 20;
export const STATUS_CASE_TABLE_NOT_REQUIRED = 30;
export const STATUS_CASE_TABLE_RECEIVED_IGO = 31;
export const STATUS_CASE_TABLE_WAIVED = 32;

export const caseDocumentStatusChoices = [
  { value: STATUS_CASE_TABLE_NOT_REQUIRED, label: "Not Required" },
  { value: STATUS_CASE_TABLE_NOT_YET_REQUESTED, label: "Not Yet Requested" },
  { value: STATUS_CASE_TABLE_OUTSTANDING, label: "Outstanding" },
  { value: STATUS_CASE_TABLE_PENDING, label: "Pending" },
  { value: STATUS_CASE_TABLE_RECEIVED_IGO, label: "Received - IGO" },
  { value: STATUS_CASE_TABLE_RECEIVED_NGO, label: "Received - NGO" },
  {
    value: STATUS_CASE_TABLE_RECEIVED_PENDING_REVIEW,
    label: "Received - Pending Review",
  },
  { value: STATUS_CASE_TABLE_WAIVED, label: "Waived" },
];

// Funder.REVENUE_MODEL_CHOICES
export const REVENUE_MODEL_DIRECT = "DIRECT";
export const REVENUE_MODEL_TRADING = "TRADING"; // AKA Trading - Pre-Booked
export const REVENUE_MODEL_TRADING_BLIND = "TRADING_BLIND";
export const REVENUE_MODEL_TBD = "TBD";

export const caseRevenueModelTypeChoices = [
  { value: REVENUE_MODEL_DIRECT, label: "Direct Placement" },
  { value: REVENUE_MODEL_TRADING, label: "Trading - Pre-Booked" },
  { value: REVENUE_MODEL_TRADING_BLIND, label: "Trading - Blind" },
  { value: REVENUE_MODEL_TBD, label: "TBD" },
];

// LifeSettlementCase.Status.choices
export const caseStatusChoices = [
  { value: "P-A", label: "Auction" },
  { value: "P-AFR", label: "Await Funder DD Review" },
  { value: "P-AOA", label: "Await Offer Acceptance" },
  { value: "IC-AOP", label: "Await Owner Payment" },
  { value: "IC-ARE", label: "Await Rescission Expiration" },
  { value: "CA", label: "Bought - Asset" },
  { value: "SA", label: "Bought - Lapsed" },
  { value: "LA", label: "Bought - Matured" },
  { value: "MA", label: "Bought - Sold" },
  { value: "I-RCNC", label: "Can Not Compete" },
  { value: "I-TCNI", label: "Client Not Interested" },
  { value: "I-ICOBO", label: "Closed by Provider" },
  { value: "CBS", label: "Closed by Seller" },
  { value: "IC-ACDR", label: "Contract Diligence" },
  { value: "IC-ACoCo", label: "Contract Signing" },
  { value: "I-ICCNR", label: "Contracts Not Returned" },
  { value: "F", label: "Direct Placed - Asset" },
  { value: "DPL", label: "Direct Placed - Lapsed" },
  { value: "DPM", label: "Direct Placed - Matured" },
  { value: "I-TDNP", label: "Does Not Price" },
  { value: "P-FB", label: "File Building" },
  { value: "I-TI", label: "Incomplete" },
  { value: "T-NITR", label: "Need Information to Proceed" },
  { value: "I-RNI", label: "No Funder Offers" },
  { value: "IC-ACG", label: "Offer Accepted" },
  { value: "I-OD", label: "Offer Declined" },
  { value: "OH", label: "On Hold" },
  { value: "I-ID", label: "Passed Away In Process" },
  { value: "T-VRF", label: "Pending" },
  { value: "PL", label: "Policy Lapsed" },
  { value: "PFA", label: "Preparing for Auction" },
  { value: "T-PR", label: "Pricing Review" },
  { value: "I-ICOR", label: "Rescinded by Owner" },
  { value: "I-ICPR", label: "Rescinded by Provider" },
  { value: "I-RS", label: "State Rejected" },
  { value: "IC-ACaCh", label: "Title Changes In Process" },
  { value: "P-U", label: "Underwriting Review" },
];

// LifeSettlementCase.STAGES
export const stageChoices = makeChoices(["Opportunity", "In Closing", "Price Discovery", "Inactive", "Asset"]);

export const activeStageChoices = makeChoices(["Opportunity", "In Closing", "Price Discovery"]);

export const tasksStageChoices = makeChoices(["Opportunity", "In Closing", "Price Discovery", "Asset"]);

export const tasksStatusChoices = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Completed" },
  { value: 3, label: "Waived" },
];

// OwnerEntityInformation.ENTITY_TYPE_CHOICES
export const entityTypeChoices = [
  { value: "DAC", label: "Designated Activity Company (DAC)" },
  { value: "GP", label: "General Partnership (GP)" },
  { value: "INC", label: "Corporation (Inc)" },
  { value: "IT", label: "Irrevocable Trust" },
  { value: "LLC", label: "Limited Liability Company (LLC)" },
  { value: "LLP", label: "Limited Liability Partnership (LLP)" },
  { value: "LP", label: "Limited Partnership (LP)" },
  { value: "LTD", label: "Limited (LTD)" },
  { value: "PL", label: "Professional Limited Liability Company (PL)" },
  { value: "RT", label: "Revocable Trust" },
  { value: "OTHER", label: "Other" },
];

// MARITAL_STATUS_CHOICES
export const MARITAL_STATUS_SINGLE = "SI";
export const MARITAL_STATUS_MARRIED = "MA";
export const MARITAL_STATUS_WIDOWED = "WI";
export const MARITAL_STATUS_DIVORCED = "DI";
export const MARITAL_STATUS_SEPARATED = "SE";
export const MARITAL_STATUS_REGISTERED_RELATIONSHIP = "SE";
export const maritalStatusChoices = [
  { value: MARITAL_STATUS_SINGLE, label: "Single" },
  { value: MARITAL_STATUS_MARRIED, label: "Married" },
  { value: MARITAL_STATUS_WIDOWED, label: "Widowed" },
  { value: MARITAL_STATUS_DIVORCED, label: "Divorced" },
  { value: MARITAL_STATUS_SEPARATED, label: "Separated" },
  { value: MARITAL_STATUS_REGISTERED_RELATIONSHIP, label: "Registered relationship" },
];

// INSURED_STATUS_CHOICES
export const INSURED_STATUS_ALIVE = "A";
export const INSURED_STATUS_DECEASED = "D";
export const insuredStatusChoices = [
  { value: INSURED_STATUS_ALIVE, label: "Alive" },
  { value: INSURED_STATUS_DECEASED, label: "Deceased" },
];

// MARKET_TYPE_CHOICES
export const SECONDARY = "SECONDARY";
export const TERTIARY = "TERTIARY";
export const marketTypeChoices = [
  { value: SECONDARY, label: "Secondary" },
  { value: TERTIARY, label: "Tertiary" },
];

// Policy.ProductType
export const UNIVERSAL_LIFE = "UL";
export const VARIABLE_UNIVERSAL_LIFE = "VUL";
export const INDEX_UNIVERSAL_LIFE = "IUL";
export const WHOLE_LIFE = "WL";
export const TERM_CONVERTIBLE = "TC";
export const TERM_NON_CONVERTIBLE = "TNC";
export const GUARANTEED_UNIVERSAL_LIFE = "GUL";
export const GROUP_LIFE = "GL";
export const UNKNOWN = "UNK";
export const productTypeChoices = [
  { value: GUARANTEED_UNIVERSAL_LIFE, label: "Guaranteed Universal Life" },
  { value: INDEX_UNIVERSAL_LIFE, label: "Index Universal Life" },
  { value: TERM_CONVERTIBLE, label: "Term - Convertible" },
  { value: TERM_NON_CONVERTIBLE, label: "Term - Non Convertible" },
  { value: UNIVERSAL_LIFE, label: "Universal Life" },
  { value: VARIABLE_UNIVERSAL_LIFE, label: "Variable Universal Life" },
  { value: WHOLE_LIFE, label: "Whole Life" },
  { value: GROUP_LIFE, label: "Group Life" },
  { value: UNKNOWN, label: "Unknown" },
];

// Policy.PolicyType
export const INDIVIDUAL = "I";
export const GROUP = "G";
export const policyTypeChoices = [
  { value: GROUP, label: "Group" },
  { value: INDIVIDUAL, label: "Individual" },
];

// Policy.RateClass
export const rateClassChoices = [
  { value: "SUPER PREFERRED", label: "Super Preferred" },
  { value: "PREFERRED PLUS", label: "Preferred Plus" },
  { value: "PREFERRED", label: "Preferred" },
  { value: "STANDARD PLUS", label: "Standard Plus" },
  { value: "STANDARD", label: "Standard" },
  { value: "UNINSURABLE", label: "Uninsurable" },
];

// Policy.TableRate
export const tableRateChoices = [
  { value: "A", label: "1 / A / 125%" },
  { value: "B", label: "2 / B / 150%" },
  { value: "C", label: "3 / C / 175%" },
  { value: "D", label: "4 / D / 200%" },
  { value: "E", label: "5 / E / 225%" },
  { value: "F", label: "6 / F / 250%" },
  { value: "G", label: "7 / G / 275%" },
  { value: "H", label: "8 / H / 300%" },
  { value: "I", label: "9 / I / 325%" },
  { value: "J", label: "10 / J / 350%" },
  { value: "K", label: "11 / K / 375%" },
  { value: "L", label: "12 / L / 400%" },
  { value: "M", label: "13 / M / 425%" },
  { value: "N", label: "14 / N / 450%" },
  { value: "O", label: "15 / O / 475%" },
  { value: "P", label: "16 / P / 500%" },
  { value: "Q", label: "17 / Q / 525%" },
  { value: "R", label: "18 / R / 550%" },
  { value: "S", label: "19 / S / 575%" },
  { value: "T", label: "20 / T / 600%" },
  { value: "U", label: "21 / U / 625%" },
  { value: "V", label: "22 / V / 650%" },
  { value: "W", label: "23 / W / 675%" },
  { value: "X", label: "24 / X / 700%" },
  { value: "Y", label: "25 / Y / 725%" },
  { value: "Z", label: "26 / Z / 750%" },
  { value: "AA", label: "27 / AA / 775%" },
  { value: "AB", label: "28 / AB / 800%" },
  { value: "AC", label: "29 / AC / 825%" },
  { value: "AD", label: "30 / AD / 850%" },
  { value: "AE", label: "31 / AE / 875%" },
  { value: "AF", label: "32 / AF / 900%" },
  { value: "AG", label: "33 / AG / 925%" },
  { value: "AH", label: "34 / AH / 950%" },
  { value: "AI", label: "35 / AI / 975%" },
  { value: "AJ", label: "36 / AJ / 1000%" },
  { value: "AK", label: "37 / AK / 1025%" },
  { value: "AL", label: "38 / AL / 1050%" },
  { value: "AM", label: "39 / AM / 1075%" },
  { value: "AN", label: "40 / AN / 1100%" },
];

// Policy.DebtBenefit
export const debtBenefitChoices = [
  { value: "L", label: "Level" },
  { value: "I", label: "Increasing" },
  { value: "R", label: "Return of Premium" },
];

// UNDERWRITING_TYPE_CHOICES
export const underwritingTypeChoices = [
  { value: "C", label: "Clinical" },
  { value: "NC", label: "Non-Clinical" },
  { value: "V", label: "Verbal" },
];

export const statusChoicesDocumentStatus = [
  { value: 30, label: "Not Required" },
  { value: 20, label: "Not Yet Requested" },
  { value: 2, label: "Outstanding" },
  { value: 1, label: "Pending" },
  { value: 31, label: "Received - IGO" },
  { value: 10, label: "Received - NGO" },
  { value: 11, label: "Received - Pending Review" },
  { value: 32, label: "Waived" },
];

export const requiredOfChoices = [
  { value: "BROKER", label: "Broker" },
  { value: "CARRIER", label: "Carrier" },
  { value: "MANAGER_CASE", label: "Case Manager" },
  { value: "MANAGER_CLOSING", label: "Closing Manager" },
  { value: "ESCROW", label: "Escrow Agent" },
  { value: "FUNDER", label: "Funder" },
  { value: "INSURED", label: "Insured" },
  { value: "OWNER", label: "Owner" },
  { value: "MANAGER_PRICING", label: "Pricing Manager" },
  { value: "PROVIDER", label: "Provider" },
  { value: "FACILITATOR", label: "Source" },
  { value: "MANAGER_TRADING", label: "Trading Manager" },
  { value: "SERVICER", label: "Servicer" },
];

export const vendorChoices = [
  { value: "21st SERVICES", label: "21st" },
  { value: "AVS", label: "AVS" },
  { value: "CLARITY", label: "Clarity" },
  { value: "CONVERGENCE", label: "Convergence" },
  { value: "FASANO", label: "Fasano" },
  { value: "FOCUS", label: "Focus" },
  { value: "ISC", label: "ISC" },
  { value: "LAPETUS", label: "Lapetus" },
  { value: "LIFEROC", label: "LifeRoc" },
  { value: "LSI", label: "LSI" },
  { value: "POLARIS", label: "Polaris" },
  { value: "PREDICTIVE", label: "Predictive" },
];

export const certificateTypeVendorChoices = {
  FASANO: [
    { value: "SIMPLIFIED", label: "Simplified/Accelerated" },
    { value: "SECONDARY", label: "Secondary" },
    { value: "TERTIARY", label: "Tertiary" },
  ],
  "21st SERVICES": [
    { value: "SIMPLIFIED", label: "Simplified/Accelerated" },
    { value: "STANDARD", label: "Standard" },
    { value: "TERTIARY", label: "Tertiary" },
    { value: "CLINICAL", label: "Clinical" },
  ],
  LSI: [
    { value: "STANDARD", label: "Standard" },
    { value: "CLINICAL", label: "Clinical" },
  ],
  ISC: [
    { value: "STANDARD", label: "Standard" },
    { value: "CLINICAL", label: "Clinical" },
    { value: "SECONDARY", label: "Secondary" },
  ],
};

export const documentTypeChoices = [
  { value: "3RD DATABASE CHECK", label: "3rd Database Check" },
  { value: "BANKRUPTCY DOCUMENTATION", label: "Bankruptcy Documentation" },
  { value: "DIVORCE DOCUMENTATION", label: "Divorce Documentation" },
  { value: "IRS W-9", label: "IRS W-9" },
  { value: "MISC DOCUMENTATION", label: "Misc Documentation" },
  { value: "PHOTO ID", label: "Photo Id" },
  { value: "PHOTO ID AND SSN", label: "Photo ID & SSN Verification" },
  { value: "POA DOCUMENTATION", label: "POA Documentation" },
  { value: "SSN VERIFICATION", label: "SSN Verification" },
  { value: "UCC CHECK", label: "UCC Check" },
];

export const activityLevelChoices = [
  { value: "N", label: "N" },
  { value: "A", label: "A" },
  { value: "S", label: "S" },
];

export const geneticInfluenceChoices = [
  { value: "Contributes", label: "Contributes" },
  { value: "Unknown", label: "Unknown" },
  { value: "Unremarkable", label: "Unremarkable" },
];

export const spreadTypeChoices = [
  { value: "FS", label: "Fix Spread" },
  { value: "P", label: "Percentage" },
];

export const activityTypeChoices = [
  { value: "B", label: "Bid" },
  { value: "BC", label: "Bid Contingent" },
  { value: "BR", label: "Bid Rescinded" },
  { value: "CN", label: "Cannot Compete With High Offer" },
  { value: "D", label: "Declined" },
  { value: "ENI", label: "Expressed No Interest" },
  { value: "F", label: "Follow Up" },
  { value: "FPC", label: "Follow Up - Phone Call" },
  { value: "FVM", label: "Follow Up - Voice Mail" },
  { value: "FLE", label: "Follow Up - LRA Email" },
  { value: "FDE", label: "Follow Up - Direct Email" },
  { value: "IC", label: "In Conflict" },
  { value: "PR", label: "Pricing Pending" },
  { value: "RI", label: "Requested Information" },
  { value: "S", label: "Submitted" },
  { value: "UP", label: "Underwriting Pending" },
];

export const premiumPaymentCategoryChoices = [
  { value: "NO", label: "No" },
  { value: "PPA", label: "Premium Payment Advance" },
  { value: "PPR", label: "Premium Payment Reimbursement" },
];

export const FUNDER_STATUS_MAPPING = {
  B: "Bidding",
  P: "Pending",
  I: "Inactive",
  LB: "Losing Bid",
  LC: "Lost Case",
  WP: "Winning Pending",
  WC: "Winning in Closing",
  W: "Won",
  D: "Declined",
  NS: "Not Sent",
};

// Lead.Status.choices
export const leadStatusChoices = [
  { value: 100, label: "Not Yet Contacted" },
  { value: 110, label: "Chasing" },
  { value: 120, label: "Not Qualified" },
  { value: 130, label: "Await Application" },
  { value: 140, label: "Committed to Competition" },
  { value: 150, label: "In Dialogue" },
  { value: 160, label: "Unable to Connect" },
  { value: 170, label: "Life Settlement Application Submitted" },
  { value: 180, label: "Life Application Submitted" },
  { value: 190, label: "Bad Lead" },
];

// Lead.STAGES
export const leadStageChoices = makeChoices(["Open", "Closed", "Converted"]);
