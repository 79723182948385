import React from "react";
import { connect } from "react-redux";
import { useTable, useSortBy, usePagination } from "react-table";
import { Table as BootstrapTable } from "react-bootstrap";

import Pagination from "./Pagination";
import DefaultPagination from "./DefaultPagination";
import Body from "./Body";
import Header from "./Header";

import {
  loadingCasesSelector,
  sortBySelector,
  initialPageResultsSelector,
  activeTabSelector,
  caseCountSelector,
  leCountSelector,
  recordsCountSelector,
  accountsCountSelector,
  loadingLeSelector,
  loadingRecordsSelector,
  loadingAccountsSelector,
  leadsCountSelector,
  parametersCountSelector,
} from "reducers";
import HeaderDefaultSort from "./HeaderDefaultSort";
import { compareIgnoreCase } from "./utils";
import { loadingParametersSelector } from "../../reducers/life_settlement/loading";
import Footer from "./Footer";

const Table = ({
  columns,
  data,
  paginated = true,
  emptyMessage = "No data",
  rowIsActive = row => false,
  initialPageSize,
  sortBy,
  resultsCount,
  initialPageResults,
  isLoading,
  sortByValue,
  defaultSort = false,
  defaultPagination = false,
  showPaginationResults = true,
  tableClass = "",
  showFooter = false,
}) => {
  const tableProps = {
    columns,
    data,
    emptyMessage,
    rowIsActive,
    initialPageSize,
    sortBy,
    resultsCount,
    initialPageResults,
    isLoading,
    sortByValue,
    defaultSort,
    defaultPagination,
    showPaginationResults,
    tableClass,
    showFooter,
  };

  if (paginated) {
    return <TablePaginated {...tableProps} />;
  }

  return <TableNotPaginated {...tableProps} />;
};

const TablePaginated = ({
  columns,
  data,
  emptyMessage,
  rowIsActive,
  initialPageSize = 100,
  resultsCount,
  initialPageResults,
  sortByValue, // Redux selector
  sortBy, // Prop from parent
  defaultSort,
  defaultPagination,
  showPaginationResults,
  tableClass,
}) => {
  let initialSortBy;
  if (sortBy) {
    initialSortBy = sortBy;
  } else {
    initialSortBy = React.useMemo(() => [{ id: sortByValue.id, desc: sortByValue.desc }], [sortByValue]);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { pageIndex, pageSize },
    pageCount,
    page,
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: initialPageSize, sortBy: initialSortBy },
      sortTypes: {
        alphanumeric: (row1, row2, columnName) => {
          return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
        },
      },
    },
    useSortBy,
    usePagination,
  );

  const renderResults = () => {
    if (showPaginationResults && resultsCount) {
      return <p>showing results {initialResultDisplayed(data)}</p>;
    }
  };

  const initialResultDisplayed = data => {
    return (
      parseInt(initialPageResults + 1) + " - " + parseInt(initialPageResults + data.length) + " of " + resultsCount
    );
  };

  let PaginationComponent = Pagination;
  if (defaultPagination) {
    PaginationComponent = DefaultPagination;
  }

  let className = "shadowed";
  if (tableClass) {
    className += " " + tableClass;
  }
  return (
    <>
      <div className="table-container" style={{ width: "100%" }}>
        <BootstrapTable {...getTableProps({ className: className })}>
          {defaultSort ? <HeaderDefaultSort headerGroups={headerGroups} /> : <Header headerGroups={headerGroups} />}
          <Body
            rows={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            rowIsActive={rowIsActive}
            emptyMessage={emptyMessage}
          />
        </BootstrapTable>
      </div>
      <PaginationComponent
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        setPageSize={setPageSize}
        gotoPage={gotoPage}
        pageSize={pageSize}
        previousPage={previousPage}
        nextPage={nextPage}
        showPaginationResults={showPaginationResults}
      />
      {renderResults()}
    </>
  );
};

const TableNotPaginated = ({
  columns,
  data,
  emptyMessage,
  rowIsActive,
  defaultSort,
  defaultPagination,
  sortBy,
  tableClass = "",
  showFooter = false,
}) => {
  let useTableData = { columns, data };
  if (sortBy) {
    useTableData.initialState = { sortBy };
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups } = useTable(
    useTableData,
    useSortBy,
  );

  let className = "shadowed";
  if (tableClass) {
    className += " " + tableClass;
  }

  return (
    <div className="table-container">
      <BootstrapTable {...getTableProps({ className: className })}>
        {defaultSort ? <HeaderDefaultSort headerGroups={headerGroups} /> : <Header headerGroups={headerGroups} />}
        <Body
          rows={rows}
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          rowIsActive={rowIsActive}
          emptyMessage={emptyMessage}
        />
        {showFooter ? <Footer footerGroups={footerGroups} /> : null}
      </BootstrapTable>
    </div>
  );
};

const mapStateToProps = state => {
  const tab = activeTabSelector(state);
  // TODO: CHOOSE COUNT/LOADING SELECTOR FROM ENDPOINTS_TO_INITIAL_PAGE_RESULTS_SELECTOR
  let resultsCount = 0;
  let loading = false;
  if (tab === "lifeexpectancy") {
    resultsCount = leCountSelector(state);
    loading = loadingLeSelector(state);
  } else if (tab === "cases") {
    resultsCount = caseCountSelector(state);
    loading = loadingCasesSelector(state);
  } else if (tab === "tasks") {
    resultsCount = recordsCountSelector(state);
    loading = loadingRecordsSelector(state);
  } else if (tab === "accounts") {
    resultsCount = accountsCountSelector(state);
    loading = loadingAccountsSelector(state);
  } else if (tab === "leads") {
    resultsCount = leadsCountSelector(state);
    loading = false;
  } else if (tab === "parameters") {
    resultsCount = parametersCountSelector(state);
    loading = loadingParametersSelector(state);
  }
  return {
    isLoading: loading,
    sortByValue: sortBySelector(state),
    resultsCount: resultsCount,
    // TODO: CHOOSE initialPageResults FROM ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING
    initialPageResults: initialPageResultsSelector(state),
  };
};

export default connect(mapStateToProps, {})(Table);
