import React from "react";

import { ProgressBar, SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { highlightPlugin } from "@react-pdf-viewer/highlight";
import { searchPlugin } from "@react-pdf-viewer/search";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { bookmarkPlugin } from "@react-pdf-viewer/bookmark";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

const PDFViewer = ({ fileUrl }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const highlightPluginInstance = highlightPlugin();
  const searchPluginInstance = searchPlugin();
  const zoomPluginInstance = zoomPlugin();
  const bookmarkPluginInstance = bookmarkPlugin();

  const handleDocumentLoad = e => {
    const { activateTab } = defaultLayoutPluginInstance;
    activateTab(1);
  };

  return (
    <div style={{ height: "77vh", overflow: "auto" }}>
      <Viewer
        fileUrl={fileUrl}
        plugins={[
          defaultLayoutPluginInstance,
          highlightPluginInstance,
          searchPluginInstance,
          zoomPluginInstance,
          bookmarkPluginInstance,
        ]}
        onDocumentLoad={handleDocumentLoad}
        defaultScale={SpecialZoomLevel.PageFit}
        renderLoader={percentages => (
          <div style={{ width: "240px" }}>
            <ProgressBar progress={Math.round(percentages)} />
          </div>
        )}
      />
    </div>
  );
};

export default PDFViewer;
