import React from "react";
import { connect } from "react-redux";
import { FaEdit } from "react-icons/fa";

import {
  clearActiveCaseFile,
  editCaseWithoutDispatch,
  fetchCaseFilesByCaseDocumentId,
  selectAccount,
  selectAccountTask,
  selectActiveRecordId,
  selectCaseTask,
  selectLead,
  selectLeadTask,
  showModal,
} from "actions";
import { loadingRecordsSelector, recordsSelector } from "reducers";
import IconLink from "components/IconLink";
import Table, { dateCell, datetimeCell, shortNotesCell } from "components/table";
import { ACCOUNT_TASK_MODAL, CASE_FILE_MODAL, CASE_TASK_MODAL, LEAD_TASK_MODAL } from "components/modals";
import { TaskTab } from "./constants";

const RecordsTable = ({ type, records, showModal, recordsLoading, ...props }) => {
  const columns = [
    {
      Header: "Action",
      Cell: ({ row }) => {
        const cd = row.original.case_document;
        const objType = row.original.type;
        if (objType === 1) {
          return (
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.clearActiveCaseFile();
                props.selectActiveRecordId(row.original.id);
                props.fetchCaseFilesByCaseDocumentId(cd);
                showModal(CASE_FILE_MODAL);
              }}
            />
          );
        } else if (objType === 2) {
          return (
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                const record = row.original;
                const task = row.original.task_obj;
                props.selectAccount(task.account);
                props.selectActiveRecordId(row.original.id);
                props.selectAccountTask({
                  ...task,
                  created_by: record.record_creator,
                  last_edited_by_name: record.last_editor,
                  last_edit_date: record.last_edit_date,
                  date_created: record.date_created,
                });
                showModal(ACCOUNT_TASK_MODAL);
              }}
            />
          );
        } else if (objType === 3) {
          return (
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                const record = row.original;
                const task = row.original.task_obj;
                props.selectActiveRecordId(row.original.id);
                console.log("set lead id", row.original);
                props.selectLead(row.original.task_obj?.lead_id);
                props.selectLeadTask({
                  ...task,
                  created_by: record.record_creator,
                  last_edited_by_name: record.last_editor,
                  last_edit_date: record.last_edit_date,
                  date_created: record.date_created,
                });
                showModal(LEAD_TASK_MODAL);
              }}
            />
          );
        } else if (objType === 4) {
          return (
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectActiveRecordId(row.original.id);
                props.selectCaseTask(row.original);
                showModal(CASE_TASK_MODAL);
              }}
            />
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Record ID",
      accessor: "record_id",
      className: "wrap-text",
      Cell: ({ row }) => {
        const objType = row.original.type;
        const caseId = row.original.case_id;
        const accountId = row.original.task_obj && row.original.task_obj.account;
        if (objType === 1 || objType === 4) {
          let redirectUrl = "files";
          if (objType === 4) redirectUrl = "tasks";
          return (
            <div className="text-wrap">
              <a href={`/cases/${caseId}/${redirectUrl}/`} target="_blank" rel="noreferrer">
                {row.original.record_id}
              </a>
            </div>
          );
        } else if (objType === 2) {
          return (
            <div className="text-wrap">
              <a href={`/accounts/${accountId}/information/`} target="_blank" rel="noreferrer">
                {row.original.record_id}
              </a>
            </div>
          );
        } else if (objType === 3) {
          return (
            <div className="text-wrap">
              <a href={`/leads/${row.original.task_obj?.lead_id}/overview/`} target="_blank" rel="noreferrer">
                {row.original.record_id}
              </a>
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Record Name",
      accessor: "record_name",
      className: "wrap-text",
    },
  ];

  if (type === TaskTab.CASE || type === TaskTab.CASE_REQUIREMENT) {
    columns.push({
      Header: "Carrier Name",
      accessor: "carrier_name",
      className: "wrap-text",
    });
  }

  columns.push({
    Header: "Task Type",
    accessor: "task_type",
    className: "wrap-text",
  });
  columns.push({
    Header: "Task Notes",
    accessor: "notes",
    Cell: shortNotesCell,
  });
  columns.push({
    Header: "Task Owner",
    accessor: "record_owner",
    className: "wrap-text",
  });

  if (type === TaskTab.ACCOUNT) {
    columns.push({
      Header: "Work Phone",
      accessor: "work_phone",
    });
    columns.push({
      Header: "Cell Phone",
      accessor: "cell_phone",
    });
    columns.push({
      Header: "Other Phone",
      accessor: "other_phone",
    });
    columns.push({
      Header: "Work Email",
      accessor: "work_email",
    });
    columns.push({
      Header: "Other Email",
      accessor: "other_email",
    });
  } else if (type === TaskTab.LEAD) {
    columns.push({
      Header: "Phone Number",
      accessor: "phone_number",
    });
    columns.push({
      Header: "Other Phone",
      accessor: "other_phone",
    });
    columns.push({
      Header: "Email",
      accessor: "email",
    });
    columns.push({
      Header: "Other Email",
      accessor: "other_email",
    });
  }

  columns.push({
    Header: "Create Date",
    accessor: "date_created",
    Cell: dateCell,
  });
  columns.push({
    Header: "Last Edit Date",
    accessor: "last_edit_date",
    Cell: datetimeCell,
  });
  columns.push({
    Header: "Due Date",
    accessor: "due_date",
    Cell: dateCell,
  });

  if (type === TaskTab.CASE) {
    columns.push({
      Header: "Last Cases Status Sent",
      accessor: "last_sent_case",
      Cell: datetimeCell,
    });
  }

  return (
    <Table
      columns={columns}
      data={records}
      emptyMessage={recordsLoading ? "Loading data..." : "No data"}
      initialPageSize={100}
    />
  );
};

const mapStateToProps = state => {
  const records = recordsSelector(state);

  return {
    records: records,
    recordsLoading: loadingRecordsSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  clearActiveCaseFile,
  fetchCaseFilesByCaseDocumentId,
  editCaseWithoutDispatch,
  selectAccount,
  selectAccountTask,
  selectActiveRecordId,
  selectLeadTask,
  selectCaseTask,
  selectLead,
})(RecordsTable);
