import React from "react";
import { connect } from "react-redux";

import { ButtonCreate } from "components/buttons";
import BeneficiaryForm from "./BeneficiaryForm";
import {
  beneficiariesSelector,
  activeCaseIdSelector,
  numberOfBeneficiariesSelector,
  loadingSelector,
  shouldSaveSelector,
  activeCaseSelector,
} from "reducers";
import Loader from "components/Loader";
import * as notifications from "../../../notifications";
import { fetchBeneficiaries, unsetShouldSave } from "actions";
import { isAuthorized } from "../../../utils";
import { PERMISSIONS } from "../../../constants";

const BeneficiariesPage = ({ beneficiaries, hasAdminPermission, ...props }) => {
  const [beneficiariesList, setBeneficiariesList] = React.useState([]);
  let refs = {
    1: React.useRef(),
    2: React.useRef(),
    3: React.useRef(),
    4: React.useRef(),
    5: React.useRef(),
  };

  React.useEffect(() => {
    if (props.caseInfo.submission_id) {
      document.title = `LifeRoc | ${props.caseInfo.submission_id} | Beneficiaries`;
    }
    if (props.caseInfo.id) {
      props.fetchBeneficiaries(props.caseInfo.id);
    }
  }, [props.caseInfo.id]);

  React.useEffect(() => {
    setBeneficiariesList(beneficiaries);
  }, [beneficiaries.map(b => b.id).toString()]);

  React.useEffect(() => {
    if (props.shouldSave) {
      for (let i = 1; i <= beneficiariesList.length; i++) {
        setTimeout(() => {
          refs[i].current && refs[i].current.click();
        }, i * 500);
      }
      setTimeout(() => {
        unsetShouldSave();
        notifications.success("Beneficiaries saved successfully");
      }, (beneficiariesList.length + 1) * 500);
    }
  }, [props.shouldSave]);

  if (props.loading.error404) {
    return <></>;
  }

  return (
    <>
      <Loader isLoading={props.loading.beneficiaries} text={"Loading Beneficiaries data"} />
      {beneficiariesList.map((beneficiary, idx) => (
        <BeneficiaryForm beneficiary={beneficiary} beneficiaryIdx={idx + 1} submitRef={refs[idx + 1]} />
      ))}
      {hasAdminPermission && (
        <ButtonCreate
          onClick={() => {
            setBeneficiariesList([...beneficiariesList, {}]);
          }}
          style={{ marginBottom: "2rem" }}
          disabled={beneficiariesList.length >= 5}
        >
          {beneficiariesList.length < 5 ? "Add Beneficiary" : "Max beneficiaries reached (5)"}
        </ButtonCreate>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const roles = state.auth.activeAgencyRoles;
  return {
    caseInfo: activeCaseSelector(state),
    loading: loadingSelector(state),
    beneficiaries: beneficiariesSelector(state),
    caseId: activeCaseIdSelector(state),
    numberOfBeneficiaries: numberOfBeneficiariesSelector(state),
    shouldSave: shouldSaveSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  fetchBeneficiaries,
})(BeneficiariesPage);
