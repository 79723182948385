import React from "react";
import { connect, useDispatch } from "react-redux";
import { clearSelectedAccount, fetchAccounts, selectAccount, showModal } from "actions";
import {
  activeAgencySelector,
  accountsSelector,
  loadingAccountsSelector,
  loginInfoSelector,
  selectedAccountsSelector,
} from "reducers";
import Table, { datetimeCell } from "components/table";
import IconLink from "components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DELETE_ACCOUNT_MODAL } from "components/modals";
import { isAuthorized } from "../../utils";
import { PERMISSIONS } from "../../constants";
import { PRACTICE_TYPES_MAP } from "./constants";
import * as types from "../../actions/types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AccountsTable = ({ accounts, accountsLoading, activeAgency, user_id, ...props }) => {
  const dispatch = useDispatch();

  const columns = [
    {
      Header: "Action",
      // this enables to sort by selected items
      accessor: d => {
        return props.selectedAccounts.indexOf(d.id) !== -1 ? "True" : "False";
      },
      Cell: ({ row }) => {
        const data = row.original;
        const caseId = data.id;
        let isDisabled = false;
        let tooltipText = "Select to send Email";
        if (data.email_opt_out || data.other_email_opt_out || data.case_email_opt_out) {
          tooltipText = "Email Opted Out";
          isDisabled = true;
        } else if (!data.work_email) {
          tooltipText = "No Work Email";
          isDisabled = true;
        }

        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <OverlayTrigger key="top" placement="top" overlay={<Tooltip>{tooltipText}</Tooltip>}>
              <input
                type="checkbox"
                style={{
                  "margin-right": "5px",
                  width: "16px",
                  cursor: isDisabled ? "not-allowed" : "pointer",
                }}
                checked={props.selectedAccounts.indexOf(row.original.id) !== -1}
                disabled={isDisabled}
                onClick={e => {
                  if (e.target.checked) {
                    dispatch({
                      type: types.SELECT_ACCOUNT_LIST,
                      payload: { id: row.original.id },
                    });
                  } else {
                    dispatch({
                      type: types.DESELECT_ACCOUNT_LIST,
                      payload: { id: row.original.id },
                    });
                  }
                }}
              />
            </OverlayTrigger>
            {(props.hasAdminPermission || row.original.edit_permission) && (
              <IconLink
                to={`/accounts/${caseId}/information/`}
                Icon={FaEdit}
                iconConfig={{ className: "table--action-icon" }}
                tooltip="View / Edit"
                onClick={() => {
                  props.clearSelectedAccount();
                  window.scrollTo(0, 0);
                }}
              />
            )}
            {props.hasAdminPermission && (
              <IconLink
                Icon={FaTrash}
                iconConfig={{ className: "table--action-icon red" }}
                tooltip="Delete Account"
                onClick={() => props.showModal(DELETE_ACCOUNT_MODAL, { id: caseId })}
              />
            )}
          </div>
        );
      },
    },
    {
      Header: "Account Name",
      accessor: "source_name",
    },
    {
      Header: "Entity Name",
      accessor: "entity_name",
    },
    {
      Header: "Account Type",
      accessor: "type",
      Cell: ({ row }) => {
        const type = row.original.type;
        if (type === 0) {
          return "Individual";
        } else if (type === 1) {
          return "Entity";
        }
      },
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Practice Type",
      accessor: "practice_type",
      Cell: ({ row }) => {
        const type = row.original.practice_type;
        if (type) {
          return PRACTICE_TYPES_MAP[type] || row.original.practice_type;
        } else {
          return "";
        }
      },
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "State",
      accessor: "state",
    },
    {
      Header: "Work Phone",
      accessor: "work_phone",
    },
    {
      Header: "Cell Phone",
      accessor: "cell_phone",
    },
    {
      Header: "Other Phone",
      accessor: "other_phone",
    },
    {
      Header: "Work Email",
      accessor: "work_email",
    },
    {
      Header: "Sales Rep",
      accessor: "sales_rep_name",
    },
    {
      Header: "National Account",
      accessor: "active_national_account",
    },
    {
      Header: "Date Last Modified",
      accessor: "last_edit_date",
      Cell: datetimeCell,
    },
    {
      Header: "Next Task Due Date",
      accessor: "next_task_due_date",
      Cell: datetimeCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={accounts}
      emptyMessage={accountsLoading ? "Loading Accounts data..." : "No data"}
      initialPageSize={100}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const accounts = Object.values(accountsSelector(state)) || [];
  const roles = state.auth.activeAgencyRoles;
  const { user_id } = loginInfoSelector(state);

  return {
    accounts: accounts,
    activeAgency: activeAgencySelector(state),
    accountsLoading: loadingAccountsSelector(state),
    selectedAccounts: selectedAccountsSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
    user_id,
  };
};

export default connect(mapStateToProps, {
  fetchAccounts,
  selectAccount,
  showModal,
  clearSelectedAccount,
})(AccountsTable);
