import React from "react";
import { connect } from "react-redux";

import { createEmptyFunderEntity, fetchFunderEntities, selectFunderEntity } from "../../actions";
import {
  activeFunderEntityIdSelector,
  activeFunderEntitiesSelector,
  activeFunderSelector,
  loadingFunderEntitiesSelector,
} from "../../reducers";
import FunderEntityInfo from "./FunderEntityInfo";
import { ButtonCreate } from "../../components/buttons";
import FunderEntityForm from "./FunderEntityForm";
import { ClipLoader } from "react-spinners";

const FunderEntitiesPage = ({ funderInfo, ...props }) => {
  React.useEffect(() => {
    if (funderInfo && funderInfo.id) {
      props.fetchFunderEntities(funderInfo.id);
    }
  }, [funderInfo]);

  React.useEffect(() => {
    if (funderInfo && funderInfo.name) {
      document.title = `LifeRoc | ${funderInfo.name} | Funder Entities`;
    }
  }, [funderInfo]);

  const renderLoading = () => {
    return (
      <div>
        Loading Funder Entities
        <ClipLoader size={50} css={{ marginLeft: "10px" }} />
      </div>
    );
  };

  const renderFunderEntities = () => {
    return (
      <section id="insured-details" className="scroll-spied">
        {props.funderEntities.map((funderEntity, idx) => {
          return (
            <FunderEntityInfo
              key={funderEntity.id}
              idx={idx + 1}
              info={funderEntity}
              isActive={props.activeFunderEntityId === funderEntity.id}
            />
          );
        })}
      </section>
    );
  };

  return (
    <div style={{ marginTop: 100 }}>
      {props.funderEntitiesLoading ? renderLoading() : null}
      {renderFunderEntities()}
      <ButtonCreate
        onClick={() => {
          props.createEmptyFunderEntity(funderInfo.id);
        }}
        style={{ marginBottom: "2rem" }}
      >
        Add Entity
      </ButtonCreate>
      <FunderEntityForm />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    funderInfo: activeFunderSelector(state),
    funderEntities: activeFunderEntitiesSelector(state),
    activeFunderEntityId: activeFunderEntityIdSelector(state),
    funderEntitiesLoading: loadingFunderEntitiesSelector(state),
  };
};

export default connect(mapStateToProps, {
  fetchFunderEntities,
  selectFunderEntity,
  createEmptyFunderEntity,
})(FunderEntitiesPage);
