import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";

import {
  activeCaseSelector,
  managementChoicesSelector,
  agentsChoicesSelector,
  insuredListSelector,
  lifeExpectanciesSelector,
  auctionActivitiesSelector,
  auctionSelector,
} from "reducers";

import { pricingSetup } from "../../../actions";
import AuctionNotesForm from "./form/AuctionNotesForm";
import CaseTradingSummaryForm from "./form/CaseTradingSummaryForm";

const CaseTradingSummary = ({
  caseInfo,
  insuredInfo,
  lifeExpectancyInfo,
  isActive,
  case_title,
  pricingSetup,
  caseTradingSummaryFormSubmitRef,
  auctionNotesFormSubmitRef,
  ...props
}) => {
  if (!caseInfo) {
    return null;
  }

  return (
    <div className={`section-card shadowed ${isActive && "active"} case-section`} onClick={props.handleClick}>
      <Card>
        <Card.Header>
          <h5 className="section-title">TRADING SUMMARY</h5>
        </Card.Header>
        <Card.Body>
          <CaseTradingSummaryForm submitRef={caseTradingSummaryFormSubmitRef} caseInfo={caseInfo} />
          <AuctionNotesForm submitRef={auctionNotesFormSubmitRef} />
        </Card.Body>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const agentsChoices = agentsChoicesSelector(state);
  const funderAuctionActivities = auctionActivitiesSelector(state).filter(activity => activity.funder);
  const marketAuctionActivities = auctionActivitiesSelector(state).filter(activity => activity.provider);
  return {
    caseInfo: activeCaseSelector(state),
    funderAuctionActivities: funderAuctionActivities,
    marketAuctionActivities: marketAuctionActivities,
    insuredInfo: insuredListSelector(state),
    lifeExpectancyInfo: lifeExpectanciesSelector(state),
    providerChoices: choices.case_providers,
    agencyChoices: choices.agencies,
    salesRepChoices: choices.case_sales_representatives,
    caseManagersChoices: agentsChoices.case_managers,
    accountChoices: choices.accounts,
    auction: auctionSelector(state),
  };
};

export default connect(mapStateToProps, { pricingSetup })(CaseTradingSummary);
