import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import logo from "logo.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { logout, selectAgency, fetchFunders, cleanLifeSettlementSearchData } from "actions";
import { activeAgencySelector, agenciesSelector, loginInfoSelector } from "reducers";
import { PERMISSIONS, URLS } from "../constants";
import { isAuthorized, redirectTo } from "../utils";
import advisorhq from "../apis/advisorhq";

const NavbarLink = ({ url, title, ...props }) => {
  const location = useLocation();
  const split = location.pathname.split("/");
  let isActive = false;
  if (split.length > 1) {
    const firstPart = split[1];
    if (url.startsWith("/riskx")) {
      isActive = firstPart.indexOf("riskx") !== -1;
    } else {
      isActive = firstPart.indexOf(url.replaceAll("/", "")) !== -1;
    }
  }
  let activeClass = isActive ? "navbar-active" : "";
  return (
    <Link className={"nav-link " + activeClass} to={url} props={props}>
      {title}
    </Link>
  );
};

// example taken from https://react-bootstrap.netlify.com/components/navbar/#navbars
const AHQNavbar = ({ activeAgency, agencies, email, cleanLifeSettlementSearchData, roles, ...props }) => {
  const onAgencyChange = async agency => {
    const response = await advisorhq.patch("api/select_agency/", {
      active_agency: agency.id,
    });
    props.selectAgency({
      active_agency: response.data.active_agency,
      active_agency_roles: response.data.active_agency_roles,
    });
  };

  const displayAgencies = () => {
    let displayAgencyName = activeAgency?.name || "Select An Agency";
    let activeAgencyRole = roles.length > 0 ? roles[0] : "";

    if (agencies.length === 1) {
      return (
        <Nav>
          {displayAgencyName} ({activeAgencyRole})
        </Nav>
      );
    }
    return (
      <NavDropdown title={`${displayAgencyName} (${activeAgencyRole})`} id="agency-dropdown">
        {agencies.map(agency => (
          <NavDropdown.Item key={agency.id} onClick={() => onAgencyChange(agency)}>
            {agency.name} ({agency.role})
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    );
  };

  return (
    <Navbar
      style={{ position: "fixed", top: 0, width: "100%", zIndex: 99, height: "4rem" }}
      expand="lg"
      className="shadowed"
    >
      <Navbar.Brand href={URLS.HOME}>
        <img src={logo} alt="advisor hq" width="114" height="33" />
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      {props.isSignedIn && (
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {props.hasAdminPermission && <NavbarLink title={"System Admin"} url={URLS.ADMIN_EMAILS} />}
            <NavbarLink title={"My Tasks"} url={URLS.TASKS} />
            <NavbarLink title={"Accounts"} url={URLS.ACCOUNTS} />
            <NavbarLink title={"Carriers"} url={URLS.CARRIERS} />
            <NavbarLink title={"Cases"} url={URLS.HOME} onClick={() => cleanLifeSettlementSearchData()} />
            {props.hasAdminPermission && <NavbarLink title={"Funders"} url={URLS.FUNDERS} />}
            {props.hasAdminPermission && <NavbarLink title={"Trading"} url={URLS.TRADING} />}
            {props.hasAdminPermission && <NavbarLink title={"LE Tools"} url={URLS.LIFE_EXPECTANCY} />}
            {props.hasAdminPermission && <NavbarLink title={"Product Database"} url={URLS.PRODUCTS} />}
            <NavbarLink title={"Leads"} url={URLS.LEADS} />
            {props.hasAdminPermission && <NavbarLink title={"RiskX"} url={URLS.PARAMETERS} />}
          </Nav>

          {displayAgencies()}

          <NavDropdown title={email} id="account-dropdown">
            <NavDropdown.Item eventKey="4.1" onClick={props.logout}>
              Logout
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="reset-link" onClick={() => redirectTo("/auth/reset-password")}>
              Reset Password
            </NavDropdown.Item>
          </NavDropdown>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};

const mapStateToProps = state => {
  const { isSignedIn, email } = loginInfoSelector(state);
  const roles = state.auth.activeAgencyRoles;
  return {
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
    activeAgency: activeAgencySelector(state),
    agencies: agenciesSelector(state),
    isSignedIn,
    email,
    roles,
  };
};

export default connect(mapStateToProps, {
  logout,
  selectAgency,
  fetchFunders,
  cleanLifeSettlementSearchData,
})(AHQNavbar);
