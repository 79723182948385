import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import {} from "actions";
import { CASE_TASK_MODAL } from "components/modals";
import CaseTaskForm from "./CaseTaskForm";

const CaseTaskModal = ({ ...props }) => {
  return (
    <FullPageModal modalType={CASE_TASK_MODAL}>
      <CaseTaskForm />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {})(CaseTaskModal);
