/* Component to render modals for holding full pages.
 *
 * The difference with the other modals is that it uses "react-modal"
 * which supports modal nesting, and by default it occupies most of the screen. */

import React from "react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";

import { hideModal } from "actions";

export default ({ modalType, children, title, className = "", ...props }) => {
  const dispatch = useDispatch();
  const customStyles = {
    zIndex: 1000,
  };

  const close = () => {
    props.onCloseCallback?.(); // Optional chaining to safely call onCloseCallback if it exists
    dispatch(hideModal(modalType));
  };

  return (
    <Modal
      style={customStyles}
      isOpen
      onRequestClose={event => {
        // const ESCAPE = 27;
        // // disable close on escape key press
        // if (event.type === "keydown" && event.keyCode === ESCAPE) {
        //   return;
        // }
        close();
      }}
      className={`container full-page-modal ${className}`}
      overlayClassName="modal-background"
      shouldCloseOnOverlayClick={true}
      {...props}
    >
      {title ? (
        <>
          <div className="close-btn" onClick={close}>
            <span>X</span>
          </div>
          <h3 className="title" style={{ marginTop: "-5px" }}>
            {title}
          </h3>
        </>
      ) : null}
      <div>{children}</div>
    </Modal>
  );
};
