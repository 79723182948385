import React from "react";
import { connect } from "react-redux";

import { activeAccountSelector } from "reducers";
import { FaArrowLeft } from "react-icons/fa";
import { PERMISSIONS, URLS } from "../../constants";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabGroup } from "../../components/Tabs";
import { setShouldSave, showModal } from "actions";
import { isAuthorized } from "../../utils";

const AccountTopHeader = ({ accountInfo, ...props }) => {
  const { id } = useParams();
  const path = `/accounts/${id}`;
  const history = useNavigate();

  const SaveButton = saveFn => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-save-button"
          onClick={async () => {
            await props.setShouldSave();
          }}
          style={{
            width: "100%",
          }}
        >
          Save Changes
        </button>
      </div>
    );
  };

  const BackToAccountsButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-save-button"
          onClick={() => history(URLS.ACCOUNTS)}
          style={{
            width: "100%",
          }}
        >
          <FaArrowLeft /> Back to Accounts
        </button>
      </div>
    );
  };

  const h3style = {
    width: "100%",
    top: "7.2rem",
    left: 0,
    zIndex: 1,
  };

  let accountName = "";
  if (accountInfo) {
    if (accountInfo.type === 0) {
      if (accountInfo.last_name && accountInfo.first_name) {
        accountName = `${accountInfo.last_name}, ${accountInfo.first_name}`;
      }
    } else if (accountInfo.type === 1) {
      accountName = accountInfo.entity_name;
    }
  }

  React.useEffect(() => {
    if (accountName) {
      document.title = `LifeRoc | ${accountName} | Account`;
    }
  }, [accountName]);

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            backgroundColor: "black",
          }}
        >
          <h5
            className="section-title navbar-title"
            style={{
              color: "white",
              alignItems: "center",
              marginBottom: 0,
              lineHeight: 2,
            }}
          >
            Account: {accountName}
          </h5>
          <div style={{ display: "flex" }}>
            <BackToAccountsButton />
            <SaveButton saveFn={props.saveFn} />
          </div>
        </Card.Header>
      </Card>
      <h3 className={"header tertiary"} style={h3style}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          <Tab link={`${path}/information/`}>Account Information</Tab>
          <Tab link={`${path}/cases/`}>Account Cases</Tab>
          {props.hasAdminPermission && <Tab link={`${path}/licensing/`}>Account Licensing & Contracting</Tab>}
        </TabGroup>
      </h3>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const roles = state.auth.activeAgencyRoles;
  return {
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
    accountInfo: activeAccountSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  setShouldSave,
})(AccountTopHeader);
