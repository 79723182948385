import React from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/all";

/* Create a Collapsible element wrapper that opens/closes when
 * the "trigger" React.Component is clicked.  */
export default ({ title, children, className = "", initiallyHidden = false }) => {
  const contentRef = React.useRef();
  const [hidden, setHidden] = React.useState(initiallyHidden);

  const onClick = e => {
    setHidden(!hidden);
    contentRef.current.classList.toggle("hidden");
  };

  let initClassName = className || "collapsible__content";
  if (initiallyHidden) {
    initClassName += " hidden";
  }

  const ArrowComponent = hidden ? FaArrowUp : FaArrowDown;

  return (
    <div className="collapsible">
      <div className="collapsible__trigger" onClick={onClick}>
        <h5
          className={`form-title ${className}`}
          style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}
        >
          {title}
          <span style={{ marginLeft: "auto" }}>
            <ArrowComponent style={{ marginRight: 5 }} />
          </span>
        </h5>
      </div>
      <div className={initClassName} ref={contentRef}>
        {children}
      </div>
    </div>
  );
};
