import React from "react";
import { connect } from "react-redux";
import { clearSelectedSynonym, fetchSynonymsByParameter, selectSynonym, showModal } from "actions";
import { activeAgencySelector, activeParameterSelector, activeSynonymSelector } from "reducers";
import Table from "components/table";
import IconLink from "components/IconLink";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DELETE_SYNONYM_MODAL, SYNONYM_MODAL } from "components/modals";
import { isAuthorized } from "../../../utils";
import { PERMISSIONS } from "../../../constants";
import { loadingSynonymsSelector } from "../../../reducers/life_settlement/loading";
import { parameterSynonymsSelector } from "../../../reducers/life_settlement/management/synonyms";

const ParameterSynonymsTable = ({ activeParameter, synonymsLoading, activeAgency, parameterSynonyms, ...props }) => {
  if (!activeParameter) {
    // Still didn't fetch data
    return null;
  }

  React.useEffect(() => {
    props.fetchSynonymsByParameter(activeParameter.id);
  }, [props.activeParameterId]);

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        const id = row.original.id;
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectSynonym(id);
                props.showModal(SYNONYM_MODAL);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon btn--red" }}
              tooltip="Delete"
              onClick={() => {
                props.showModal(DELETE_SYNONYM_MODAL, {
                  id: id,
                  parameterSynonym: true,
                });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Synonyms",
      accessor: "name",
    },
    {
      Header: "Firm Matches",
      accessor: "hard_parameters_name",
    },
    {
      Header: "Soft Parameter Matches",
      accessor: "soft_parameters_name",
    },
  ];

  return (
    <Table
      columns={columns}
      data={parameterSynonyms || []}
      emptyMessage={synonymsLoading ? "Loading Synonyms data..." : "Select a Synonym"}
      showPaginationResults={true}
      defaultSort={true}
      paginated={false}
      initialPageSize={100}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const roles = state.auth.activeAgencyRoles;
  const activeParameter = activeParameterSelector(state);
  const activeSynonymId = activeSynonymSelector(state) && activeSynonymSelector(state).id;
  const parameterSynonyms = parameterSynonymsSelector(state);
  return {
    activeParameter,
    activeSynonymId,
    parameterSynonyms,
    activeAgency: activeAgencySelector(state),
    synonymsLoading: loadingSynonymsSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectSynonym,
  clearSelectedSynonym,
  fetchSynonymsByParameter,
})(ParameterSynonymsTable);
