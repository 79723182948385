import React from "react";
import { Form, Col } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import CreatableSelect from "react-select/creatable";

import { getValidationClassName } from "./validation";
import Label from "./Label";
import FieldErrors from "./FieldErrors";

const SelectCreatable = ({ label, options = [], emptyValue = "", md = "", ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  // Search value into possibly nested options (for now we search up to 1 nested level)
  const findOption = value => {
    let flattenedOpts = [];

    options.forEach(opt => {
      if (opt.options) {
        flattenedOpts = [...flattenedOpts, ...opt.options];
      } else {
        flattenedOpts.push(opt);
      }
    });
    return flattenedOpts.find(opt => opt.value === value);
  };

  let initialValue;
  if (field.value !== emptyValue) {
    initialValue = findOption(field.value);
  } else {
    if (props.defaultValue !== undefined) {
      initialValue = findOption(props.defaultValue);
    } else {
      initialValue = emptyValue;
    }
  }

  const defaultOnChange = option => {
    let value;
    if (option === null) {
      value = props.defaultValue !== undefined ? props.defaultValue : emptyValue;
    } else {
      value = option.value;
    }
    setFieldValue(field.name, value);
  };

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      {label && <Label label={label} required={props.isRequired} />}
      {props.buttons ? props.buttons : null}
      <CreatableSelect
        {...props}
        maxMenuHeight={200}
        name={field.name}
        options={options}
        value={initialValue}
        onChange={props.onChange || defaultOnChange}
        className={`select ${getValidationClassName(meta)}`}
        isClearable
        menuPlacement="auto"
        formatCreateLabel={inputValue => inputValue}
        isDisabled={props.disabled || false}
      />
      <FieldErrors meta={meta} />
    </Form.Group>
  );
};

export default SelectCreatable;
