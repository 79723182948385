import React from "react";
import { IconContext } from "react-icons";

const IconButton = ({ Icon, iconConfig = {}, onClick, ...props }) => {
  const style = iconConfig.style || {};
  style.cursor = "pointer";
  iconConfig.style = style;

  return (
    <IconContext.Provider value={iconConfig}>
      <Icon onClick={onClick} {...props} />
    </IconContext.Provider>
  );
};

export default IconButton;
