import React, { useRef } from "react";
import { connect } from "react-redux";

import { setActiveEmailTemplate, sendAccountEmail } from "actions";
import { accountsSelector, emailTemplateSelector, loginInfoSelector, selectedAccountsSelector } from "reducers";
import { EMAIL_TEMPLATE_TYPES } from "../../admin/constants";
import { SendEmailForm } from "components/forms";

const SendAccountEmailForm = ({ ...props }) => {
  const formikRef = useRef();

  const handleToChange = email_source => {
    if (email_source) {
      formikRef.current.setFieldValue("_to", email_source.label);
      formikRef.current.setFieldValue("recipients", getSelectedEmails(email_source.label));
    }
  };

  const getSelectedEmails = email_source => {
    if (props.selectedAccounts) {
      let result = "";
      for (let id of props.selectedAccounts) {
        let account = props.accounts[id];
        if (account) {
          if (email_source === "Work Email") {
            result += account.source_name + " <" + (account.work_email || "") + ">; ";
          } else if (email_source === "Case Status Email") {
            result += account.source_name + " <" + (account.email || "") + ">; ";
          }
        }
      }
      return result;
    } else {
      return "";
    }
  };

  return (
    <SendEmailForm
      onSubmitAction={props.sendAccountEmail}
      emailTemplates={props.emailTemplates}
      getSelectedEmails={getSelectedEmails}
      loginInfo={props.loginInfo}
      selectedIds={props.selectedAccounts}
      templateType="Accounts"
      title="Send Account Email"
    />
  );
};

const mapStateToProps = state => {
  const accounts = accountsSelector(state);
  const emailTemplates = emailTemplateSelector(state).filter(
    template => template.type === EMAIL_TEMPLATE_TYPES.ACCOUNT,
  );

  return {
    loginInfo: loginInfoSelector(state),
    accounts,
    selectedAccounts: selectedAccountsSelector(state),
    emailTemplates,
  };
};

export default connect(mapStateToProps, {
  setActiveEmailTemplate,
  sendAccountEmail,
})(SendAccountEmailForm);
