import React from "react";
import { connect } from "react-redux";

import {
  selectOwner,
  showModal,
  selectInsured,
  unsetShouldSave,
  fetchBeneficiaries,
  fetchInsured,
  fetchOwners,
} from "actions";
import {
  activeCaseIdSelector,
  activeOwnerSelector,
  ownerEntitiesSelector,
  ownerPersonsSelector,
  ownerInsuredListSelector,
  loadingSelector,
  shouldSaveSelector,
  activeCaseSelector,
} from "reducers";
import { ButtonCreate } from "components/buttons";
import OwnerBasicForm from "./OwnerBasicForm";
import OwnerEntityForm from "./OwnerEntityForm";
import OwnerPersonForm from "./OwnerPersonForm";
import InsuredDemographicForm from "pages/cases/insured/InsuredDemographicForm";
import InsuredContactForm from "pages/cases/insured/InsuredContactForm";
import Loader from "components/Loader";
import * as notifications from "../../../notifications";
import OwnersTable from "./OwnersTable";
import { isAuthorized } from "../../../utils";
import { PERMISSIONS } from "../../../constants";

const OwnersPage = ({ ownerEntities, ownerPersons, activeOwner, owners, hasAdminPermission, ...props }) => {
  const ownerBasicSubmitRef = React.useRef();
  const ownerEntitySubmitRef = React.useRef();
  const ownerPersonSubmitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      setTimeout(() => {
        ownerBasicSubmitRef.current && ownerBasicSubmitRef.current.click();
      }, 300);
      setTimeout(() => {
        ownerEntitySubmitRef.current && ownerEntitySubmitRef.current.click();
      }, 500);
      setTimeout(() => {
        ownerPersonSubmitRef.current && ownerPersonSubmitRef.current.click();
      }, 700);
      setTimeout(() => {
        unsetShouldSave();
        notifications.success("Owners saved successfully");
      }, 1000);
    }
  }, [props.shouldSave]);

  React.useEffect(() => {
    if (props.caseInfo.submission_id) {
      document.title = `LifeRoc | ${props.caseInfo.submission_id} | Owners`;
    }
    if (props.caseInfo.id) {
      props.fetchInsured(props.caseInfo.id);
      props.fetchOwners(props.caseInfo.id);
    }
  }, [props.caseInfo.id]);

  const displayActiveOwnerForms = () => {
    if (activeOwner === null) {
      return null;
    }

    let forms = [<OwnerBasicForm key={0} submitRef={ownerBasicSubmitRef} />];

    if (activeOwner.type === "entity") {
      forms.push(<OwnerEntityForm ownerId={activeOwner.id} key={1} submitRef={ownerEntitySubmitRef} />);
    } else if (activeOwner.type === "person") {
      forms.push(<OwnerPersonForm ownerId={activeOwner.id} key={3} submitRef={ownerPersonSubmitRef} />);
    } else {
      forms.push(<InsuredDemographicForm insuredId={activeOwner.insured} disabled />);
      forms.push(<InsuredContactForm insuredId={activeOwner.insured} disabled />);
    }

    return forms;
  };

  if (props.loading.error404) {
    return <></>;
  }

  return (
    <>
      <Loader isLoading={props.loading.owners} text={"Loading Owners data"} />
      <OwnersTable owners={owners} />
      {hasAdminPermission && (
        <ButtonCreate
          onClick={() => {
            props.selectOwner({ type: "undefined" });
          }}
          style={{ marginBottom: "2rem" }}
        >
          Add Owner
        </ButtonCreate>
      )}
      {displayActiveOwnerForms()}
    </>
  );
};

const mapStateToProps = state => {
  const op = ownerPersonsSelector(state);
  const oe = ownerEntitiesSelector(state);
  const oi = ownerInsuredListSelector(state);
  const roles = state.auth.activeAgencyRoles;

  return {
    caseInfo: activeCaseSelector(state),
    loading: loadingSelector(state),
    activeOwner: activeOwnerSelector(state),
    caseId: activeCaseIdSelector(state),
    ownerPersons: ownerPersonsSelector(state),
    ownerEntities: ownerEntitiesSelector(state),
    ownerInsuredList: ownerInsuredListSelector(state),
    shouldSave: shouldSaveSelector(state),
    owners: [...op, ...oe, ...oi],
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  selectOwner,
  showModal,
  selectInsured,
  fetchInsured,
  fetchOwners,
  fetchBeneficiaries,
})(OwnersPage);
