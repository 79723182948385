import React from "react";
import { connect } from "react-redux";

import { fetchLeadTasks, selectLeadTask, showModal } from "actions";
import { activeLeadIdSelector, activeLeadSelector, leadTasksSelector } from "reducers";
import { DELETE_LEAD_TASK_MODAL, LEAD_TASK_MODAL } from "components/modals";
import Table, { dateCell, datetimeCell, notesCell } from "components/table";
import { FaEdit, FaTrash } from "react-icons/fa";
import { TASK_STATUS_MAP, TASK_TYPES_MAP } from "../../accounts/constants";
import IconLink from "../../../components/IconLink";

const LeadTaskTable = ({ activeLead, activeLeadId, fetchLeadTasks, ...props }) => {
  if (!activeLead || !props.tasks) {
    // Still didn't fetch data
    return null;
  }

  React.useEffect(() => {
    if (activeLead && activeLead.id) {
      fetchLeadTasks(activeLead.id);
    }
  }, []);

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        return (
          <>
            <IconLink
              Icon={FaEdit}
              iconConfig={{ className: "table--action-icon" }}
              tooltip="View/Edit"
              onClick={() => {
                props.selectLeadTask(row.original);
                props.showModal(LEAD_TASK_MODAL);
              }}
            />
            <IconLink
              Icon={FaTrash}
              iconConfig={{ className: "table--action-icon btn--red" }}
              tooltip="Delete"
              onClick={() => {
                const taskId = row.original.id;
                props.showModal(DELETE_LEAD_TASK_MODAL, {
                  taskId,
                  leadId: activeLeadId,
                });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Task Subject",
      accessor: "subject",
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ row }) => {
        const type_value = row.original.type;
        if (type_value !== null) {
          return TASK_TYPES_MAP[type_value] || "";
        } else {
          return "";
        }
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const status_value = row.original.status;
        if (status_value !== null) {
          return TASK_STATUS_MAP[status_value] || "";
        } else {
          return "";
        }
      },
    },
    {
      Header: "Task Owner",
      accessor: "owner_name",
    },
    {
      Header: "Task Creator",
      accessor: "created_by",
    },
    {
      Header: "Create Date",
      accessor: "date_created",
      Cell: dateCell,
    },
    {
      Header: "Last Edited By",
      accessor: "last_edited_by_name",
    },
    {
      Header: "Last Edit Date",
      accessor: "last_edit_date",
      Cell: datetimeCell,
    },
    {
      Header: "Follow Up Date",
      accessor: "due_date",
      Cell: dateCell,
    },
    {
      Header: "Notes",
      accessor: "notes",
      Cell: notesCell,
    },
  ];

  return (
    <Table columns={columns} data={props.tasks} defaultSort={true} paginated={false} emptyMessage="No Tasks found" />
  );
};

const mapStateToProps = state => {
  const activeLead = activeLeadSelector(state);
  const activeLeadId = activeLeadIdSelector(state);
  const tasks = leadTasksSelector(state);

  // As we cannot make the same implementation used for accounts: accounts.tasks using reverse foreign keys
  // We'll fetch the tasks for the given lead id as a useEffect fetchLeadTasks & leadTasksSelector
  return {
    activeLead,
    activeLeadId,
    tasks,
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectLeadTask,
  fetchLeadTasks,
})(LeadTaskTable);
