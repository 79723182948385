import { Formik } from "formik";
import * as Yup from "yup";
import * as notifications from "../../../notifications";
import {
  EditorField,
  DateInput,
  SubmitButton,
  Collapsible,
  StateSelect,
  HyperLinkText,
} from "../../../components/forms";
import { Form } from "react-bootstrap";
import {
  accountsSelector,
  activeCaseSelector,
  agentsChoicesSelector,
  funderWinningBidSelector,
  managementChoicesSelector,
} from "../../../reducers";
import { connect, useDispatch } from "react-redux";
import {
  editCase,
  fetchCaseFiles,
  getFundersForClosing,
  selectCaseFile,
  showModal,
  unsetShouldSave,
  setShouldSave,
  fetchFunderAuctionActivities,
  fetchInsured,
  selectFunder,
} from "actions";
import React from "react";
import { isAuthorized } from "../../../utils";
import RelatedCasesAndDriveLinksForm from "./RelatedCasesAndDriveLinksForm";
import { caseStatusChoices, marketTypeChoices, PERMISSIONS } from "../../../constants";
import {
  CASE_ACCOUNT_HIERARCHY_MODAL,
  PRICING_DOCUMENTS_MODAL,
  SEND_FUNDER_EMAIL_MODAL,
  SEND_MANAGER_EMAIL_MODAL,
} from "components/modals";
import { SET_EMAIL_TEMPLATE, SET_SELECTED_FUNDERS } from "../../../actions/types";
import * as types from "../../../actions/types";
import { FaEdit } from "react-icons/fa";
import HierarchySplitsTable from "../../accounts/licensing_and_contracts/hierarchies/HierarchySplitsTable";
import SelectInlineLabel from "../../../components/forms/SelectInlineLabel";
import { ButtonCreate } from "../../../components/buttons";

const CaseForm = ({ caseInfo, hasAdminPermission, ...props }) => {
  if (!caseInfo) {
    // Still didn't fetch data
    return null;
  }
  const {
    submission_id,
    submission_date,
    last_edit_date,
    jurisdiction,
    provider,
    carrier_name,
    carrier_id,
    source,
    broker,
    status,
    stage,
    notes,
    status_date,
    case_manager,
    pricing_manager,
    auction_manager,
    closing_manager,
    servicing_manager,
    underwriting_manager,
    related_cases,
    market_type,
  } = caseInfo;
  const dispatch = useDispatch();
  let account_id;
  let account_name = "";
  if (caseInfo.hierarchy) {
    account_id = caseInfo.hierarchy.account_id;
    account_name = caseInfo.hierarchy.account_name;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        submission_id,
        submission_date,
        last_edit_date,
        jurisdiction,
        provider,
        source,
        broker,
        carrier_name,
        carrier_id,
        status,
        stage,
        notes,
        status_date,
        case_manager,
        pricing_manager,
        auction_manager,
        closing_manager,
        servicing_manager,
        underwriting_manager,
        related_cases,
        market_type,
      }}
      validationSchema={Yup.object({})}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          let previousStatus = caseInfo.status;
          let newStatus = values.status;
          let changingStatus = previousStatus && previousStatus !== newStatus;
          await props.editCase(caseInfo.id, values);
          props.unsetShouldSave();
          props.fetchCaseFiles();

          let offerDeclinedChange = changingStatus && values.status === "I-OD";

          // Conditions to show the Funder Case Closed Notification:
          // 1. The status changes to Offer Declined
          // 2. There is at least one Funder in Bidding Status
          if (offerDeclinedChange) {
            // Request a list of funders for closing
            let caseId = caseInfo.id;
            let fundersData = await props.getFundersForClosing(caseId);
            if (fundersData && fundersData.length > 0) {
              notifications.warn("Case Closed with Bidding Funder(s) - showing Funder Case Closed Notification");
              let funderIdsWithBids = fundersData.map(funder => funder.id);
              await dispatch({ type: SET_SELECTED_FUNDERS, payload: funderIdsWithBids });
              await dispatch({ type: types.PARTIAL_FETCH_FUNDERS, payload: fundersData });
              await props.fetchInsured(caseId);
              await dispatch({ type: SET_EMAIL_TEMPLATE, payload: "Funder Case Closed Notification" });
              props.showModal(SEND_FUNDER_EMAIL_MODAL);
            }
          }

          let statusChangedToAuction = changingStatus && values.status === "P-A";
          if (statusChangedToAuction) {
            // Notify the Sales Rep, National Account Manager and Sales Manager
            notifications.warn("Case Status changed to Auction - Notify Case Managers");
            props.showModal(SEND_MANAGER_EMAIL_MODAL);
          }

          // Conditions to show the Funder Contract Diligence Notification
          // 1. The status changes to Contract Diligence
          // 2. There is a Winning Bid
          let statusChangedToContractDiligence = changingStatus && values.status === "IC-ACDR";
          if (statusChangedToContractDiligence) {
            let caseId = caseInfo.id;
            // Request updated funder auction activities to determine if there is a winning bid
            await props.fetchFunderAuctionActivities(caseId);
            if (props.funderWinningBid) {
              notifications.warn("Case Status changed to Contract Diligence - Notify Winning Bidder");
              let fundersData = await props.selectFunder(props.funderWinningBid.funder);
              await props.fetchInsured(caseId);
              await dispatch({ type: SET_SELECTED_FUNDERS, payload: [props.funderWinningBid.funder] });
              await dispatch({ type: types.PARTIAL_FETCH_FUNDERS, payload: [fundersData] });
              await dispatch({ type: SET_EMAIL_TEMPLATE, payload: "Funder Contract Diligence Notification" });
              props.showModal(SEND_FUNDER_EMAIL_MODAL);
            } else {
              notifications.warn(
                "Case Status changed to Contract Diligence - no Winning Bid on Case, no Winning Bidder to notify.",
              );
            }
          }
        } catch (error) {
          setErrors(error.response.data);
          props.unsetShouldSave();
          notifications.error("Error editing case");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        let sideButtonStyle = {
          height: "26px",
          position: "absolute",
          right: "30px",
          top: "-52px",
          padding: "0 5px",
          marginTop: 1,
          fontSize: 14,
          zIndex: 1,
        };

        function renderSelectHierarchyButton() {
          if (hasAdminPermission) {
            return (
              <button
                className={`btn btn--secondary btn--pricing-setup`}
                onClick={async e => {
                  props.showModal(CASE_ACCOUNT_HIERARCHY_MODAL, {
                    id: caseInfo.id,
                  });
                }}
                style={sideButtonStyle}
              >
                <FaEdit />
                Select Hierarchy
              </button>
            );
          }
        }

        function renderCreatePricingButton() {
          return (
            <ButtonCreate
              onClick={e => {
                e.preventDefault();
                props.showModal(PRICING_DOCUMENTS_MODAL, { id: caseInfo.id });
              }}
              key="create"
              className="btn--pricing-setup"
              style={sideButtonStyle}
            >
              Generate Pricing Documents
            </ButtonCreate>
          );
        }

        return (
          <>
            <Form
              onSubmit={handleSubmit}
              className={"case-form form"}
              style={{ marginBottom: 0, paddingBottom: 0 }}
              {...props}
            >
              <Collapsible title="Case Detail" style={{ marginBottom: 0, paddingBottom: 0 }}>
                <Form.Row>
                  {renderCreatePricingButton()}
                  <DateInput appendLabel="Create Date" name="submission_date" disabled={!hasAdminPermission} md={3} />
                  <DateInput appendLabel="Last Edit Date" name="last_edit_date" disabled={true} md={3} />
                  <DateInput appendLabel="Status Date" name="status_date" disabled={true} md={3} />
                  <SelectInlineLabel
                    label="Case Status"
                    name="status"
                    placeholder="Select Case Status"
                    options={caseStatusChoices}
                    disabled={!hasAdminPermission}
                    classNamePrefix="case-form-select"
                    selectMinHeight="20px"
                  />
                </Form.Row>
                <Form.Row>
                  <StateSelect
                    name="jurisdiction"
                    placeholder="Select Case Jurisdiction"
                    disabled={!hasAdminPermission}
                    inline={true}
                  />
                  <SelectInlineLabel
                    label="Provider"
                    name="provider"
                    placeholder="Select Provider"
                    options={props.providerChoices.getChoices({ sortByLabel: true })}
                    disabled={!hasAdminPermission}
                    emptyValue={null}
                  />
                  <SelectInlineLabel
                    label="Market Type"
                    name="market_type"
                    placeholder="Select Market"
                    options={marketTypeChoices}
                    disabled={!hasAdminPermission}
                  />
                  <HyperLinkText
                    label="Carrier"
                    value={carrier_name || "---"}
                    url={`/carriers/${carrier_id}/overview/`}
                    name="case_folder"
                    inline={true}
                  />
                </Form.Row>
                {hasAdminPermission && <RelatedCasesAndDriveLinksForm />}
                <Collapsible title="Team Data" style={{ marginBottom: 0, paddingBottom: 0 }}>
                  <Form.Row>
                    <HyperLinkText
                      label="Account Source"
                      value={account_name || "---"}
                      url={account_id ? `/accounts/${account_id}/licensing/` : null}
                      name="source"
                      inline={true}
                    />
                    {renderSelectHierarchyButton()}
                    <SelectInlineLabel
                      label="Broker"
                      name="broker"
                      placeholder="Select Broker"
                      options={props.accountChoices}
                      disabled={!hasAdminPermission}
                    />
                    <SelectInlineLabel
                      label="Case Manager"
                      name="case_manager"
                      placeholder="Select Case Manager"
                      options={props.caseManagerChoices.getChoices({ sortByLabel: true })}
                      disabled={!hasAdminPermission}
                    />
                    <SelectInlineLabel
                      label="Pricing Manager"
                      name="pricing_manager"
                      placeholder="Select Pricing Manager"
                      options={props.pricingManagerChoices.getChoices({
                        sortByLabel: true,
                      })}
                      disabled={!hasAdminPermission}
                    />
                  </Form.Row>
                  <Form.Row>
                    <SelectInlineLabel
                      label="Trading Manager"
                      name="auction_manager"
                      placeholder="Select Trading Manager"
                      options={props.auctionManagerChoices.getChoices({
                        sortByLabel: true,
                      })}
                      disabled={!hasAdminPermission}
                    />
                    <SelectInlineLabel
                      label="Closing Manager"
                      name="closing_manager"
                      placeholder="Select Closing Manager"
                      options={props.closingManagerChoices.getChoices({
                        sortByLabel: true,
                      })}
                      disabled={!hasAdminPermission}
                    />
                    <SelectInlineLabel
                      label="Servicing Manager"
                      name="servicing_manager"
                      placeholder="Select Servicing Manager"
                      options={props.servicingManagerChoices.getChoices({
                        sortByLabel: true,
                      })}
                      disabled={!hasAdminPermission}
                    />
                    <SelectInlineLabel
                      label="Underwriting Manager"
                      name="underwriting_manager"
                      placeholder="Select Underwriting Manager"
                      options={props.underwritingManagerChoices.getChoices({
                        sortByLabel: true,
                      })}
                      disabled={!hasAdminPermission}
                    />
                  </Form.Row>
                  {hasAdminPermission && (
                    <Collapsible title="Hierarchy Splits" style={{ marginBottom: 0, paddingBottom: 0 }}>
                      <HierarchySplitsTable removeActionButtons={true} />
                    </Collapsible>
                  )}
                </Collapsible>
                <Collapsible
                  title="Case Notes"
                  style={{ marginBottom: 0, paddingBottom: 0 }}
                  className="case-form-notes-content collapsible__content"
                >
                  <EditorField style={{ width: "100%" }} name="notes" initial={notes} disabled={!hasAdminPermission} />
                </Collapsible>
                <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
              </Collapsible>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const roles = state.auth.activeAgencyRoles;
  const choices = managementChoicesSelector(state);
  const accounts = accountsSelector(state);
  const agentsChoices = agentsChoicesSelector(state);

  const sourceChoices = choices.accounts.getChoices({ sortByLabel: true });
  sourceChoices.push({ value: null, label: "" });

  return {
    caseInfo: activeCaseSelector(state),
    providerChoices: choices.case_providers,
    accountChoices: choices.accounts.getChoices({ sortByLabel: true }),
    sourceChoices,
    agencyChoices: choices.agency,
    salesRepChoices: choices.case_sales_representatives,
    caseManagerChoices: agentsChoices.case_managers,
    pricingManagerChoices: agentsChoices.pricing_managers,
    auctionManagerChoices: agentsChoices.auction_managers,
    closingManagerChoices: agentsChoices.closing_managers,
    servicingManagerChoices: agentsChoices.servicing_managers,
    underwritingManagerChoices: agentsChoices.underwriting_managers,
    accounts: accounts,
    funderWinningBid: funderWinningBidSelector(state),
    hasAdminPermission: isAuthorized([PERMISSIONS.ADMINISTRATOR], roles),
  };
};

export default connect(mapStateToProps, {
  editCase,
  selectCaseFile,
  showModal,
  unsetShouldSave,
  fetchCaseFiles,
  fetchFunderAuctionActivities,
  fetchInsured,
  selectFunder,
  getFundersForClosing,
  setShouldSave,
})(CaseForm);
